import { createTheme } from '@material-ui/core/styles';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { AlertClassKey, AutocompleteClassKey } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';

import { BandeinsStrangeBold, senBold, senRegular } from '../fonts/fonts';
import colors from '../utils/colors';

export const SCROLLBAR_WIDTH = '24px';

interface Overrides extends CoreOverrides {
  MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
  MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
}

const exxetaBrandTheme = createTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: colors.white,
        color: colors.mainColor,
      },
    },

    MuiAlert: {
      standardInfo: {
        backgroundColor: colors.grey100,
        border: `1px solid ${colors.grey600};`,
      },
      standardError: {
        backgroundColor: colors.errorInfo,
        border: `1px solid ${colors.red500};`,
      },
      standardSuccess: {
        backgroundColor: colors.successInfo,
        border: `1px solid ${colors.green500};`,
      },
      standardWarning: {
        backgroundColor: colors.warningInfo,
        border: `1px solid ${colors.orange500};`,
      },
      action: {
        padding: 0,
        marginRight: '0',
      },
      icon: {
        marginRight: '0',
      },
      message: {
        color: colors.mainColor,
      },
    },

    MuiBadge: {
      badge: {
        color: 'inherit !important',
      },
    },

    /** Button-Parameter:
     *  color: immer "primary"
     *  variant: "contained" oder "outlined"
     */
    MuiButton: {
      root: {
        border: 'none',
        boxShadow: 'none !important',
        borderRadius: '24px',
        textTransform: 'none',
      },
      containedPrimary: {
        padding: '10px 40px',
        border: 'none',
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.grey600,
        },
        '&:active': {
          backgroundColor: colors.grey800,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.grey200,
          color: colors.grey600,
        },
      },

      outlinedPrimary: {
        padding: '7px 37px',
        border: `3px solid ${colors.mainColor}`,
        backgroundColor: `${colors.white} !important`,
        '&:hover': {
          border: `3px solid ${colors.grey600}`,
          color: colors.grey600,
        },
        '&:active': {
          border: `3px solid ${colors.grey800}`,
          color: colors.grey800,
        },
        '&.Mui-disabled': {
          border: `3px solid ${colors.grey200}`,
          color: colors.grey600,
        },
      },

      label: {
        fontFamily: senBold,
        fontSize: '16px',
        lineHeight: '24px',
        whiteSpace: 'nowrap',
      },

      endIcon: {
        marginLeft: '16px',
      },
    },

    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },

    MuiChip: {
      root: {
        fontFamily: BandeinsStrangeBold,
      },
    },

    MuiContainer: {
      maxWidthLg: {
        '@media (min-width: 1280px)': {
          maxWidth: 'none',
        },
        padding: 0,
      },
    },

    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: SCROLLBAR_WIDTH,
        },
        '*::-webkit-scrollbar-thumb': {
          background: `linear-gradient(90deg, #ffffff00 0%,#ffffff00 33%, ${colors.greyLines} 33%, ${colors.greyLines} 66%, #ffffff00 66%, #ffffff00 100%)`,
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
      },
    },

    MuiDialog: {
      paperWidthSm: {
        padding: '32px',
        minWidth: '50rem',
        maxWidth: '75rem',
        maxHeight: '55rem',
        borderRadius: '16px',
        boxShadow: `0px 0px 10px 0px ${colors.grey600}`,
      },
    },

    MuiDialogContent: {
      root: {
        padding: '24px 0px 32px 0px',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '0px',
        fontFamily: BandeinsStrangeBold,
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0px',
      },
      spacing: {
        '& >  :not(:first-child)': {
          marginLeft: '16px',
        },
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: colors.mainColor,
        opacity: '0.4 !important',
      },
    },

    MuiDrawer: {
      paper: {
        backgroundColor: colors.mainColor,
        color: colors.white,
      },
    },

    MuiAutocomplete: {
      input: {
        padding: '0px !important',
      },
      inputRoot: {
        padding: '10px 24px !important',
      },
      popupIndicator: {
        marginRight: '16px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      listbox: {
        maxHeight: '250px',
        padding: '8px 0px',
        backgroundColor: colors.white,
      },
      paper: {
        boxShadow: 'none',
        border: `1px solid ${colors.grey100}`,
        borderRadius: '5px',
      },
      option: {
        height: '32px',
        margin: '4px 0px',
        width: '100%',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'list-item',
        justifyContent: 'left',
        paddingLeft: '24px',
        color: colors.mainColor,
        backgroundColor: colors.white,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&[data-focus="true"]': {
          backgroundColor: colors.grey100,
        },
        '&:active': {
          backgroundColor: colors.grey100,
        },
      },
      popper: {
        minWidth: '10rem',
        width: 'fit-content !important',
      },
      endAdornment: {
        top: 'auto',
      },
    },

    MuiCheckbox: {
      root: {
        width: '20px',
        height: '20px',
        appearance: 'none',
        borderRadius: '6px',
        border: `1px solid ${colors.mainColor}`,
        cursor: 'pointer',
        backgroundColor: colors.grey50,
        '&:hover': {
          backgroundColor: colors.grey50 + '!important',
        },
        '&$checked': {
          backgroundColor: colors.turqoise500 + '!important',
        },
        '&$disabled': {
          backgroundColor: colors.grey100 + '!important',
          border: `1px solid ${colors.grey400}`,
        },
      },
    },

    MuiFormControlLabel: {
      root: {
        height: '100%',
        marginRight: '24px',
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },

    MuiFormControl: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInput-underline': {
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'solid',
            },
          },
        },
      },
    },

    MuiFormHelperText: {
      root: {
        fontFamily: senRegular,
        fontSize: '14px',
        color: colors.red500,
        marginTop: '4px',
      },
    },

    MuiFormLabel: {
      root: {
        marginBottom: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: colors.mainColor,
        fontFamily: senRegular,
        position: 'relative',
        fontSize: '14px',
        justifyContent: 'space-between',
        lineHeight: '20px',
      },
      asterisk: {
        visibility: 'hidden',
        position: 'absolute',
        right: '0',
        '&:after': {
          visibility: 'visible',
          content: '"Pflichtfeld"',
          color: colors.grey600,
        },
      },
    },

    MuiInput: {
      formControl: {
        marginTop: '0px!important',
      },
      underline: {
        '&:before': {
          borderBottom: '0px !important',
        },
        '&:after': {
          borderBottom: '0px !important',
        },
      },
      input: {},
      colorSecondary: {
        color: colors.white,
      },
      inputTypeSearch: {
        '&::placeholder': {
          color: colors.grey100,
          opacity: 1,
        },
      },
    },

    MuiInputBase: {
      inputMultiline: {
        margin: '10px 0px 10px 24px',
        paddingRight: '24px !important',
      },
      root: {
        borderRadius: '24px !important',
        padding: '10px 24px',
        width: '100%',
        border: '1px solid transparent',
        backgroundColor: colors.grey50,
        color: colors.mainColor,
        outline: 'none !important',
        justifyContent: 'center',
        alignSelf: 'stretch',
        '&:hover': {},
        '&:focus-within': {
          border: '1px solid black !important',
        },
        '&.Mui-error': {
          border: `1px solid ${colors.red500} !important`,
          backgroundColor: colors.grey50,
        },
      },
      input: {
        textDecoration: 'none',
        lineHeight: '24px',
        font: 'initial',
        fontFamily: senRegular + '!important',
        fontSize: '16px !important',
        padding: '0px !important',
      },
      multiline: {
        padding: '0px',
      },
      marginDense: {
        paddingTop: '0px !important',
      },
    },

    MuiInputLabel: {
      formControl: {
        transform: 'none',
        position: 'unset',
      },
      marginDense: {
        transform: 'none',
      },
      shrink: {
        transform: 'none,',
      },
    },

    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: colors.green500, // Set the color of the progress bar
      },
    },

    MuiLink: {
      root: {
        color: colors.mainColor,
        underline: 'always',
        textDecoration: 'underline !important',
        cursor: 'pointer',
      },
    },

    MuiPaper: {
      root: {
        fontFamily: BandeinsStrangeBold,
        '&.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
          boxShadow: 'none !important',
        },
      },
    },

    MuiTableCell: {
      root: {
        padding: '0px',
      },
      head: {
        borderBottom: `1px solid ${colors.mainColor}`,
        padding: '0px 32px 8px 0px',
      },
      body: {
        padding: '16px 0px',
        borderBottom: `1px solid ${colors.grey100}`,
      },
    },

    MuiTypography: {
      root: {
        backgroundColor: colors.white,
        color: colors.mainColor,
      },
    },

    MuiMenu: {
      paper: {
        boxShadow: 'none',
        border: '1px solid #E6E6E6',
        borderRadius: '4px',
        maxHeight: 350,
        fontSize: '16px',
        width: 'fit-content',
      },
      list: {
        padding: 0,
      },
    },

    MuiMenuItem: {
      root: {
        fontFamily: senRegular,
        fontWeight: 'normal',
        height: '32px',
        margin: '4px 0px',
        width: '100%',
        minWidth: '180px',
        fontSize: '16px',
        lineHeight: '24px',

        paddingLeft: '24px',
        color: colors.mainColor,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&$selected': {
          backgroundColor: colors.grey100,
          '&:hover': {
            backgroundColor: colors.grey100,
          },
        },
      },
    },

    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
        padding: 0,
        color: colors.mainColor,
      },
    },

    MuiDivider: {
      root: {
        borderRadius: '2px',
        backgroundColor: colors.grey800,
      },
      vertical: {
        width: '1px',
        height: '40px',
        margin: '0px 10px 0px 24px',
      },
      middle: {
        height: '24px !important',
        backgroundColor: colors.grey400,
        margin: '0px 8px 0px 0px !important',
      },
    },

    MuiCard: {
      root: {
        borderRadius: '24px',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
        margin: '12px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '32px',
        '&:last-child': {
          paddingBottom: '32px',
        },
      },
    },

    MuiSwitch: {
      root: {
        width: 40,
        height: 20,
        padding: 0,
        margin: '0px 8px',
      },
      switchBase: {
        padding: 0,
        margin: 2,
        transitionDuration: '400ms',
        // Controls default (unchecked) color for the thumb
        color: `${colors.mainColor}`,
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: `${colors.mainColor}`,
        },
      },
      track: {
        width: '40px',
        height: '20px',
        opacity: 1,
        backgroundColor: `${colors.grey50}`,
        borderRadius: '16px',
        border: `1px solid ${colors.mainColor}`,
        '$checked$checked + &': {
          opacity: 1.0,
          backgroundColor: `${colors.turqoise500}`,
        },
      },
      thumb: {
        width: '16px',
        height: '16px',
        borderRadius: '100%',
        boxShadow: 'none',
      },
    },

    MuiSlider: {
      markLabel: {
        fontFamily: senBold,
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    MuiStepper: {
      root: {
        padding: '0px',
      },
      horizontal: {
        alignItems: 'flex-start',
        height: '24px',
      },
    },

    MuiStep: {
      horizontal: {
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '24px',
      },
    },

    MuiStepConnector: {
      root: {
        flex: 'unset',
        alignSelf: 'center',
      },
      lineHorizontal: {
        border: `2px solid ${colors.mainColor}`,
        width: '196px',
      },
    },
  } as Overrides,

  palette: {
    background: {
      default: colors.white,
    },

    primary: {
      main: colors.mainColor,
    },
    // secondary== primary
    secondary: {
      main: colors.mainColor,
    },
  },

  // Diese Fonts entstprechen dem EXXETA-Styleguide-Richtlinien (Stand: 21.Dezember 2023)
  typography: {
    body1: {
      fontFamily: senRegular,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: senRegular,
      fontSize: '14px',
      lineHeight: '20px',
    },
    h1: {
      fontFamily: BandeinsStrangeBold,
      fontSize: '36px',
      lineHeight: '54px',
    },
    h2: {
      fontFamily: BandeinsStrangeBold,
      fontSize: '24px',
      lineHeight: '36px',
    },
    h3: {
      fontFamily: senBold,
      fontSize: '24px',
      lineHeight: '36px',
    },
    h4: {
      fontFamily: senBold,
      fontSize: '20px',
      lineHeight: '30px',
    },
    h5: {
      fontFamily: senBold,
      lineHeight: '24px',
      fontSize: '16px',
    },
  },
});

export default exxetaBrandTheme;
