import { Theme, createStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import MUIEXXSnackbar from './components/BaseComponentsExxetaStyle/MUIEXXSnackbar';
import DialogNewProject, { DialogMode } from './components/Dialogs/DialogNewProject/DialogNewProject';
//UI Elements
import ToolbarTop from './components/ToolbarTop/ToolbarTop';
//Pages
import Auslastung from './pages/Auslastung';
import MeinBoard from './pages/MeinBoard';
import Mitarbeiter from './pages/Mitarbeiter';
import NotFound404 from './pages/NotFound404';
import Projekte from './pages/Projekte';
import EmployeePage from './pages/employee/EmployeePage';
//store
import { initApp } from './redux/actions/AppInitAction';
import { changeDialogMode, setShowDialog } from './redux/actions/ProjectActions';
import { ApplicationStore } from './redux/reducers';
import { Employee } from './redux/reducers/employee/employee.type';
import { Toast } from './redux/reducers/toastReducer';
import { useMountHook } from './utils/useMount';

const styles = (theme: Theme): StyleRules<string> =>
  createStyles({
    root: {
      display: 'flex',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    sticky: {
      position: 'sticky',
      top: 0,
      zIndex: 150,
    },
  });

export interface AppProps extends WithStyles<typeof styles> {
  toasts: Toast[];
  showDialog: boolean;
  dialogMode: DialogMode;
  employee: Employee;
}

const App = (props: AppProps): JSX.Element => {
  const ref = useRef();
  const { toasts, showDialog, dialogMode } = props;
  const dispatch = useDispatch();
  useMountHook(() => {
    dispatch(initApp());
  }, ref);

  const classes = props.classes;

  const handleClickProjectSearch = (dialogMode: DialogMode): void => {
    dispatch(changeDialogMode(dialogMode));
    dispatch(setShowDialog(true));
  };

  const handleCloseOfDialogNewProject = (): void => {
    dispatch(setShowDialog(false));
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <CssBaseline />
        </div>
        {/* <NavLink to="/" exact activeStyle={{color: 'green'}}>App</NavLink>
                <NavLink to="/meinBoard" exact activeStyle={{color: 'green'}} >Mein Board</NavLink> */}
        <div className={classes.wrapper}>
          <div className={classes.sticky}>
            <ToolbarTop onProjectSearchClick={handleClickProjectSearch} />
          </div>
          <Switch>
            <Route path="/" exact component={Mitarbeiter} />
            <Route path="/auslastung" exact component={Auslastung} />
            <Route path="/meinBoard" exact component={MeinBoard} />
            <Route path="/mitarbeiter" exact component={Mitarbeiter} />
            <Route path="/mitarbeiter/profil/:name" exact component={EmployeePage} />
            <Route path="/mitarbeiter/profil/:name/abwesenheit-verwalten" exact component={EmployeePage} />
            <Route path="/projekte" exact component={Projekte} />
            <Route path="*" component={NotFound404} />
          </Switch>
        </div>
      </div>

      {toasts.map((toast: Toast) => (
        <MUIEXXSnackbar key={toast.id} toast={toast} />
      ))}
      {showDialog && (
        <DialogNewProject
          handleClose={handleCloseOfDialogNewProject}
          showDialog={showDialog}
          dialogNewProjectMode={dialogMode}
        />
      )}
    </div>
  );
};

App.defaultProps = {};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    toasts: store.toasts,
    showDialog: store.project.showDialog,
    dialogMode: store.project.dialogNewProjectMode,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(App));
