import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import { Status } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import MUIEXXDatePicker from '../BaseComponentsExxetaStyle/MUIEXXDatePicker';

interface UrlaubsstatusProps {
  handleDeleteInputRow: Function;
  handleAddNewInputs: Function;
  handleDateInput: Function;
  employeeStatus: Status;
  errorIsShowed: boolean;
  handleDateError: Function;
}
export interface Vacation {
  vacationBeginning: string | null;
  vacationEnding: string | null;
}

const useStyles = makeStyles((theme) => ({
  smallContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  notice: {
    color: colors.grey600,
  },

  inputFields: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  noticeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  addIcon: {
    width: '32px',
    height: '32px',
  },

  deleteIcon: {
    width: '16px',
    height: '16px',
  },

  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
  },

  deleteWrapper: {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'self-end',
  },

  deleteBox: {
    width: '24px',
    height: '24px',
  },

  errorText: {
    color: colors.red500,
  },
}));

const Urlaubsstatus = (props: UrlaubsstatusProps) => {
  const { employeeStatus, errorIsShowed, handleAddNewInputs, handleDateInput, handleDeleteInputRow, handleDateError } =
    props;
  const { vacationStatus } = employeeStatus;
  const classes = useStyles();

  return (
    <div className={classes.smallContent}>
      <div className={classes.noticeBox}>
        <Typography variant="h5">Wann bist du im Urlaub?</Typography>
        <Typography variant="body2" className={classes.notice}>
          Hinweis: Hier kannst du deine Urlaubszeiten eintragen. Diese werden in der Auslastungstabelle angezeigt.
        </Typography>
      </div>
      <div className={classes.inputFields}>
        {vacationStatus.map((vacation, index) => {
          const { vacationBeginning, vacationEnding } = vacation;
          return (
            <div key={index} className={classes.inputRow}>
              <MUIEXXDatePicker
                fieldName={'Urlaubsbeginn'}
                formLabel={'Urlaubsbeginn'}
                value={vacationBeginning === null ? null : dayjs(vacationBeginning, 'DD.MM.YYYY')}
                onChange={(date: Dayjs) => {
                  handleDateInput(date, index, 'vacationBeginning');
                }}
                handleDateError={(error) => {
                  handleDateError(error, index);
                }}
                textfieldWidth={'200px'}
              />
              <MUIEXXDatePicker
                fieldName={'Urlaubsende'}
                formLabel={'Urlaubsende'}
                minDate={dayjs(vacationBeginning, 'DD.MM.YYYY')}
                value={vacationEnding === null ? null : dayjs(vacationEnding, 'DD.MM.YYYY')}
                onChange={(date: Dayjs) => {
                  handleDateInput(date, index, 'vacationEnding');
                }}
                handleDateError={(error) => {
                  handleDateError(error, index);
                }}
                textfieldWidth={'200px'}
              />

              <div className={classes.deleteWrapper}>
                <IconButton onClick={() => handleDeleteInputRow(index)} className={classes.deleteBox}>
                  <FontAwesomeIcon className={classes.deleteIcon} icon={faTrash} />
                </IconButton>
              </div>
            </div>
          );
        })}
        <div>
          {errorIsShowed ? (
            <Typography variant="body2" className={classes.errorText}>
              Felder dürfen nicht leer sein. Bitte fülle diese Urlaubszeiten aus oder entferne sie wieder.
            </Typography>
          ) : (
            <IconButton onClick={() => handleAddNewInputs()}>
              <FontAwesomeIcon className={classes.addIcon} icon={faCirclePlus} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Urlaubsstatus;
