//Projekte.tsx
const compareFirstnameInProjectTableRow = (a: any, b: any): number => {
  const nameA = a.forename.toUpperCase();
  const nameB = b.forename.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const compareProjectNames = (a: any, b: any): number => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const compareFirstnameOfProjectMembers = (a: any, b: any): number => {
  const nameA = a.employeeEmail.split('.')[0].toUpperCase();
  const nameB = b.employeeEmail.split('.')[0].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export { compareFirstnameInProjectTableRow, compareProjectNames, compareFirstnameOfProjectMembers };
