import { DialogMode, DialogNewProjectMode } from '../../components/Dialogs/DialogNewProject/DialogNewProject';
import { sortProjects } from '../../utils/projectUtils';
import * as actionTypes from '../actions/ActionTypes';
import { DispatchedProjectAction, Project } from '../actions/ProjectActions';

export interface ProjectStore {
  projectData: Project[];
  showDialog: boolean;
  dialogNewProjectMode: DialogMode;
}

export const projectInitialData: ProjectStore = {
  projectData: [],
  dialogNewProjectMode: { mode: DialogNewProjectMode.CREATE_NEW },
  showDialog: false,
};

function ProjectReducer(state = projectInitialData, action: DispatchedProjectAction): ProjectStore {
  switch (action.type) {
    case actionTypes.PROJECT_DATA_AVAILABLE: {
      return {
        ...state,
        projectData: action.payload ? sortProjects(action.payload) : state.projectData,
      };
    }

    case actionTypes.SET_SHOW_DIALOG: {
      return { ...state, showDialog: action.payload };
    }

    case actionTypes.CHANGE_DIALOG_MODE: {
      return { ...state, dialogNewProjectMode: action.payload };
    }

    default:
      return { ...state };
  }
}

export default ProjectReducer;
