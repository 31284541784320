import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Theme, Typography, createStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { DateValidationError } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import EmployeeBottom from '../../components/profil/EmployeeBottom';
import EmployeeNavigation from '../../components/profil/EmployeeNavigation';
import { Vacation } from '../../components/status/Urlaubsstatus';
import EmptyEmployee from '../../models/shared/EmptyEmployee';
import { editEmployee } from '../../redux/actions/EmployeeActions';
import { ApplicationStore } from '../../redux/reducers';
import { AbsenceStatus, Employee, Status, WorkloadStatus } from '../../redux/reducers/employee/employee.type';
import { useRouterNav } from '../../useRouterNav';
import {
  createEmailFromUniqueEmployeeName,
  createUniqueEmployeeNameFromUrl,
  getEmployeeFromEmail,
} from '../../utils/employeeUtils';
import MitarbeiterProfilBody from './EmployeeBody';
import StatusEinstellungen from './StatusEinstellungen';

const styles = (theme: Theme) =>
  createStyles({
    spinnerWrapper: {
      paddingTop: theme.spacing(5),
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'auto',
    },
    bodyStyle: {
      display: 'flex',
    },
    backToViewLine: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'flex-end',
      margin: '32px 0px 24px 0px',
      paddingLeft: '8px',
    },
    backToViewIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '16px',
    },
    arrowIcon: {
      width: '16px',
      height: '16px',
      marginLeft: '12px',
    },
    contentLeft: {
      display: 'flex',
    },
  });

interface EmployeePageProps extends WithStyles<typeof styles> {
  employee: Employee;
  saveEmployeeStatus: (status: Status, email: string, urlaubChanged: boolean) => void;
  displayedEmployees: Employee[];
  employeeStatus: Status;
}

export interface IsChanged {
  workload: boolean;
  vacation: boolean;
  absence: boolean;
}
export interface StatusStore {
  status: Status;
  isValid: boolean;
  isChanged: IsChanged;
  errorShowed: boolean;
  onProfile: boolean;
}

const EmployeePage = (props: EmployeePageProps): JSX.Element => {
  const { classes, employee, displayedEmployees, saveEmployeeStatus } = props;

  const { goToMitarbeiterPage, goToNextEmployee } = useRouterNav();

  const [employeeState, setEmployeeState] = useState<StatusStore>({
    isValid: true,
    isChanged: { workload: false, vacation: false, absence: false },
    status: employee?.status || EmptyEmployee.status,
    errorShowed: false,
    onProfile: !window.location.href.includes('abwesenheit-verwalten'),
  });

  const initialVacation = useMemo(
    () =>
      employee?.status?.vacationStatus.map((v) => ({
        vacationBeginning: v.vacationBeginning,
        vacationEnding: v.vacationEnding,
      })) || [],
    [employee],
  );

  const initialWorkload = useMemo(
    () => employee?.status?.workloadStatus || EmptyEmployee.status.workloadStatus,
    [employee],
  );

  const initialAbsence = useMemo(
    () => employee?.status?.absenceStatus || EmptyEmployee.status.absenceStatus,
    [employee],
  );

  const handleAbsenceValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeState((prev) => ({
      ...prev,
      status: { ...prev.status, absenceStatus: event.target.value as AbsenceStatus },
      isChanged: { ...prev.isChanged, absence: (event.target.value as AbsenceStatus) !== initialAbsence },
    }));
  };

  const handleWorkloadClick = (key: keyof WorkloadStatus) => {
    const { workloadStatus } = employeeState.status;
    const newWorkloadStatus = {
      ...workloadStatus,
      [key]: !workloadStatus[key],
    };

    setEmployeeState((prev) => ({
      ...prev,
      isChanged: {
        ...prev.isChanged,
        workload: !(
          initialWorkload.iHaveTime === newWorkloadStatus.iHaveTime &&
          initialWorkload.iNeedHelp === newWorkloadStatus.iNeedHelp
        ),
      },
      status: {
        ...prev.status,
        workloadStatus: {
          ...prev.status.workloadStatus,
          [key]: !prev.status.workloadStatus[key as keyof WorkloadStatus],
        },
      },
    }));
  };

  const checkIfVacationValid = (vacationStatus: Vacation[]) => {
    let isValid = true;
    const foundIndex = vacationStatus.findIndex(
      (v) => typeof v.vacationBeginning !== 'string' || typeof v.vacationEnding !== 'string',
    );

    if (foundIndex >= 0) {
      isValid = false;
    }
    setEmployeeState((prev) => ({ ...prev, isValid }));
  };

  const checkIfVacationChanged = (newVacationStatus: Vacation[]) => {
    let hasChanged = false;
    if (initialVacation.length !== newVacationStatus.length) {
      hasChanged = true;
    } else {
      const foundIndex = initialVacation.findIndex((vacation, index) => {
        return (
          vacation.vacationBeginning !== newVacationStatus[index].vacationBeginning ||
          vacation.vacationEnding !== newVacationStatus[index].vacationEnding
        );
      });

      if (foundIndex >= 0) {
        hasChanged = true;
      }
    }
    return hasChanged;
  };

  const handleAddNewInputs = () => {
    setEmployeeState((prev) => ({
      ...prev,
      isChanged: { ...prev.isChanged, vacation: true },
      status: {
        ...prev.status,
        vacationStatus: [...prev.status.vacationStatus, { vacationBeginning: null, vacationEnding: null }],
      },
      isValid: false,
    }));
  };

  const handleDeleteInputRow = (currentIndex: number) => {
    const newVacationStatus = employeeState.status.vacationStatus.filter((vacation, index) => index !== currentIndex);
    setEmployeeState((prev) => ({
      ...prev,
      isChanged: { ...prev.isChanged, vacation: checkIfVacationChanged(newVacationStatus) },
      status: {
        ...prev.status,
        vacationStatus: newVacationStatus,
      },
    }));
    checkIfVacationValid(newVacationStatus);
  };

  const handleDateInput = (date: Dayjs, index: number, timeSlot: keyof Vacation) => {
    const newVacationStatus = employeeState.status.vacationStatus.map((vacation, i) => {
      if (index === i) {
        vacation[timeSlot] = date.format('DD.MM.YYYY');
      }
      return vacation;
    });

    setEmployeeState((prev) => ({
      ...prev,
      isChanged: { ...prev.isChanged, vacation: checkIfVacationChanged(newVacationStatus) },
      status: {
        ...prev.status,
        vacationStatus: newVacationStatus,
      },
    }));

    checkIfVacationValid(newVacationStatus);
  };

  const onSaveButtonClick = (employee: Employee, shouldGoToNextEmployee: boolean, moveRight = true) => {
    if (employeeState.isValid) {
      saveEmployeeStatus(employeeState.status, employee.email, employeeState.isChanged.vacation);
      setEmployeeState((prev) => ({
        ...prev,
        isChanged: { absence: false, vacation: false, workload: false },
        errorShowed: false,
      }));
      if (shouldGoToNextEmployee) {
        goToNextEmployee(moveRight, displayedEmployees, employee);
      }
    } else {
      setEmployeeState((prev) => ({
        ...prev,
        errorShowed: true,
        status: {
          ...prev.status,
          vacationStatus: prev.status.vacationStatus.map((v) => ({
            vacationBeginning: typeof v.vacationBeginning !== 'string' ? undefined : v.vacationBeginning,
            vacationEnding: typeof v.vacationEnding !== 'string' ? undefined : v.vacationEnding,
          })),
        },
      }));
    }
  };

  const onCancelClick = (event?: React.MouseEvent) => {
    event?.preventDefault();
    setEmployeeState((prev) => ({
      ...prev,
      status: employee.status || EmptyEmployee.status,
      isChanged: { absence: false, vacation: false, workload: false },
      isValid: true,
      errorShowed: false,
    }));
  };

  const handleDateError = (error: DateValidationError, index: number) => {
    switch (error) {
      case 'minDate': {
        const newVacationStatus = employeeState.status.vacationStatus.map((vacation, i) => {
          if (index === i) {
            vacation.vacationEnding = vacation.vacationBeginning;
          }
          return vacation;
        });
        setEmployeeState((prev) => ({
          ...prev,
          status: { ...prev.status, vacationStatus: newVacationStatus },
        }));
        break;
      }
    }
  };

  useEffect(() => {
    setEmployeeState((prev) => ({ ...prev, onProfile: !window.location.href.includes('abwesenheit-verwalten') }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    setEmployeeState((prev) => ({ ...prev, status: employee?.status || EmptyEmployee.status }));
  }, [employee]);

  return !employee ? (
    <div className={classes.spinnerWrapper}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <>
        <div>
          {employee && (
            <EmployeeNavigation
              employee={employee}
              isChanged={employeeState.isChanged}
              onCancelClick={onCancelClick}
              onSaveButtonClick={onSaveButtonClick}
              displayedEmployees={displayedEmployees}
              onProfile={employeeState.onProfile}
            >
              {employeeState.onProfile ? (
                <div>
                  <div className={classes.backToViewLine}>
                    <IconButton color="primary" onClick={goToMitarbeiterPage}>
                      <div className={classes.backToViewIcon}>
                        <FontAwesomeIcon icon={faArrowLeft} className={classes.arrowIcon} />
                        <Typography variant="body1">Zurück zur Übersicht</Typography>
                      </div>
                    </IconButton>
                  </div>
                  <div>
                    <MitarbeiterProfilBody
                      employee={employee}
                      employeeState={employeeState}
                      handleWorkloadClick={handleWorkloadClick}
                    />
                  </div>
                </div>
              ) : (
                <StatusEinstellungen
                  handleDateError={handleDateError}
                  handleSave={onSaveButtonClick}
                  employeeState={employeeState}
                  onCancelClick={onCancelClick}
                  handleAddNewInputs={handleAddNewInputs}
                  handleDeleteInputRow={handleDeleteInputRow}
                  handleDateInput={handleDateInput}
                  handleAbsenceValue={handleAbsenceValue}
                />
              )}
            </EmployeeNavigation>
          )}
        </div>
        {employeeState.onProfile && (
          <EmployeeBottom
            employee={employee}
            onCancelButtonClick={onCancelClick}
            onSaveButtonClick={onSaveButtonClick}
            displayedEmployees={displayedEmployees}
            employeeState={employeeState}
          />
        )}
      </>
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    employee: getEmployeeFromEmail(
      createEmailFromUniqueEmployeeName(createUniqueEmployeeNameFromUrl(window.location.href)),
      store.employee.employeeData,
    ),
    employees: store.employee.employeeData,
    displayedEmployees: store.employee.displayedEmployees,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
  return {
    saveEmployeeStatus: (changesStatus: Status, employeeEmail: string, urlaubChanged: boolean) => {
      dispatch(editEmployee(changesStatus, employeeEmail, urlaubChanged));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployeePage));
