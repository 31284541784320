import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  actionSpace: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notice: {
    marginBottom: '32px',
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '1024px',
    },
  },
}));

export interface ConfirmationDialogProps {
  open: boolean;
  dialogTitle: string;
  dialogDescription: string;
  handleClose: Function;
  handleSecondaryButton?: Function;
  handleConfirmButton: Function;
  confirmText: string;
  secondText: string;
  actionSpace?: boolean;
}

const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
  const {
    handleSecondaryButton,
    handleClose,
    handleConfirmButton,
    dialogTitle,
    open,
    dialogDescription,
    confirmText,
    secondText,
    actionSpace = false,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => handleClose()} className={classes.dialog}>
      <div className={classes.titleRow}>
        <DialogTitle disableTypography>{dialogTitle}</DialogTitle>
        <IconButton onClick={() => handleClose()}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </div>
      <div className={classes.notice}>
        <Typography>{dialogDescription}</Typography>
      </div>
      <DialogActions {...(actionSpace ? { className: classes.actionSpace } : {})}>
        <Button
          onClick={() => {
            handleSecondaryButton && handleSecondaryButton();
            handleClose();
          }}
          variant="outlined"
          color="primary"
          disableRipple
        >
          {secondText}
        </Button>
        <Button
          onClick={() => {
            handleConfirmButton();
            handleClose();
          }}
          variant="contained"
          color="primary"
          disableRipple
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
