import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import MUIEXXCheckBox from '../components/BaseComponentsExxetaStyle/MUIEXXCheckBox';
import ConfirmationDialog from '../components/Dialogs/ConfirmationDialog';
import { DialogNewProjectMode } from '../components/Dialogs/DialogNewProject/DialogNewProject';
import ProjectTableList from '../components/ProjectTableList';
import SubHeader from '../components/ToolbarTop/SubHeader';
import {
  Project,
  ProjectMember,
  archiveProject,
  changeDialogMode,
  deleteProjectConfirmedAction,
  setShowDialog,
} from '../redux/actions/ProjectActions';
import { ApplicationStore } from '../redux/reducers';
import { Employee } from '../redux/reducers/employee/employee.type';
import colors from '../utils/colors';
import { compareFirstnameOfProjectMembers as compareForenameOfProjectMembers } from '../utils/compareNamesAlphabetical';
import { getEmployeeFromEmail } from '../utils/employeeUtils';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '40px',
    },

    checkboxArchived: {
      marginLeft: 'auto',
    },

    labelChecked: {
      fontWeight: 800,
      color: theme.palette.primary.main,
    },

    //used to overwrite materialui root
    labelUnchecked: {},

    search: {
      display: 'flex',
      alignItems: 'center',
    },

    searchTextActiv: {
      marginLeft: theme.spacing(2),
      fontWeight: 800,
      //color: theme.palette.primary.main,
    },

    searchTextInactiv: {
      fontStyle: 'italic',
      marginLeft: theme.spacing(2),
    },

    paddingBottom: {
      paddingBottom: 20,
    },

    stuckHeader: {
      borderBottom: `1px solid ${colors.grey200}`,
    },
  });

enum ActionType {
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export interface ProjekteProps extends WithStyles<typeof styles> {
  projects: Project[];
  employees: Employee[];
}

interface ProjektePageState {
  clickedProject: Project;
  selectedAction: ActionType;
  searchValue: string;
  checkedArchived: boolean;
  nameFlag: sortState;
  customerFlag: sortState;
  originalList: Project[];
  currentList: Project[];
}

export enum sortState {
  ASC,
  DESC,
  UNSORTED,
}

const Projekte = (props: ProjekteProps): JSX.Element => {
  const { classes, projects, employees } = props;
  const dispatch = useDispatch();

  const SEARCH_PARAM_ARCHIV = 'archiv';
  const SEARCH_PARAM_SEARCH = 'search';

  // URL Filter Variablen
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const initProjektePageState: ProjektePageState = {
    clickedProject: null,
    selectedAction: null,
    searchValue: searchParams.has(SEARCH_PARAM_SEARCH) ? searchParams.get(SEARCH_PARAM_SEARCH) : '',
    checkedArchived: searchParams.has(SEARCH_PARAM_ARCHIV),
    nameFlag: sortState.ASC,
    customerFlag: sortState.UNSORTED,
    originalList: [],
    currentList: [],
  };

  const [state, setState] = useState(initProjektePageState);

  const handleDeleteProject = (project: Project): void => {
    setState({
      ...state,
      selectedAction: ActionType.DELETE,
      clickedProject: project,
    });
  };

  const handleArchiveProject = (project: Project, value: boolean): void => {
    setState({
      ...state,
      selectedAction: ActionType.ARCHIVE,
      clickedProject: project,
    });
    dispatch(archiveProject(project.name, value));
  };

  const handleEditProject = (project: Project): void => {
    dispatch(setShowDialog(true));
    dispatch(changeDialogMode({ mode: DialogNewProjectMode.EDIT, projectName: project.name }));
  };

  const handleFilter = (projects: Project[], checkedArchived: boolean): Project[] => {
    let resultList = projects;
    if (!checkedArchived) {
      resultList = projects.filter((project) => !project.archived);
    }
    return resultList;
  };

  const handleSearch = (input: string, projects: Project[]): Project[] => {
    let resultList = projects;
    if (input !== null || input !== '') {
      const inputSearch = input.toUpperCase();
      resultList = projects.filter(
        (project) =>
          project.name.toUpperCase().search(inputSearch) !== -1 ||
          project.customer.toUpperCase().search(inputSearch) !== -1 ||
          project.description.toUpperCase().search(inputSearch) !== -1 ||
          project.projectMembers.filter(
            (employee) => getEmployeeFromEmail(employee.employeeEmail, employees).forename.search(inputSearch) !== -1,
          ).length > 0,
      );
    }
    return resultList;
  };

  const handleURLChange = (searchValue: string, checkedArchived: boolean) => {
    searchParams.set(SEARCH_PARAM_SEARCH, searchValue);
    if (searchValue === '') {
      searchParams.delete(SEARCH_PARAM_SEARCH);
    }

    searchParams.set(SEARCH_PARAM_ARCHIV, checkedArchived.toString());
    if (!checkedArchived) {
      searchParams.delete(SEARCH_PARAM_ARCHIV);
    }
    const newSearchString = searchParams.toString();

    if (location.search !== newSearchString) {
      history.replace({ search: newSearchString });
    }
  };

  const handleChange = (event: ChangeEvent | MouseEvent, id?: string): void => {
    const testListe: Project[] = projects.map((project) => {
      return createData(project, employees);
    });
    let resultList: Project[] = testListe;
    let searchValue: string = state.searchValue;
    let checkedArchived: boolean = state.checkedArchived;

    if (event) {
      switch (id) {
        case 'checkbox':
          checkedArchived = !checkedArchived;
          break;
        case 'search':
          searchValue = (event.target as HTMLInputElement).value;
          break;
        case 'sort':
          break;
        default:
          break;
      }
    }

    resultList = handleFilter(handleSearch(searchValue, testListe), checkedArchived);

    handleURLChange(searchValue, checkedArchived);
    //TODO
    //handleSort(resultList);

    setState({
      ...state,
      searchValue: searchValue,
      checkedArchived: checkedArchived,
      currentList: resultList,
    });
  };

  function createData(project: Project, employees: Employee[]): Project {
    const members: ProjectMember[] = [];
    if (project.projectMembers && project.projectMembers.length > 0) {
      for (const projectMember of project.projectMembers) {
        members.push(projectMember);

        if (members && members.length > 1) {
          members.sort(compareForenameOfProjectMembers);
        }
      }
    }
    return {
      name: project.name,
      customer: project.customer,
      description: project.description,
      projectMembers: members,
      archived: project.archived,
      billable: project.billable,
      endDate: project.endDate,
      startDate: project.startDate,
    };
  }

  useEffect(() => {
    const originalList = projects.map((project) => {
      return createData(project, employees);
    });

    handleChange(null);

    setState((prevState) => {
      return {
        ...prevState,
        originalList: originalList,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return (
    <div className={classes.root}>
      <SubHeader
        handleInputChange={(event) => handleChange(event, 'search')}
        searchValue={state.searchValue}
        placeholder={'Projekt oder Kunde suchen'}
        stuckClass={classes.stuckHeader}
      >
        <div className={classes.checkboxArchived}>
          <MUIEXXCheckBox
            checked={state.checkedArchived}
            onChange={(event) => handleChange(event, 'checkbox')}
            onClick={(event) => handleChange(event, 'checkbox')}
            label={'Archivierte Projekte anzeigen'}
            margin={'0px 12px'}
          />
        </div>
      </SubHeader>

      <ProjectTableList
        projects={state.currentList}
        onEditProject={handleEditProject}
        onArchiveProject={handleArchiveProject}
        onDeleteProject={handleDeleteProject}
        employees={employees}
      />

      {state.selectedAction === ActionType.DELETE && state.clickedProject && (
        <ConfirmationDialog
          open={!!state.clickedProject}
          confirmText="Löschen"
          secondText="Abbrechen"
          dialogDescription={`Das Projekt ${state.clickedProject.name} wird unwiderruflich gelöscht. Bist du sicher, dass du fortfahren möchtest?`}
          dialogTitle={`Projekt ${state.clickedProject.name} wirklich löschen?`}
          handleClose={() => handleDeleteProject(null)}
          handleConfirmButton={() => {
            dispatch(deleteProjectConfirmedAction(state.clickedProject.name));
          }}
          actionSpace={true}
        />
      )}
    </div>
  );
};

Projekte.propTypes = {};

Projekte.defaultProps = {
  projects: [],
};

const mapStateToProps = (store: ApplicationStore): { projects: Project[]; employees: Employee[] } => {
  return {
    projects: store.project.projectData,
    employees: store.employee.employeeData,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Projekte));
