const getIdTokenFromBrowserSession = (): string => {
  for (let i = 0; i < window.sessionStorage.length; i++) {
    const indexKey = window.sessionStorage.key(i);
    if (indexKey && indexKey.includes('-login.windows.net-idtoken-')) {
      const value = window.sessionStorage.getItem(indexKey);
      const parsedToken = JSON.parse(value);
      return parsedToken.secret;
    }
  }
  return null;
};

export { getIdTokenFromBrowserSession };
