import { CircularProgress, makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Project } from '../../redux/actions/ProjectActions';
import { displayRedBoxes } from '../../redux/actions/WorkloadActions';
import { ApplicationStore } from '../../redux/reducers';
import { Employee } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import useResizeStatus from '../../utils/resizeHook';
import CustomTimeline from '../Timeline/CustomTimeline';
import { getTimelineGroups } from '../Timeline/getTimelineGroups';
import { getTimelineItems } from '../Timeline/getTimelineItems';

export interface EmployeeWorkloadTimelineProps {
  employee: Employee;
  teamData: string[];
  projects: Project[];
  isAllEmployeesLoaded: boolean;
}

const useStyles = makeStyles((theme) => ({
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    '& .react-calendar-timeline': {
      border: `2px solid ${colors.grey200}`,
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even': {
      borderBottom: '0px',
    },
  },
}));

const EmployeeWorkloadTimeline = (props: EmployeeWorkloadTimelineProps) => {
  const { employee, teamData, projects, isAllEmployeesLoaded } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isResizing } = useResizeStatus();
  const today = dayjs();
  const visibleMonthCount = 3;

  const items = getTimelineItems('alle', [employee], [employee], projects);
  const groups = getTimelineGroups({
    currentEmployeeFilter: 'alle',
    employees: [employee],
    displayedEmployees: [employee],
    teamData,
    onlyEmployee: true,
    items: items,
    visibleMonthCount: visibleMonthCount,
    startDay: today,
  });

  groups.shift();

  useEffect(() => {
    if (isAllEmployeesLoaded) {
      dispatch(displayRedBoxes(!isResizing));
    }
  }, [isResizing, isAllEmployeesLoaded, dispatch]);

  useEffect(() => {
    if (isAllEmployeesLoaded) {
      dispatch(displayRedBoxes(false));
    }
    const frameId = requestAnimationFrame(() => {
      if (isAllEmployeesLoaded) {
        dispatch(displayRedBoxes(true));
      }
    });

    return () => cancelAnimationFrame(frameId);
  }, [dispatch, isAllEmployeesLoaded]);

  return (
    <div className={classes.root}>
      {isAllEmployeesLoaded ? (
        <CustomTimeline
          employees={[employee]}
          groups={groups}
          startDay={today}
          visibleMonthCount={visibleMonthCount}
          items={items}
          hideSidebar={true}
          labelFormat="MMMM YYYY"
        />
      ) : (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (
  store: ApplicationStore,
): {
  isAllEmployeesLoaded: boolean;
} => {
  return {
    isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
  };
};
export default connect(mapStateToProps)(EmployeeWorkloadTimeline);
