import { Card, CardContent, Theme, Typography, makeStyles } from '@material-ui/core';
import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { senBold } from '../../fonts/fonts';
import { InputSelectOption } from '../../models/shared/InputSelectOption';
import { Project } from '../../redux/actions/ProjectActions';
import { ApplicationStore } from '../../redux/reducers';
import { Employee } from '../../redux/reducers/employee/employee.type';
import MUIEXXAutocompleteInputField, { Option } from '../BaseComponentsExxetaStyle/MUIEXXAutocompleteInputField';
import EmployeeWorkloadTimeline from '../Employee/EmployeeWorkloadTimeline';
import TimelineCaption from '../TimelineCaption';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    alignSelf: 'flex-start',
  },

  inputField: {
    width: '727px',
  },
  utilisationCardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  utilisationCard: {
    marginBottom: '40px',
  },

  calendarView: {
    padding: '8px 0px',
  },

  calendarTitle: {
    marginBottom: '16px',
  },

  timelineCaptionWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  popperWithSticky: {
    width: 'fit-content !important',
    '& .MuiAutocomplete-option:last-child': {
      position: 'sticky',
      bottom: '0px',
      padding: '12px 24px 12px 24px',
      margin: '0px',
      height: '48px',
      borderTop: '1px solid black',
      fontFamily: senBold,
    },

    '& .MuiAutocomplete-listbox': {
      paddingBottom: '0px',
    },
  },
}));

export interface EventHandlers {
  handleInputChange: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  handleOnClick: React.MouseEventHandler<HTMLDivElement>;
  handleBlur: React.FocusEventHandler<HTMLDivElement>;
}

interface EmployeeWorkloadCardProps {
  suggestionList: InputSelectOption[];
  inputValue: string;
  handleOnChange: (
    event: ChangeEvent<{}>,
    value?: NonNullable<string | Option>,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<{}>,
  ) => void;
  handleOnInputChange: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
  employee: Employee;
  projects: Project[];
}

const EmployeeWorkloadCardContent = (props: EmployeeWorkloadCardProps): JSX.Element => {
  const classes = useStyles();
  const { suggestionList, handleOnChange, handleOnInputChange, inputValue, employee, projects } = props;

  return (
    <Card className={classes.utilisationCard}>
      <CardContent className={classes.utilisationCardContent}>
        <Typography variant="h2">Auslastung</Typography>
        <div className={classes.inputField}>
          <MUIEXXAutocompleteInputField
            options={suggestionList}
            onChange={handleOnChange}
            onInputChange={handleOnInputChange}
            inputValue={inputValue}
            placeholder={'Bitte eingeben'}
            label={`${employee.forename} ein Projekt hinzufügen`}
            formControlStyle={classes.inputField}
            popperClassname={inputValue !== '' && classes.popperWithSticky}
          />
        </div>
        <div className={classes.calendarView}>
          <Typography variant="h5" className={classes.calendarTitle}>
            Monatsansicht
          </Typography>
          <EmployeeWorkloadTimeline employee={employee} teamData={employee.teams} projects={projects} />
        </div>
        <div className={classes.timelineCaptionWrapper}>
          <TimelineCaption />
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  projects: Project[];
} => {
  return {
    projects: store.project.projectData,
  };
};
export default connect(mapStateToProps)(EmployeeWorkloadCardContent);
