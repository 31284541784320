import { Absence, Employee } from '../../redux/reducers/employee/employee.type';

const EmptyEmployee: Employee = {
  email: '',
  forename: '',
  id: '',
  projects: [],
  surname: '',
  teams: [],
  status: {
    workloadStatus: {
      iHaveTime: false,
      iNeedHelp: false,
    },
    absenceStatus: Absence.I_AM_HERE,
    vacationStatus: [],
  },
  profileImage: '',
  profileImageIsZoomed: false,
  position: '',
};

export default EmptyEmployee;
