// app settings
export const THEME_TYPE_CHANGED = 'THEME_TYPE_CHANGED';
export const APP_STARTED = 'APP_STARTED';
export const STATUS_EDITABLE = 'STATUS_EDITABLE';

// employee requests
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const GET_EMPLOYEE_BY_ID = 'GET_EMPLOYEE_BY_ID';
export const EMPLOYEES_DATA_AVAILABLE = 'EMPLOYEES_DATA_AVAILABLE';
export const TEAM_DATA_AVAILABLE = 'TEAM_DATA_AVAILABLE';
export const TEAM_FILTER_DATA_AVAILABLE = 'TEAM_FILTER_DATA_AVAILABLE';
export const DISPLAYED_EMPLOYEES = 'DISPLAYED_EMPLOYEES';

// employee filters: teams
export const INIT_VISIBILITY_FILTER = 'INIT_VISIBILITY_FILTER';
export const SHOW_ALL = 'SHOW_ALL';
export const SHOW_SPECIFIC_TEAM = 'SHOW_SPECIFIC_TEAM';

// employee filters: click& expand
export const CHANGE_ARRAY_OF_OPENED_EMPLOYEE_CARDS = 'CHANGE_ARRAY_OF_OPENED_EMPLOYEE_CARDS';
export const TOGGLE_ALL_EMPLOYEE_CARDS = 'TOGGLE_ALL_EMPLOYEE_CARDS';

// project requests
export const PROJECT_DATA_AVAILABLE = 'PROJECT_DATA_AVAILABLE';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
export const DELETE_PROJECT_CONFIRMED = 'DELETE_PROJECT_CONFIRMED';
export const SET_SHOW_DIALOG = 'SET_SHOW_DIALOG';
export const CHANGE_DIALOG_MODE = 'CHANGE_DIALOG_MODE';
// User
export const USER_CHANGED = 'USER_CHANGED';

// display and remove red Boxes
export const DISPLAY_RED_BOXES = 'DISPLAY_RED_BOXES';

// Filter
export const SET_BILLABLE_FILTER_STATE = 'SET_BILLABLE_FILTER_STATE';
export const SET_EMPLOYEE_WORKLOAD_LIMIT = 'SET_EMPLOYEE_WORKLOAD_LIMIT';
export const SET_WORKLOAD_PROJECTS = 'SET_WORKLOAD_PROJECTS';
export const CHANGE_EMPLOYEE_SEARCH_VALUE = 'CHANGE_EMPLOYEE_SEARCH_VALUE';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
