import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { showSpecificTeam } from '../../redux/actions/EmployeeActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

const sortTeamNames = (teamNames: string[]): FilterValue[] => {
  const teamNamesTemp = teamNames.filter((team) => {
    return team !== 'alle';
  });

  const sortAlphabetical = (team: string[]) => {
    return team.sort((a, b) => a.localeCompare(b, 'en-EN'));
  };

  const maxNamesTemp = sortAlphabetical(teamNamesTemp.filter((team) => team.includes('max')));
  const friendsNamesTemp = sortAlphabetical(teamNamesTemp.filter((team) => !team.includes('max')));

  const maxFilterValues: FilterValue[] = addSubTeams(maxNamesTemp);
  const friendsFilterValues: FilterValue[] = friendsNamesTemp.map((teamName, index) => ({
    label: teamName,
    value: 100 + index,
  }));

  const teamNameValues: FilterValue[] = [{ label: 'alle', value: -1 }, ...maxFilterValues, ...friendsFilterValues];
  return teamNameValues;
};

const addSubTeams = (teamNames: string[]): FilterValue[] => {
  const generateSubMenu = (parent: string, level: number): FilterValue[] => {
    const subTeams = teamNames
      .filter((teamName) => teamName.startsWith(parent) && teamName.split('.').length === level)
      .map((teamName, index) => ({
        label: teamName,
        value: index,
        hasSubMenu: teamNames.some((subTeam) => subTeam.startsWith(`${teamName}.`)),
        subMenu: teamNames.some((subTeam) => subTeam.startsWith(`${teamName}.`))
          ? generateSubMenu(`${teamName}.`, level + 1)
          : undefined,
      }));

    return subTeams;
  };
  return generateSubMenu('', 1);
};

interface TeamFilterProps {
  teamNames: string[];
  currentEmployeeFilter: string;
  isAllEmployeesLoaded: boolean;
}

const ALL_TEAMS = 'alle';
const SEARCH_PARAM_TEAM = 'team';

const TeamFilter = (props: TeamFilterProps): JSX.Element => {
  const { teamNames = [], currentEmployeeFilter, isAllEmployeesLoaded } = props;
  const dispatch = useDispatch();
  const sortedTeamNames = sortTeamNames(teamNames);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // URL Filter Variablen
  const location = useLocation();
  const history = useHistory();

  const changeTeamURL = (teamNameFilter: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(SEARCH_PARAM_TEAM, teamNameFilter.replace(/\s/g, '_').replace(/&/g, '＆'));

    if (teamNameFilter === ALL_TEAMS) {
      searchParams.delete(SEARCH_PARAM_TEAM);
    }

    const newSearchString = searchParams.toString();

    if (location.search !== newSearchString) {
      history.replace({ search: newSearchString });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(SEARCH_PARAM_TEAM) && isAllEmployeesLoaded) {
      const val = searchParams.get(SEARCH_PARAM_TEAM);
      const teamValue = val.replace(/＆/g, '&').replace(/_/g, ' ');

      dispatch(showSpecificTeam(teamValue));
    }
    return () => {
      dispatch(showSpecificTeam(ALL_TEAMS));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllEmployeesLoaded]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTeamFilterItemClicked = (teamNameFilter: FilterValue): void => {
    dispatch(showSpecificTeam(teamNameFilter.label));
    changeTeamURL(teamNameFilter.label);
    handleMenuClose();
  };

  const handleTeamFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <FilterBase
      filterHeading={'Team'}
      open={open}
      anchorEl={anchorEl}
      filterState={currentEmployeeFilter}
      filterValuesItems={sortedTeamNames}
      handleMenuItemClick={handleTeamFilterItemClicked}
      handleFilterClick={handleTeamFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  teamNames: string[];
  currentEmployeeFilter: string;
  isAllEmployeesLoaded: boolean;
} => {
  return {
    teamNames: store.employee.teamData,
    currentEmployeeFilter: store.employee.shownSpecificTeam,
    isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
  };
};

export default withRouter(connect(mapStateToProps)(TeamFilter));
