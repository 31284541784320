import { makeStyles } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import { TimelineGroup, TimelineMarkers, TodayMarker } from 'react-calendar-timeline';

import { Employee } from '../../redux/reducers/employee/employee.type';
import { RedBox, RedBoxArray, getEmployeeBox, getWidthforMonth } from '../../utils/timelineUtils';
import RedBoxes from './RedBoxes';
import { CustomGroupParams } from './getTimelineGroups';

interface RedBoxMarkersProps {
  visibleMonthCount: number;
  groups: TimelineGroup<CustomGroupParams>[];
  allGroupEmployeeBoxes: Element[];
  labelFormat: string;
}

const useStyles = makeStyles(() => ({
  todayMarker: {
    background:
      'repeating-linear-gradient(to bottom, black 0px, black 4px, transparent 4px, transparent 12px) !important',
  },
}));

const RedBoxMarkers = (props: RedBoxMarkersProps): JSX.Element => {
  const { visibleMonthCount, groups, allGroupEmployeeBoxes, labelFormat } = props;
  const classes = useStyles();

  const allRedBoxes: RedBoxArray = [];

  groups
    .filter((employee) => employee.empId)
    .forEach((timelineGroup: TimelineGroup<CustomGroupParams>) => {
      const employee: Employee = timelineGroup.employee;
      const months = timelineGroup.months;
      const monthKeys = Object.keys(months);

      const redBoxData: RedBox[] = [];

      monthKeys.forEach((monthKey) => {
        const month = dayjs(monthKey, 'MM/YY');
        const isRed = months[monthKey].percentageBillable < 50;

        if (isRed) {
          // Elements..
          const firstRowElement = document.querySelector('.rct-hl-even ');
          const sideBarElement: HTMLElement = getEmployeeBox(allGroupEmployeeBoxes, employee.id);
          const reactScrollElement = document.getElementsByClassName('rct-scroll')[0];

          // calculate top of Red Boxes
          const sideBarTop = sideBarElement ? sideBarElement.getBoundingClientRect().y : 0;
          const viewportTop = reactScrollElement ? reactScrollElement.getBoundingClientRect().y : 0;
          const top: string = sideBarElement
            ? sideBarTop - viewportTop + 'px'
            : firstRowElement?.getBoundingClientRect().y - viewportTop + 'px';

          // calculate width and height of redboxes
          const width = getWidthforMonth(month, reactScrollElement, visibleMonthCount, labelFormat);
          const height: string = sideBarElement
            ? sideBarElement.style.height
            : firstRowElement?.getBoundingClientRect().height + 'px';

          // set redBoxstyling
          const redBox: RedBox = {
            employee: employee,
            start_time: month.startOf('month'),
            height: height,
            top: top,
            width: width,
          };
          redBoxData.push(redBox);
        }
      });

      allRedBoxes.push(redBoxData);
    });

  return (
    <TimelineMarkers>
      {<RedBoxes allRedBoxes={allRedBoxes} />}
      <TodayMarker date={dayjs().toDate().valueOf()}>
        {({ styles }) => <div style={styles} className={classes.todayMarker} />}
      </TodayMarker>
    </TimelineMarkers>
  );
};

export default RedBoxMarkers;
