import { faInfoCircle, faLightEmergencyOn, faMugTea, faUfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import { Absence, Workload, WorkloadStatus } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';

interface StatusChipsProps {
  variant: StatusChipsVariants;
}

export type StatusChipsVariants = Absence | keyof WorkloadStatus;

interface StyleProps {
  color: string;
  hovered: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  chip: ({ color, hovered }) => ({
    border: `1px solid ${colors.mainColor}`,
    borderRadius: '16px',
    backgroundColor: color,
    width: hovered ? '144px' : '28px',
    height: '28px',
    display: 'flex',
    transition: 'width 0.4s ease-in-out',
    gap: '8px',
    alignItems: 'center',
    paddingLeft: '6px',
  }),
  icon: {
    width: '14px',
    height: '14px',
  },
  miniText: ({ hovered }) => ({
    fontSize: '12px',
    lineHeight: '16px',
    backgroundColor: 'transparent',
    whiteSpace: 'nowrap',
    transition: 'all 0.5s ease-out allow-discrete',
    overflow: 'hidden',
    opacity: hovered ? '1' : '0',
  }),
}));

const StatusChip = (props: StatusChipsProps) => {
  const { variant } = props;
  const [hovered, setHovered] = useState(false);
  let text, icon, color;

  switch (variant) {
    case Absence.I_AM_ABSENT: {
      text = 'Diesmal nicht da';
      icon = faMugTea;
      color = colors.turqoise200;
      break;
    }
    case Absence.I_AM_PERMANENTLY_ABSENT: {
      text = 'Bin nie dabei';
      icon = faUfo;
      color = colors.white;
      break;
    }
    case Workload.I_NEED_HELP: {
      text = 'Ich brauche Hilfe';
      icon = faLightEmergencyOn;
      color = colors.red100;
      break;
    }
    case Workload.I_HAVE_TIME: {
      text = 'Habe Zeit übrig';
      icon = faInfoCircle;
      color = colors.yellow200;
      break;
    }
  }

  const classes = useStyles({ color, hovered });

  return (
    <div
      className={`${classes.chip}`}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <FontAwesomeIcon icon={icon} className={classes.icon} />
      {
        <Typography variant="body2" className={classes.miniText}>
          {text}
        </Typography>
      }
    </div>
  );
};

export default StatusChip;
