import { Vacation } from '../../../components/status/Urlaubsstatus';

export interface EmployeeStore {
  teamData: string[];
  employeeData: Employee[];
  displayedEmployees: Employee[];
  openEmployeeCards: number[];
  shownSpecificTeam: string;
  statusTag: string[];
  profileImage: string;
}

export interface Employee {
  email?: string;
  forename?: string;
  id?: string;
  projects?: string[];
  surname?: string;
  teams?: string[];
  status?: Status;
  profileImage?: string;
  profileImageIsZoomed?: boolean;
  position: string;
}

export interface WorkloadStatus {
  iHaveTime: boolean;
  iNeedHelp: boolean;
}

export enum Workload {
  I_HAVE_TIME = 'iHaveTime',
  I_NEED_HELP = 'iNeedHelp',
}

export enum Absence {
  I_AM_HERE = 'I_AM_HERE',
  I_AM_ABSENT = 'I_AM_ABSENT',
  I_AM_PERMANENTLY_ABSENT = 'I_AM_PERMANENTLY_ABSENT',
}

export type AbsenceStatus = Absence.I_AM_HERE | Absence.I_AM_ABSENT | Absence.I_AM_PERMANENTLY_ABSENT;

export interface Status {
  workloadStatus: WorkloadStatus;
  absenceStatus: AbsenceStatus;
  vacationStatus: Vacation[];
}
