import { Theme, makeStyles } from '@material-ui/core/styles';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import React, { useEffect, useState } from 'react';
import Timeline, { DateHeader, Id, TimelineGroup, TimelineHeaders, TimelineItem } from 'react-calendar-timeline';
import { useDispatch } from 'react-redux';

import { senBold } from '../../fonts/fonts';
import { changeDialogMode, setShowDialog } from '../../redux/actions/ProjectActions';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { useRouterNav } from '../../useRouterNav';
import { DialogNewProjectMode } from '../Dialogs/DialogNewProject/DialogNewProject';
import CustomTimelineGroup from './CustomTimelineGroup';
import CustomTimelineItem from './CustomTimelineItem';
import RedBoxMarkers from './RedBoxMarkers';
import './customTimeline.css';
import { CustomGroupParams } from './getTimelineGroups';
import { CustomItemParams } from './getTimelineItems';

const useStyles = makeStyles<Theme>((theme) => ({
  dateHeader: {
    height: '24px !important',
    display: 'flex',
    alignItems: 'center',
    fontFamily: senBold,
  },
}));

export interface CustomTimelineProps {
  groups: TimelineGroup<CustomGroupParams>[];
  items: TimelineItem<CustomItemParams>[];
  employees: Employee[];
  hideSidebar: boolean;
  timelineStartTime?: Date;
  timelineEndTime?: Date;
  startDay: Dayjs;
  visibleMonthCount: number;
  labelFormat?: string;
}

const CustomTimeline = (props: CustomTimelineProps): JSX.Element => {
  const {
    groups,
    items,
    employees,
    hideSidebar,
    timelineStartTime,
    timelineEndTime,
    startDay,
    visibleMonthCount,
    labelFormat = 'MM/YY',
  } = props;

  const classes = useStyles();

  const today = startDay;

  dayjs.locale('de');

  const minTime = today.startOf('month').valueOf();
  const maxTime = today.add(visibleMonthCount, 'month').startOf('month').valueOf();
  // Change to this one to enable scrolling
  /*const minTime = today.subtract(12, 'month').startOf('month').valueOf();
  const maxTime = today.add(18, 'month').startOf('month').valueOf();*/

  const [allGroupEmployeeBoxes, setAllGroupEmployeeBoxes] = useState<HTMLElement[]>([]);
  const dispatch = useDispatch();

  const [sortedItems, setSortedItems] = useState<TimelineItem<CustomItemParams>[]>(items);

  const handleTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void,
  ) => {
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart));
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const handleGroupRef = (ref: HTMLElement) => {
    if (ref) {
      const parentNode = ref.parentNode as HTMLElement;
      setAllGroupEmployeeBoxes((prev) => {
        const newSet = new Set([...prev, parentNode]);
        return Array.from(newSet);
      });
    }
  };

  const { goToManageEmployeeAbsences } = useRouterNav();
  const onItemSelect = (itemId: Id, e: React.SyntheticEvent, time: number): void => {
    if (!itemId.toString().includes('Urlaub')) {
      const item = items.find((item) => item.id === itemId);
      dispatch(changeDialogMode({ mode: DialogNewProjectMode.EDIT, projectName: item.name }));
      dispatch(setShowDialog(true));
    } else {
      const employeeOfItem = employees.find((employee) => employee.id === itemId.toString().split('_')[0]);
      if (employeeOfItem) {
        goToManageEmployeeAbsences(employeeOfItem);
      }
    }
  };

  useEffect(() => {
    // Sort the items array and set it to sortedItems state

    const newSortedItems = items
      .sort((a, b) => {
        if (a.percentageTime !== b.percentageTime) {
          // Sortiere zuerst nach percentageTime in absteigender Reihenfolge
          return b.percentageTime - a.percentageTime;
        } else {
          // Wenn percentageTime gleich ist, sortiere nach start_time in aufsteigender Reihenfolge
          return a.start_time - b.start_time;
        }
      })
      .map((item) => {
        item.start_time = item.start_time <= minTime ? minTime : item.start_time;
        item.end_time = item.end_time >= maxTime ? maxTime : item.end_time;
        return item;
      });
    setSortedItems(newSortedItems);
  }, [items, minTime, maxTime]);

  return (
    <div>
      <Timeline
        sidebarWidth={hideSidebar ? 0 : 216}
        dragSnap={1000 * 60 * 60 * 24 * 7} // TODO: Fix steps of movement
        groups={groups}
        items={sortedItems}
        defaultTimeStart={timelineStartTime ? dayjs(timelineStartTime).toDate() : dayjs().startOf('month').toDate()}
        defaultTimeEnd={
          timelineEndTime
            ? dayjs(timelineEndTime).toDate()
            : dayjs().add(visibleMonthCount, 'month').startOf('month').toDate()
        }
        onTimeChange={handleTimeChange}
        maxZoom={2 * 365.24 * 86400 * 1000}
        minZoom={0.5 * 365.24 * 86400 * 1000}
        timeSteps={{
          second: 0,
          minute: 0,
          hour: 0,
          day: 0,
          month: 1,
          year: 1,
        }} //set any step that you don't want to be able to zoom in to zero
        itemRenderer={(props) => <CustomTimelineItem {...props} minTime={minTime} maxTime={maxTime} />}
        groupRenderer={(props) => (
          <CustomTimelineGroup
            {...props}
            hideSidebar={hideSidebar}
            employees={employees}
            handleGroupRef={handleGroupRef}
          />
        )}
        lineHeight={80} // definiert die Höhe eines Items
        itemHeightRatio={1}
        onItemSelect={onItemSelect}
        onItemClick={onItemSelect}
      >
        <TimelineHeaders>
          <DateHeader
            unit="month"
            className={classes.dateHeader}
            labelFormat={labelFormat}
            intervalRenderer={({ getIntervalProps, intervalContext }) => {
              return (
                <div className="rct-dateHeader " {...getIntervalProps()}>
                  <span>
                    {labelFormat === 'MMMM YYYY'
                      ? dayjs(intervalContext.intervalText, labelFormat, 'en').locale('de').format(labelFormat)
                      : intervalContext.intervalText}
                  </span>
                </div>
              );
            }}
          />
        </TimelineHeaders>
        <RedBoxMarkers
          visibleMonthCount={visibleMonthCount}
          groups={groups}
          labelFormat={labelFormat}
          allGroupEmployeeBoxes={allGroupEmployeeBoxes}
        ></RedBoxMarkers>
      </Timeline>
    </div>
  );
};

export default CustomTimeline;
