import { Theme, makeStyles } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';

import colors from '../../utils/colors';
import { StickyComponent } from '../StickyComponent';
import PageSearchField from './PageSearchField';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    padding: '0px 24px 8px 24px',
    justifyContent: 'left',
    backgroundColor: colors.white,
    zIndex: 110,
  },

  filterRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface SubHeaderProps {
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  searchValue: string;
  placeholder: string;
  children?: React.ReactNode;
  stuckClass?: string;
}

const SubHeader = (props: SubHeaderProps): JSX.Element => {
  const { handleInputChange, searchValue, placeholder, children, stuckClass } = props;
  const classes = useStyles();

  return (
    <StickyComponent
      unstuckClasses={`${classes.header}`}
      stuckClasses={`${classes.header} ${stuckClass}`}
      positionValue={82}
      position="top"
    >
      <PageSearchField searchValue={searchValue} onChange={handleInputChange} placeholder={placeholder} />
      <div className={classes.filterRow}>{children}</div>
    </StickyComponent>
  );
};

export default SubHeader;
