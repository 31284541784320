import { faArrowLeft, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardContent, IconButton, Theme, Typography, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';

import Abwesenheitsstatus from '../../components/status/Abwesenheitsstatus';
import Urlaubsstatus from '../../components/status/Urlaubsstatus';
import { ApplicationStore } from '../../redux/reducers';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { useRouterNav } from '../../useRouterNav';
import {
  createEmailFromUniqueEmployeeName,
  createUniqueEmployeeNameFromUrl,
  getEmployeeFromEmail,
} from '../../utils/employeeUtils';
import { StatusStore } from './EmployeePage';

interface StatusEinstellungenProps {
  employee: Employee;
  handleSave: Function;
  employeeState: StatusStore;
  onCancelClick: Function;
  handleDeleteInputRow: Function;
  handleAddNewInputs: Function;
  handleDateInput: Function;
  handleAbsenceValue: Function;
  handleDateError: Function;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    margin: '0 auto',
  },
  spinnerWrapper: {
    paddingTop: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'auto',
  },
  backToViewLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'flex-end',
    margin: '32px 0px 24px 0px',
    paddingLeft: '8px',
  },
  backToViewIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '12px',
  },
  firstSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  statusCard: {
    width: '1016px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    height: '100%',
  },
}));

const StatusEinstellungen = (props: StatusEinstellungenProps) => {
  const {
    employee,
    employeeState,
    handleSave,
    onCancelClick,
    handleAddNewInputs,
    handleDateInput,
    handleDeleteInputRow,
    handleAbsenceValue,
    handleDateError,
  } = props;
  const { errorShowed, isChanged } = employeeState;
  const classes = useStyles();

  const { goToEmployeeProfile } = useRouterNav();

  const handleClose = () => {
    goToEmployeeProfile(employee);
  };

  return !employee ? (
    <div className={classes.spinnerWrapper}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.backToViewLine}>
        <IconButton color="primary" onClick={handleClose}>
          <div className={classes.backToViewIcon}>
            <FontAwesomeIcon icon={faArrowLeft} className={classes.arrowIcon} />
            <Typography variant="body1">Zurück zum Profil von {employee.forename}</Typography>
          </div>
        </IconButton>
      </div>
      <Card className={classes.statusCard}>
        <CardContent className={classes.cardContent}>
          <div className={classes.firstSection}>
            <div className={classes.titleRow}>
              <Typography variant="h2">Abwesenheiten verwalten</Typography>
              <IconButton onClick={handleClose}>
                <FontAwesomeIcon icon={faXmark} />
              </IconButton>
            </div>
            <Typography variant="body1">
              Bitte gibt hier an, wie du an dem Regeltermin montags anwesend bist. Du kannst auch deine Urlaubszeiten
              angeben. Während deinem Urlaub wirst du automatisch für den Regeltermin als abwesend eingetragen.
            </Typography>
          </div>
          <Abwesenheitsstatus handleAbsenceValue={handleAbsenceValue} employeeStatus={employeeState.status} />
          <Urlaubsstatus
            errorIsShowed={errorShowed}
            handleAddNewInputs={handleAddNewInputs}
            handleDeleteInputRow={handleDeleteInputRow}
            handleDateInput={handleDateInput}
            employeeStatus={employeeState.status}
            handleDateError={handleDateError}
          />
          <div className={classes.actionRow}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onCancelClick();
                handleClose();
              }}
            >
              Verwerfen
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                (!employeeState.isValid && errorShowed) ||
                !(isChanged.absence || isChanged.vacation || isChanged.workload)
              }
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                handleSave(employee, false);
                if (employeeState.isValid) {
                  handleClose();
                }
              }}
            >
              Speichern
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    employee: getEmployeeFromEmail(
      createEmailFromUniqueEmployeeName(createUniqueEmployeeNameFromUrl(window.location.href)),
      store.employee.employeeData,
    ),
  };
};

export default connect(mapStateToProps)(StatusEinstellungen);
