import { TableCell, TableRow, Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import dayjs from 'dayjs';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Project, ProjectMember } from '../../redux/actions/ProjectActions';
import { Employee } from '../../redux/reducers/employee/employee.type';
import MUIEXXAutocompleteInputField from '../BaseComponentsExxetaStyle/MUIEXXAutocompleteInputField';
import { Option } from '../BaseComponentsExxetaStyle/MUIEXXAutocompleteInputField';
import MUIEXXCheckBox from '../BaseComponentsExxetaStyle/MUIEXXCheckBox';
import MUIEXXDatePicker from '../BaseComponentsExxetaStyle/MUIEXXDatePicker';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    formControl: {
      width: '100%',
      margin: '0',
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        border: '0 !important',
        padding: '0 !important',
      },

      '& .MuiAutocomplete-endAdornment': {
        position: 'relative',
      },
      '& .MuiAutocomplete-popupIndicator': {
        marginRight: '0',
        marginLeft: '0',
      },
    },

    deleteCell: {
      display: 'flex',
      justifyContent: 'end',
      marginRight: navigator.userAgent.indexOf('Firefox') !== -1 ? '16px' : '0',
    },

    inputCell: {
      paddingRight: '32px',
    },

    employeeName: {
      flex: 1,
      width: '370px',
      '& .MuiSelect-select': {
        color: 'black',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

interface ProjectMemberTableRowProps extends WithStyles<typeof styles> {
  employees: Employee[];
  setProjectMemberList: (updatedList: ProjectMember[]) => void;
  projectMemberList: ProjectMember[];
  givenProjectMember: ProjectMember;
  checkBoxIsSelected: boolean;
  onClickCheckBox: () => void;
  project: Project;
  employee: Employee;
  rowIndex: number;
  setTableRowsCompleted: React.Dispatch<React.SetStateAction<boolean[]>>;
}

interface EmployeeDataForProject {
  employeeName: string;
  percentageTime: string;
  percentageProbability: string;
}

const ProjectMemberTableRow = (props: ProjectMemberTableRowProps): JSX.Element => {
  const {
    classes,
    employees,
    projectMemberList,
    setProjectMemberList,
    givenProjectMember,
    onClickCheckBox,
    checkBoxIsSelected,
    project,
    employee,
    rowIndex,
    setTableRowsCompleted,
  } = props;

  const percentageProbabilityMenuItems = [
    { value: 25, label: 'unsicher' },
    { value: 50, label: 'wahrscheinlich' },
    { value: 75, label: 'mündliche Zusage' },
    { value: 100, label: 'beauftragt' },
  ];

  const projectPercentageMenuItems = [
    { value: 10, label: '10 %' },
    { value: 20, label: '20 %' },
    { value: 30, label: '30 %' },
    { value: 40, label: '40 %' },
    { value: 50, label: '50 %' },
    { value: 60, label: '60 %' },
    { value: 70, label: '70 %' },
    { value: 80, label: '80 %' },
    { value: 90, label: '90 %' },
    { value: 100, label: '100 %' },
  ];

  const lineMemberOptions: Option[] = employees.map((employee) => ({
    label: `${employee.forename} ${employee.surname}`,
    value: employee.email,
  }));

  const [employeeDataForProjectInput, setEmployeeDataForProjectInput] = useState<EmployeeDataForProject>({
    employeeName: givenProjectMember.employeeEmail ? `${employee.forename} ${employee.surname}` : '',
    percentageProbability: givenProjectMember.percentageProbability
      ? percentageProbabilityMenuItems.find(
          (filterValue) => filterValue.value === givenProjectMember.percentageProbability,
        ).label
      : '',
    percentageTime: givenProjectMember.percentageTime ? givenProjectMember.percentageTime + ' %' : '',
  });

  const updateProjectMember = useCallback(
    (updatedMember: ProjectMember) => {
      const newList = [...projectMemberList];
      newList[rowIndex] = updatedMember;
      setProjectMemberList(newList);
    },
    [projectMemberList, rowIndex, setProjectMemberList],
  );

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>, options: Option[], id: string): void => {
    const isEmployee = id === 'employeeName';

    const projectMemberId = isEmployee ? 'employeeEmail' : id;

    if (event) {
      if (!event.target.value) {
        setEmployeeDataForProjectInput({ ...employeeDataForProjectInput, [id]: '' });
        updateProjectMember({ ...givenProjectMember, [projectMemberId]: '' });
      } else if (event.type === 'change') {
        setEmployeeDataForProjectInput({ ...employeeDataForProjectInput, [id]: event.currentTarget.value || null });
      } else if (event.type === 'blur' && event.target.value) {
        const input = JSON.parse(JSON.stringify(event.target.value));
        if (options.findIndex((option) => option.label === input) >= 0) {
          const option: Option = options.find((option) => option.label === input);

          setEmployeeDataForProjectInput({ ...employeeDataForProjectInput, [id]: input });
          updateProjectMember({ ...givenProjectMember, [projectMemberId]: option.value });
        } else {
          setEmployeeDataForProjectInput({ ...employeeDataForProjectInput, [id]: '' });
          updateProjectMember({ ...givenProjectMember, [projectMemberId]: '' });
        }
      } else if (event.type !== 'click' && event.target.value) {
        const option: Option = JSON.parse(JSON.stringify(event.target.value));

        setEmployeeDataForProjectInput({
          ...employeeDataForProjectInput,
          [id]: option.label,
        });
        updateProjectMember({ ...givenProjectMember, [projectMemberId]: option.value });
      }
    }
  };

  const handleOnChange = (value: NonNullable<string | Option>, options: Option[], id: string) => {
    const tmpEvent = {};
    Object.defineProperty(tmpEvent, 'target', {
      writable: false,
      value: { value: value ? value : null },
    });

    Object.defineProperty(tmpEvent, 'currentTarget', {
      writable: false,
      value: {},
    });
    handleOnInputChange(tmpEvent as ChangeEvent<HTMLInputElement>, options, id);
  };

  useEffect(() => {
    setTableRowsCompleted((prev) => {
      const list = [...prev];
      if (projectMemberList.length === 1 && employeeDataForProjectInput.employeeName === '') {
        return [];
      } else {
        list[rowIndex] =
          employeeDataForProjectInput.employeeName !== '' &&
          employeeDataForProjectInput.percentageProbability !== '' &&
          employeeDataForProjectInput.percentageTime !== '';
      }

      return list.slice(0, projectMemberList.length);
    });
  }, [employeeDataForProjectInput, projectMemberList.length, rowIndex, setTableRowsCompleted]);

  useEffect(() => {
    if (
      dayjs(project.startDate, 'DD.MM.YYYY').isAfter(dayjs(givenProjectMember.startDate, 'DD.MM.YYYY')) ||
      dayjs(project.endDate, 'DD.MM.YYYY').isBefore(dayjs(givenProjectMember.startDate, 'DD.MM.YYYY'))
    ) {
      updateProjectMember({ ...givenProjectMember, startDate: project.startDate });
    }

    if (
      dayjs(project.endDate, 'DD.MM.YYYY').isBefore(dayjs(givenProjectMember.endDate, 'DD.MM.YYYY')) ||
      dayjs(project.startDate, 'DD.MM.YYYY').isAfter(dayjs(givenProjectMember.endDate, 'DD.MM.YYYY'))
    ) {
      updateProjectMember({ ...givenProjectMember, endDate: project.endDate });
    }
  }, [project.endDate, project.startDate, givenProjectMember, updateProjectMember]);

  return (
    <TableRow>
      <TableCell className={classes.inputCell}>
        <MUIEXXAutocompleteInputField
          formControlStyle={classes.formControl}
          options={lineMemberOptions}
          inputValue={employeeDataForProjectInput.employeeName}
          filterOptions={(options, inputValue) =>
            options.filter((option) => {
              const notInCurrentList = !projectMemberList.some(
                (projectMember) => projectMember.employeeEmail === option.value,
              );
              return !notInCurrentList
                ? false
                : option.value.toString().toLowerCase().includes(inputValue.inputValue.toLowerCase());
            })
          }
          placeholder={'Bitte auswählen'}
          onChange={(_, value: NonNullable<string | Option>) => {
            handleOnChange(value, lineMemberOptions, 'employeeName');
          }}
          onInputChange={(event) => {
            handleOnInputChange(event as React.ChangeEvent<HTMLInputElement>, lineMemberOptions, 'employeeName');
          }}
          noOptionsText={employeeDataForProjectInput.employeeName + ' nicht gefunden oder bereits in der Liste'}
        />
      </TableCell>
      <TableCell className={classes.inputCell}>
        <MUIEXXAutocompleteInputField
          formControlStyle={classes.formControl}
          options={projectPercentageMenuItems}
          filterOptions={(options) => options}
          getOptionSelected={(option) => employeeDataForProjectInput.percentageTime === option.label}
          inputValue={employeeDataForProjectInput.percentageTime}
          placeholder={'Bitte auswählen'}
          onChange={(_, value: NonNullable<string | Option>) => {
            handleOnChange(value, projectPercentageMenuItems, 'percentageTime');
          }}
          onInputChange={(event) => {
            handleOnInputChange(
              event as React.ChangeEvent<HTMLInputElement>,
              projectPercentageMenuItems,
              'percentageTime',
            );
          }}
          noOptionsText={'Bitte Zehnerzahlen eingeben'}
        />
      </TableCell>
      <TableCell className={classes.inputCell}>
        <MUIEXXAutocompleteInputField
          formControlStyle={classes.formControl}
          getOptionSelected={(option) => employeeDataForProjectInput.percentageProbability === option.label}
          options={percentageProbabilityMenuItems}
          filterOptions={(options) => options}
          inputValue={employeeDataForProjectInput.percentageProbability}
          placeholder={'Bitte auswählen'}
          onChange={(_, value: NonNullable<string | Option>) => {
            handleOnChange(value, percentageProbabilityMenuItems, 'percentageProbability');
          }}
          onInputChange={(event) =>
            handleOnInputChange(
              event as React.ChangeEvent<HTMLInputElement>,
              percentageProbabilityMenuItems,
              'percentageProbability',
            )
          }
          noOptionsText={'Nur 25er-Schritte möglich'}
        />
      </TableCell>
      <TableCell className={classes.inputCell}>
        <MUIEXXDatePicker
          fieldName={'projectStart'}
          formControlStyle={classes.formControl}
          value={dayjs(givenProjectMember.startDate, 'DD.MM.YYYY')}
          onChange={(date) => updateProjectMember({ ...givenProjectMember, startDate: date.format('DD.MM.YYYY') })}
          minDate={dayjs(project.startDate, 'DD.MM.YYYY')}
          maxDate={dayjs(project.endDate, 'DD.MM.YYYY')}
        />
      </TableCell>
      <TableCell className={classes.inputCell}>
        <MUIEXXDatePicker
          fieldName={'projectEnd'}
          formControlStyle={classes.formControl}
          value={dayjs(givenProjectMember.endDate, 'DD.MM.YYYY')}
          onChange={(date) => updateProjectMember({ ...givenProjectMember, endDate: date.format('DD.MM.YYYY') })}
          minDate={dayjs(givenProjectMember.startDate, 'DD.MM.YYYY')}
          maxDate={dayjs(project.endDate, 'DD.MM.YYYY')}
        />
      </TableCell>
      <TableCell>
        <div className={classes.deleteCell}>
          <MUIEXXCheckBox
            onChange={onClickCheckBox}
            onClick={onClickCheckBox}
            checked={checkBoxIsSelected}
            disabled={
              !employeeDataForProjectInput.employeeName &&
              !employeeDataForProjectInput.percentageProbability &&
              !employeeDataForProjectInput.percentageTime
            }
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ProjectMemberTableRow);
