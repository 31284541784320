import { faFolderArrowDown, faFolderArrowUp, faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  createStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React from 'react';

import { Project } from '../redux/actions/ProjectActions';
import { Employee } from '../redux/reducers/employee/employee.type';
import colors from '../utils/colors';
import { getEmployeeFromEmail } from '../utils/employeeUtils';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    table: {
      padding: '0px 0px 40px 40px',
      minWidth: 650,
      height: '78vh',
    },

    chip: {
      marginBottom: theme.spacing(1),
    },

    buttonRoot: {
      border: 'none',
    },

    buttonText: {
      textTransform: 'none',
      fontWeight: 600,
    },

    rowInactive: {
      color: theme.palette.text.disabled,
    },

    iconCell: {
      width: '3%',
      padding: theme.spacing(1),
    },
    avatarSize: {
      width: 24,
      height: 24,
    },
    zoomedAvatarSize: { width: 55, height: 55, transform: 'translate(0px,10px)' },
    icon: {
      width: '16px',
      height: '16px',
      color: colors.mainColor,
    },
  });

const columns: { id: string; label: string; align: string }[] = [
  { id: 'name', label: 'Projektbezeichnung', align: 'left' },
  { id: 'customer', label: 'Kunde', align: 'left' },
  { id: 'description', label: 'Beschreibung', align: 'left' },
  { id: 'members', label: 'Projekt-Teilnehmende', align: 'left' },
];

export interface ProjectTableListProps extends WithStyles<typeof styles> {
  projects: Project[];
  onEditProject: (project: Project) => void;
  onArchiveProject: (project: Project, value: boolean) => void;
  onDeleteProject: (project: Project) => void;
  employees: Employee[];
}

const ProjectTableList = (props: ProjectTableListProps): JSX.Element => {
  const { projects, onEditProject, onArchiveProject, onDeleteProject, classes, employees } = props;

  const makeTableBody = (): JSX.Element[] => {
    return projects.map((project: Project) => {
      return (
        <TableRow tabIndex={-1} key={project.name}>
          {columns.map((column) => {
            if (column.id === 'members') {
              return (
                <TableCell key={column.id} align="left">
                  {project.projectMembers &&
                    project.projectMembers.map((projectMember, index) => {
                      const emp = getEmployeeFromEmail(projectMember.employeeEmail, employees);
                      return emp === null ? null : (
                        <Chip
                          className={classes.chip}
                          key={index + emp.forename}
                          avatar={
                            <Avatar
                              alt="profil-bild"
                              src={emp.profileImage}
                              imgProps={{
                                className: emp.profileImageIsZoomed ? classes.zoomedAvatarSize : classes.avatarSize,
                              }}
                            />
                          }
                          label={emp.forename}
                          variant="outlined"
                          disabled={project.archived}
                        />
                      );
                    })}
                </TableCell>
              );
            }
            const value = project[column.id as keyof Project] as string;
            return (
              <TableCell
                key={column.id}
                align="left"
                classes={{ root: project.archived ? classes.rowInactive : classes.rowActive }}
              >
                {value}
              </TableCell>
            );
          })}
          <TableCell key="edit" align="left">
            <IconButton onClick={() => onEditProject(project)} disabled={project.archived}>
              {project.archived ? (
                <FontAwesomeIcon icon={faPen} className={classes.icon} />
              ) : (
                <Tooltip title="Bearbeiten">
                  <FontAwesomeIcon icon={faPen} className={classes.icon} />
                </Tooltip>
              )}
            </IconButton>
          </TableCell>
          {project.archived ? (
            <TableCell key="unarchive" align="left">
              <IconButton onClick={() => onArchiveProject(project, false)}>
                <Tooltip title="Unarchivieren">
                  <FontAwesomeIcon icon={faFolderArrowUp} className={classes.icon} />
                </Tooltip>
              </IconButton>
            </TableCell>
          ) : (
            <TableCell key="archive" align="left">
              <IconButton onClick={() => onArchiveProject(project, true)}>
                <Tooltip title="Archivieren">
                  <FontAwesomeIcon icon={faFolderArrowDown} className={classes.icon} />
                </Tooltip>
              </IconButton>
            </TableCell>
          )}
          <TableCell key="delete" align="left">
            <IconButton onClick={() => onDeleteProject(project)}>
              <Tooltip title="Löschen">
                <FontAwesomeIcon icon={faTrash} className={classes.icon} />
              </Tooltip>
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell variant="head">
              <Typography variant="h5">{columns[0].label}</Typography>
            </TableCell>
            <TableCell variant="head">
              <Typography variant="h5">{columns[1].label}</Typography>
            </TableCell>
            <TableCell variant="head">
              <Typography variant="h5">{columns[2].label}</Typography>
            </TableCell>
            <TableCell variant="head">
              <Typography variant="h5">{columns[3].label}</Typography>
            </TableCell>
            <TableCell className={classes.iconCell} variant="head"></TableCell>

            <TableCell className={classes.iconCell} variant="head"></TableCell>

            <TableCell className={classes.iconCell} variant="head"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{makeTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

ProjectTableList.defaultProps = {};

export default withStyles(styles)(ProjectTableList);
