import { faUserMinus } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Project, ProjectMember } from '../../redux/actions/ProjectActions';
import { Employee } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import MUIEXXCheckBox from '../BaseComponentsExxetaStyle/MUIEXXCheckBox';
import ProjectMemberTableRow from './ProjectMemberTableRow';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    tableContainer: {
      maxHeight: '230px',
      width: '100%',
      margin: '24px 0px',
      overflowY: 'scroll',
      paddingRight: '0px !important',
    },
    notice: {
      color: colors.grey600,
      marginTop: '4px',
    },
    nameCell: {
      minWidth: '330px',
      whiteSpace: 'nowrap',
    },
    addAndDeleteSection: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '32px',
    },
    addIcon: {
      width: '32px',
      height: '32px',
    },
    dateCell: {
      width: '172px',
      whiteSpace: 'nowrap',
    },
    deleteCell: {
      paddingRight: '0px',
      whiteSpace: 'nowrap',
      '& .MuiCheckbox-root': {
        margin: '0px',
      },
    },
    deleteCellContent: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: navigator.userAgent.indexOf('Firefox') !== -1 ? '16px' : '0',
    },
    worktimeCell: {
      width: '208px',
      whiteSpace: 'nowrap',
    },
    probabilityCell: {
      width: '210px',
    },
  });

interface ProjectMemberTableProps extends WithStyles<typeof styles> {
  employees: Employee[];
  setProjectMemberList: React.Dispatch<React.SetStateAction<ProjectMember[]>>;
  projectMemberList: ProjectMember[];
  project: Project;
  setTableRowsCompleted: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const ProjectMemberTable = (props: ProjectMemberTableProps): JSX.Element => {
  const { classes, employees, project, projectMemberList, setProjectMemberList, setTableRowsCompleted } = props;
  const initState: ProjectMember = useMemo(
    () => ({
      employeeEmail: '',
      projectName: project.name,
      customer: project.customer,
      startDate: project.startDate,
      endDate: project.endDate,
      percentageTime: 0,
      percentageProbability: 0,
    }),
    [project],
  );
  const [addingMemberIsDisabled, setAddingMemberIsDisabled] = useState(true);
  const [deleteCheckBox, setDeleteCheckBox] = useState<boolean>(false);
  const [deleteSelection, setDeleteSelection] = useState<number[]>([]);

  useEffect(() => {
    projectMemberList.find((projectMember) => projectMember.employeeEmail === '')
      ? setAddingMemberIsDisabled(true)
      : setAddingMemberIsDisabled(false);
    // Falls es keine Member gibt, soll trotzdem eine leere Zeile übergeben werden
    if (projectMemberList.length === 0) {
      setProjectMemberList([initState]);
    }
  }, [projectMemberList, setAddingMemberIsDisabled, initState, setProjectMemberList]);

  useEffect(() => {
    if (tableContainerRef.current) {
      // Setze den ScrollTop auf die maximale Scrollhöhe, um unten zu starten
      tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
    }
  }, [projectMemberList.length]);

  useEffect(() => {
    if (projectMemberList.length !== 0) setDeleteCheckBox(deleteSelection.length === projectMemberList.length);
  }, [deleteSelection, projectMemberList]);

  const handleUpdateProjectMemberList = (updatedList: ProjectMember[]) => {
    setProjectMemberList(updatedList);
  };

  const onSelectAllClick = () => {
    setDeleteCheckBox((isChecked) => {
      if (isChecked) {
        setDeleteSelection([]);
      } else {
        setDeleteSelection([...projectMemberList.map((_, index) => index)]);
      }
      return !isChecked;
    });
  };

  const handleAddNextEmployee = () => {
    setProjectMemberList([...projectMemberList, initState]);
    setAddingMemberIsDisabled(true);
  };

  const handleDelete = () => {
    const updatedList = projectMemberList.filter((_, index) => !deleteSelection.includes(index));
    handleUpdateProjectMemberList(updatedList);
    setDeleteSelection([]);
    setDeleteCheckBox(false);
  };

  const tableContainerRef = useRef(null);

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.notice}>
        Hinweis: Für alle Mitarbeitenden muss die benötigte Arbeitszeit, der Auftragsstatus und der Zeitraum, in der die
        Person an dem Projekt arbeitet angegeben werden. Der Auftragsstatus ist die Wahrscheinlichkeit, dass die Person
        für das Projekt beauftragt wird. Die Angaben können auch geschätzt werden.
      </Typography>

      <TableContainer ref={tableContainerRef} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.nameCell} variant="head">
                <Typography variant="h5">Mitarbeiter:in</Typography>
              </TableCell>
              <TableCell variant="head" className={classes.worktimeCell}>
                <Typography variant="h5">Nötige Arbeitszeit</Typography>
              </TableCell>
              <TableCell variant="head" className={classes.probabilityCell}>
                <Typography variant="h5">Auftragsstatus</Typography>
              </TableCell>
              <TableCell className={classes.dateCell} variant="head">
                <Typography variant="h5">Im Projekt von</Typography>
              </TableCell>
              <TableCell className={classes.dateCell} variant="head">
                <Typography variant="h5">Im Projekt bis</Typography>
              </TableCell>
              <TableCell variant="head" align="right" className={classes.deleteCell}>
                <div className={classes.deleteCellContent}>
                  <div>
                    <FontAwesomeIcon icon={faUserMinus} />
                  </div>
                  <MUIEXXCheckBox
                    onChange={onSelectAllClick}
                    onClick={onSelectAllClick}
                    checked={deleteCheckBox}
                    margin={'0px 0px 0px 8px !important'}
                    disabled={projectMemberList.every(
                      (projectMember) =>
                        !projectMember.employeeEmail &&
                        !projectMember.percentageProbability &&
                        !projectMember.percentageTime,
                    )}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {projectMemberList.map((projectMember, rowIndex) => (
              <ProjectMemberTableRow
                key={`project-member-${projectMember.employeeEmail}-${rowIndex}`}
                employees={employees}
                projectMemberList={projectMemberList}
                setProjectMemberList={handleUpdateProjectMemberList}
                givenProjectMember={projectMember}
                checkBoxIsSelected={deleteSelection.includes(rowIndex)}
                onClickCheckBox={() => {
                  if (deleteSelection.includes(rowIndex)) {
                    setDeleteSelection((previousSelection: number[]) =>
                      previousSelection.filter((deleteIndex) => rowIndex !== deleteIndex),
                    );
                  } else {
                    setDeleteSelection((previousSelection: number[]) => [...previousSelection, rowIndex]);
                  }
                }}
                project={project}
                employee={employees.find((employee) => projectMember.employeeEmail === employee.email)}
                rowIndex={rowIndex}
                setTableRowsCompleted={setTableRowsCompleted}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.addAndDeleteSection}>
        <div>
          {!addingMemberIsDisabled && (
            <IconButton onClick={handleAddNextEmployee}>
              <FontAwesomeIcon className={classes.addIcon} icon={faCirclePlus} />
            </IconButton>
          )}
        </div>
        <div>{deleteSelection.length > 0 && <Link onClick={handleDelete}>Ausgewählte entfernen</Link>}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectMemberTable);
