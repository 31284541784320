import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Action, AnyAction, Store } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThunkAction } from 'redux-thunk';

import Frame from './Frame';
import { configureMsal } from './auth-config.js';
import Catch from './components/catch/Catch';
import './index.css';
import { ApplicationStore } from './redux/reducers';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';

const appClientId = process.env.REACT_APP_CLIENT_ID;
const appTenantId = process.env.REACT_APP_CLIENT_TENANT_ID;

const msalConfig = configureMsal(appClientId, appTenantId);
const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

//get initialize msalInstance
msalInstance.initialize();

if (!msalInstance.getActiveAccount()) {
  // Account selection
  const accounts: AccountInfo[] = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}
//set the account
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult: AuthenticationResult = event.payload as AuthenticationResult;
    const account: AccountInfo = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});
//enable account storage event
msalInstance.enableAccountStorageEvents();
export const { store, persistor } = configureStore(); /* provide initial state if any */

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, Store<ApplicationStore, AnyAction>, null, Action>;

ReactDOM.render(
  <Catch>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <MsalProvider instance={msalInstance}>
            <Frame />
          </MsalProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </Catch>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
