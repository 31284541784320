import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeStatus = (): { isResizing: boolean } => {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout | null = null;

    const resizeObserver = new ResizeObserver(() => {
      setIsResizing(true);

      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(() => {
        setIsResizing(false);
      }, 300);
    });

    // Füge document.body hinzu zur Überwachung
    resizeObserver.observe(document.body);

    return () => {
      // Stope den ResizeObserver und  Timeout, wenn die Komponente unmountet wird
      resizeObserver.disconnect();
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isResizing };
};

export default useResizeStatus;
