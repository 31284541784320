import React from 'react';
import { CustomMarker } from 'react-calendar-timeline';
import { connect } from 'react-redux';

import { ApplicationStore } from '../../redux/reducers';
import colors from '../../utils/colors';
import { RedBox, RedBoxArray } from '../../utils/timelineUtils';

interface RedBoxProps {
  allRedBoxes: RedBoxArray;
  currentEmployeeFilter: string;
  redBoxShowed: boolean;
}

const filterRedBoxes = (allRedBoxes: RedBoxArray, filter: string): RedBoxArray => {
  if (filter === 'alle') {
    return allRedBoxes;
  }
  const filteredRedBoxes = allRedBoxes.filter((redBoxesOfEmployee) => {
    if (redBoxesOfEmployee[0]) {
      const teams = redBoxesOfEmployee[0].employee.teams;
      return teams.includes(filter);
    }
    return false;
  });
  return filteredRedBoxes;
};

const createCustomStyle = (styles: React.CSSProperties, redBox: RedBox) => {
  return {
    ...styles,
    width: redBox.width,
    top: redBox.top,
    height: redBox.height,
    backgroundColor: colors.redTimelineColor,
    zIndex: 79,
  };
};

const RedBoxes = (props: RedBoxProps): JSX.Element => {
  const { allRedBoxes, currentEmployeeFilter, redBoxShowed } = props;
  const filteredRedBoxes = filterRedBoxes(allRedBoxes, currentEmployeeFilter);

  return redBoxShowed ? (
    <>
      {filteredRedBoxes.map((redBoxesOfEmployee, outerIndex) =>
        redBoxesOfEmployee.map((redBox: RedBox, innerIndex: number) => (
          <CustomMarker
            key={`${outerIndex}-${innerIndex}-${redBox.employee.id}`}
            date={redBox.start_time.toDate().getTime()}
          >
            {({ styles }) => {
              const customStyle = createCustomStyle(styles, redBox);
              return <div style={customStyle} />;
            }}
          </CustomMarker>
        )),
      )}
    </>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  currentEmployeeFilter: string;
  redBoxShowed: boolean;
} => {
  return {
    currentEmployeeFilter: store.employee.shownSpecificTeam,
    redBoxShowed: store.workload.redBoxShowed,
  };
};

export default connect(mapStateToProps)(RedBoxes);
