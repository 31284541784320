import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

interface StickyComponentProps {
  position: 'top' | 'bottom' | 'left' | 'right';
  stuckClasses: string;
  unstuckClasses: string;
  stuckStyles?: CSSProperties;
  unstuckStyles?: CSSProperties;
  children: ReactNode;
  positionValue?: number;
}

/** Creates a Sticky Component that adopts different styling depending on whether it has a sticky position or not.
 *
 * @param {('top' | 'bottom' | 'left' | 'right')} position - Determines the position of the sticky element relative to its parent container. Can be 'top', 'bottom', 'left', or 'right'.
 * @param {string} stuckClasses - The CSS classes to apply when the element is in a 'stuck' state.
 * @param {string} unstuckClasses - The CSS classes to apply when the element is not in a 'stuck' state.
 * @param {CSSProperties} stuckStyles - Optional. The inline styles to apply when the element is in a 'stuck' state.
 * @param {CSSProperties} unstuckStyles - Optional. The inline styles to apply when the element is not in a 'stuck' state.
 * @param {ReactNode} children - The child elements to render inside the sticky container.
 * @returns Returns a div element with the appropriate styles and classes applied.
 */

export const StickyComponent: React.FC<StickyComponentProps> = (props) => {
  const { position, positionValue = 0, stuckClasses, unstuckClasses, children, stuckStyles, unstuckStyles } = props;

  const [stuck, setStuck] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const classes = stuck ? stuckClasses : unstuckClasses;
  const styles = stuck ? stuckStyles : unstuckStyles;

  const inlineStyles: CSSProperties = {
    position: 'sticky',
    [position]: positionValue,
    ...styles,
  };

  useEffect(() => {
    const cachedRef = ref.current;

    const handleScroll = () => {
      if (cachedRef) {
        const rect = cachedRef.getBoundingClientRect();
        setStuck(rect[position] <= positionValue);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div style={inlineStyles} className={classes} ref={ref}>
      {children}
    </div>
  );
};
