import { faClose, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade, IconButton, Snackbar, Theme, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';

import { removeToast } from '../../redux/actions/ToastActions';
import { Toast } from '../../redux/reducers/toastReducer';
import colors from '../../utils/colors';

const useStyles = makeStyles<Theme>((theme) => ({
  bar: {
    maxWidth: '368px',
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    gap: '16px',
  },
  closeIcon: {
    color: colors.grey600,
    fontSize: 24,
  },
  alert: {
    display: 'flex',
    gap: '16px',
  },
  alertIcon: {
    margin: 'auto auto auto 0',
  },
  center: {
    margin: 'auto 0 auto auto',
  },
}));

interface MUIEXXSnackbarProps {
  open?: boolean;
  toast: Toast;
}

const MUIEXXSnackbar = (props: MUIEXXSnackbarProps) => {
  const dispatch = useDispatch();

  const { toast, open = true } = props;
  const classes = useStyles();

  const iconMap = {
    info: <FontAwesomeIcon icon={faInfoCircle} className={classes.alertIcon} color="inherit" fontSize="inherit" />,
    success: <FontAwesomeIcon icon={faInfoCircle} className={classes.alertIcon} fontSize="inherit" />,
    warning: <FontAwesomeIcon icon={faInfoCircle} className={classes.alertIcon} fontSize="inherit" />,
    error: <FontAwesomeIcon icon={faInfoCircle} className={classes.alertIcon} fontSize="inherit" />,
  };

  return (
    <Snackbar
      TransitionComponent={Fade}
      className={classes.bar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={() => {
        dispatch(removeToast(toast.id));
      }}
      autoHideDuration={toast.severity === 'success' ? 4000 : null}
    >
      <Alert
        className={classes.alert}
        iconMapping={iconMap}
        action={
          <div className={classes.center}>
            <IconButton
              onClick={() => {
                dispatch(removeToast(toast.id));
              }}
            >
              <FontAwesomeIcon icon={faClose} color={'inherit'} className={classes.closeIcon} />
            </IconButton>
          </div>
        }
        severity={toast.severity}
        color={toast.severity}
        variant={'standard'}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export default MUIEXXSnackbar;
