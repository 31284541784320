import { InputSelectOption } from '../models/shared/InputSelectOption';
import { ProjectShort } from '../models/shared/ProjectShort';
import { Project } from '../redux/actions/ProjectActions';

export const deleteProjectById = (name: string, projects: Project[]): Project[] => {
  const tmpProjects: Project[] = JSON.parse(JSON.stringify(projects));
  if (name && tmpProjects && tmpProjects.length > 0) {
    for (let index = 0; index < tmpProjects.length; index++) {
      const project = tmpProjects[index];
      if (project && project.name && project.name === name) {
        tmpProjects.splice(index, 1);
      }
    }
  }
  return tmpProjects;
};

export const sortProjects = (projects: Project[]): Project[] => {
  if (!projects) {
    return [];
  }
  return projects.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
};

export const getProjectByName = (projectName: string, projects: Project[]): Project => {
  if (projectName && projects && projects.length > 0) {
    for (const project of projects) {
      if (project.name === projectName) {
        return project;
      }
    }
  }
  return null;
};

export const mapProjectToShortProject = (project: Project): ProjectShort => {
  return {
    name: project.name,
    id: project.name,
    customer: project.customer,
  };
};

export const mapProjectsToShortProjects = (projects: Project[]): ProjectShort[] => {
  return projects.map((project) => mapProjectToShortProject(project));
};

export const createSuggestionListOfProjectNames = (
  allProjects: ProjectShort[],
  employeeProjectList: string[],
  inputValue: string | ProjectShort,
): InputSelectOption[] => {
  const options = allProjects
    .filter((item) => !employeeProjectList.includes(item.name))
    .map<InputSelectOption>((project: ProjectShort) =>
      createInputSelectionOption(project.id, `${project.name} (${project.customer})`, project.customer),
    );

  if (inputValue) {
    options.push(
      createInputSelectionOption(
        'project.id',
        `„${inputValue}“ ${inputValue && 'als neues Projekt anlegen'} `,
        'customerKeyForPopOver',
      ),
    );
  }

  return options;
};

export const createListOfProjectNamesWithCustomer = (
  allProjects: ProjectShort[],
  employeeProjectList: string[],
): InputSelectOption[] => {
  return allProjects
    .filter((item) => employeeProjectList.includes(item.name))
    .map<InputSelectOption>((project: ProjectShort) =>
      createInputSelectionOption(project.id, project.name, project.customer),
    );
};

const createInputSelectionOption = (value: string, label: string, customer: string): InputSelectOption => {
  return {
    value: value,
    label: label,
    customer: customer,
  };
};

export const getProjectsFromEmployee = (projects: Project[], employeeEmail: string) => {
  const filteredProjects = projects.filter(
    (project) =>
      project.projectMembers.findIndex((projectMember) => projectMember.employeeEmail === employeeEmail) > -1,
  );
  return filteredProjects;
};
