import dayjs, { Dayjs } from 'dayjs';
import { TimelineGroup, TimelineItem } from 'react-calendar-timeline';

import { BillableFilterValues } from '../components/Filter/BillableFilter';
import { CustomGroupParams } from '../components/Timeline/getTimelineGroups';
import { CustomItemParams } from '../components/Timeline/getTimelineItems';
import { Project } from '../redux/actions/ProjectActions';
import { Employee } from '../redux/reducers/employee/employee.type';
import colors from './colors';

export interface RedBox {
  employee: Employee;
  start_time: Dayjs;
  height: string;
  top: string;
  width: number;
}

interface MonthData {
  percentageAll: number;
  percentageBillable: number;
  percentageNotBillable: number;
}
export interface MonthlyData {
  [month: string]: MonthData;
}

export type RedBoxArray = Array<Array<RedBox>>;

interface ItemStyleOptions {
  itemBackgroundColor: string;
  fontColorText: string;
  border: string;
  hoveredItemBackgroundColor: string;
  hoveredTextColor: string;
}

export const URLAUB = 'Urlaub';

/** Checks if a project is billable based on the current filter state.
 * @param billableFilterState The current filter state.
 * @param project The project to be checked.
 * @returns A boolean value indicating whether the project is billable or not.
 */
export const isBillable = (billableFilterState: BillableFilterValues, project: Project): boolean => {
  switch (billableFilterState) {
    case BillableFilterValues.all:
      return true;
    case BillableFilterValues.billable:
      return project.billable === true;
    case BillableFilterValues.notBillable:
      return project.billable === false;
    default:
      return true;
  }
};
/** Filters out empty teams from an array of TimelineGroups. If a teams is empty, the headline will be removed.
 * @param groups An array of TimelineGroups.
 * @returns An array of filtered TimelineGroups.
 */
export const filterEmptyTeams = (groups: TimelineGroup<CustomGroupParams>[]) => {
  const filteredGroups = groups.filter((group, index) => {
    // Überprüfen, ob die aktuelle Gruppe eine empId hat
    if (group.empId) {
      return true;
    }

    // Überprüfen, ob die nächste Gruppe eine empId hat
    const nextGroup = groups[index + 1];
    if (nextGroup && nextGroup.empId) {
      return true;
    }

    // Entfernen der aktuellen Gruppe, wenn weder die aktuelle noch die nächste Gruppe eine empId hat
    return false;
  });

  if (filteredGroups.length === 0) {
    filteredGroups.push({ id: 'NO EMPLOYEES', title: 'Keine Mitarbeitenden gefunden.' });
  }
  return filteredGroups;
};

/** Retrieves TimelineItems associated with a specific employee and their projects.
 * @param employee  The employee object.
 * @param items An array of TimelineItems.
 * @param team The name of the team.
 * @returns An array of TimelineItems associated with the employee's projects.
 */
export const getItemsByEmployee = (
  employee: Employee,
  items: TimelineItem<CustomItemParams>[],
  team: string,
): TimelineItem<CustomItemParams>[] => {
  const itemsFromEmployee: TimelineItem<CustomItemParams>[] = [];
  if (employee.projects) {
    employee.projects.forEach((project) => {
      items.forEach((item) => {
        const employeeIDfromItem = item.id.split('_')[0];
        if (item.name === project && employeeIDfromItem === employee.id) {
          itemsFromEmployee.push(item);
        }
      });
    });
    const specificItems = groupAndFindSpecificItems(itemsFromEmployee, team);

    return specificItems;
  }
  return [];
};

/** Generates monthly workload data for a given period.
 * @param diffMonth The number of months for which the data needs to be generated.
 * @param startMonth The starting month for the data generation.
 * @param items An array of timeline items.
 * @returns An object containing the workload data for each month in the specified period.
 */
export const getMonthlyWorkloadData = (
  diffMonth: number,
  startMonth: Dayjs,
  items: TimelineItem<CustomItemParams>[],
): MonthlyData => {
  let months = {};

  for (let i = 0; i < diffMonth; i++) {
    const month = startMonth.add(i, 'month');
    const monthKey = month.format('MM/YY');

    const percentageAll = getWorkloadPercentageForMonth(items, month, 'all');
    const percentageBillable = getWorkloadPercentageForMonth(items, month, 'billable');
    const percentageNotBillable = getWorkloadPercentageForMonth(items, month, 'notBillable');

    months = { ...months, [monthKey]: { percentageAll, percentageBillable, percentageNotBillable } };
  }

  return months;
};

/** This function retrieves the (HTML)-Element representing an employee box from a collection of such elements.
 * @param allGroupEmployeeBoxes An array of HTML elements representing employee boxes.
 * @param employeeID The ID of the employee whose box needs to be retrieved.
 * @returns The HTML element representing the employee box for the specified employee.
 */
export const getEmployeeBox = (allGroupEmployeeBoxes: Element[], employeeID: string): HTMLElement => {
  let employeeBox = null;
  allGroupEmployeeBoxes.forEach((employee_Box: Element) => {
    if (employee_Box.children[0].id.toString() === employeeID) {
      employeeBox = employee_Box;
    }
  });
  return employeeBox;
};

/** This function calculates the width for a given month in a calendar view.
 * @param month The given month.
 * @param reactScrollElement The scrollable element.
 * @param visibleMonthCount The count of visible months.
 * @param format The labelformat from the Timeline
 * @returns The calculated width for the given month.
 */
export const getWidthforMonth = (
  month: Dayjs,
  reactScrollElement: Element,
  visibleMonthCount: number,
  format: string,
) => {
  const rctDateHeader = document.getElementsByClassName('rct-dateHeader');
  return Array.from(rctDateHeader).reduce(
    (acc, monthHeader) => {
      const realMonth = month.format(format);
      const headerMonth = monthHeader.children[0].innerHTML;

      return realMonth === headerMonth ? monthHeader.getBoundingClientRect().width : acc;
    },
    reactScrollElement ? reactScrollElement.getBoundingClientRect().width / visibleMonthCount : 0,
  );
};

/** Retrieves the longest team name from a list of team names associated with an employee.
 * @param teamsOfEmployee An array of team names associated with an employee.
 * @returns The longest team name.
 */
export const getSpecificTeamNameOfEmployee = (teamsOfEmployee: string[]): string => {
  return teamsOfEmployee.reduce((previousTeam, currentTeam) => {
    if (currentTeam.length >= previousTeam.length) {
      return currentTeam;
    } else {
      return previousTeam;
    }
  }, '');
};

interface GroupedItems {
  [key: string]: TimelineItem<CustomItemParams>[];
}

const groupAndFindSpecificItems = (
  items: TimelineItem<CustomItemParams>[],
  team: string,
): TimelineItem<CustomItemParams>[] => {
  // Gruppiere Items nach Projektnamen
  const groupedItems = items.reduce<GroupedItems>((acc, currentItem) => {
    const key = currentItem.name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(currentItem);
    return acc;
  }, {});

  // Finde das spezifischste Item für jede Gruppe
  const specificItems = Object.values(groupedItems).map((itemGroup) => {
    const group = itemGroup as TimelineItem<CustomItemParams>[];
    if (team === 'alle') {
      return getSpecificItem(group);
    } else {
      return group.filter((item) => item.teamName === team)[0];
    }
  });

  return specificItems;
};

const getSpecificItem = (items: TimelineItem<CustomItemParams>[]): TimelineItem<CustomItemParams> => {
  return items.reduce((prevItem, currentItem) => {
    if (currentItem.id.length >= prevItem.id.length) {
      return currentItem;
    } else {
      return prevItem;
    }
  }, items[0]);
};

const getWorkloadPercentageForMonth = (
  items: TimelineItem<CustomItemParams>[],
  month: Dayjs,
  filterBillable: string,
): number => {
  let filteredItems = items.filter((item) => typeof item !== 'undefined');

  switch (filterBillable) {
    case 'all': {
      break;
    }
    case 'billable': {
      filteredItems = filteredItems.filter((item) => item.billable);
      break;
    }
    case 'notBillable': {
      filteredItems = filteredItems.filter((item) => {
        return item.billable !== true;
      });

      break;
    }
    default: {
      break;
    }
  }

  let percentWorkingTime = 0;
  filteredItems.forEach((item) => {
    if (projectIsInMonth(month, item)) {
      percentWorkingTime += item.percentageTime;
    }
  });
  return percentWorkingTime;
};

const projectIsInMonth = (month: Dayjs, item: TimelineItem<CustomItemParams>): boolean => {
  // month is a number between 0-11 + visibleMonthCount
  const start_month = dayjs(item.start_time);
  const end_month = dayjs(item.end_time);

  return (
    (dayjs(month).isAfter(start_month, 'month') || dayjs(month).isSame(start_month, 'month')) &&
    (dayjs(month).isBefore(end_month, 'month') || dayjs(month).isSame(end_month, 'month'))
  );
};

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;

  const textMetrics = context.measureText(text);

  return textMetrics.width;
};

export const getItemColor = (
  billable: boolean,
  percentageProbability: number,
  projectName: string,
): ItemStyleOptions => {
  if (projectName === URLAUB) {
    // vacation
    return {
      itemBackgroundColor: colors.purple200,
      fontColorText: colors.mainColor,
      border: colors.withBorder,
      hoveredItemBackgroundColor: colors.purple200,
      hoveredTextColor: colors.grey600,
    };
  }
  if (!billable) {
    // intern projects
    return {
      itemBackgroundColor: colors.white,
      fontColorText: colors.mainColor,
      border: colors.withBorder,
      hoveredItemBackgroundColor: colors.white,
      hoveredTextColor: colors.grey600,
    };
  }
  if ((percentageProbability === 25 || percentageProbability === 50) && billable) {
    // extern projects, 25% or 50%
    return {
      itemBackgroundColor: colors.grey200,
      fontColorText: colors.mainColor,
      border: colors.noBorder,
      hoveredItemBackgroundColor: colors.grey100,
      hoveredTextColor: colors.mainColor,
    };
  }
  if (percentageProbability === 75 && billable) {
    // extern project, 75
    return {
      itemBackgroundColor: colors.grey600,
      fontColorText: colors.white,
      border: colors.noBorder,
      hoveredItemBackgroundColor: colors.grey400,
      hoveredTextColor: colors.white,
    };
  }
  return {
    // extern projects and 100%
    itemBackgroundColor: colors.mainColor,
    fontColorText: colors.white,
    border: colors.noBorder,
    hoveredItemBackgroundColor: colors.grey800,
    hoveredTextColor: colors.white,
  };
};
