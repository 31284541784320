import { Dayjs } from 'dayjs';
import { TimelineGroup, TimelineItem } from 'react-calendar-timeline';

import { Employee } from '../../redux/reducers/employee/employee.type';
import {
  MonthlyData,
  filterEmptyTeams,
  getItemsByEmployee,
  getMonthlyWorkloadData,
  getSpecificTeamNameOfEmployee,
} from '../../utils/timelineUtils';
import { EmployeeWorkloadFilterValues } from '../Filter/EmployeeWorkloadFilter';
import { WorkloadProjectFilterValues } from '../Filter/WorkloadProjectFilter';
import { CustomItemParams } from './getTimelineItems';

export interface CustomGroupParams {
  empId?: string;
  employee?: Employee;
  items?: TimelineItem<CustomItemParams>[];
  months?: MonthlyData;
}

export interface GetTimelineGroupsParams {
  currentEmployeeFilter: string;
  employees: Employee[];
  displayedEmployees: Employee[];
  teamData: string[];
  onlyEmployee: boolean;
  employeeSearchValue?: string;
  items: TimelineItem<CustomItemParams>[];
  visibleMonthCount: number;
  startDay: Dayjs;
  workloadLimitFilter?: number;
  workloadProjectTypeFilter?: WorkloadProjectFilterValues;
}

const monthIsInWorkloadLimit = (workloadLimitFilter: number, monthlyWorkload: number): boolean => {
  if (
    workloadLimitFilter === EmployeeWorkloadFilterValues['>75%'] ||
    workloadLimitFilter === EmployeeWorkloadFilterValues['>100%']
  ) {
    if (monthlyWorkload >= workloadLimitFilter) {
      return true;
    }
  } else {
    if (monthlyWorkload < workloadLimitFilter) {
      return true;
    }
  }
  return false;
};

export const getTimelineGroups = ({
  currentEmployeeFilter,
  employees,
  displayedEmployees,
  teamData,
  onlyEmployee,
  items,
  visibleMonthCount,
  startDay,
  workloadLimitFilter,
  workloadProjectTypeFilter,
  employeeSearchValue = '',
}: GetTimelineGroupsParams) => {
  let groupList: TimelineGroup<CustomGroupParams>[] = [];

  const createRowElement = (employee: Employee, team: string) => {
    if (
      employee.teams.includes(team) &&
      (currentEmployeeFilter === 'alle' ? getSpecificTeamNameOfEmployee(employee.teams) === team : true)
    ) {
      const itemsOfEmployee = getItemsByEmployee(employee, items, team);

      groupList.push({
        id: `${employee.id}${team}`,
        title: `${employee.forename} ${employee.surname}`,
        stackItems: true,
        empId: employee.id,
        employee: employee,
        items: itemsOfEmployee,
        months: getMonthlyWorkloadData(visibleMonthCount, startDay, itemsOfEmployee),
      });
    }
  };

  switch (currentEmployeeFilter) {
    case 'alle':
      teamData
        .filter((team) => team !== 'alle')
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .forEach((team) => {
          groupList.push({ id: team, title: team });
          employees.forEach((employee) => createRowElement(employee, team));
        });
      break;

    default:
      if (onlyEmployee) {
        displayedEmployees.forEach((employee) => createRowElement(employee, currentEmployeeFilter));
      } else {
        groupList.push({ id: currentEmployeeFilter, title: currentEmployeeFilter });
        displayedEmployees.forEach((employee) => createRowElement(employee, currentEmployeeFilter));
      }
  }

  if (workloadLimitFilter) {
    groupList = groupList.filter((group) => {
      if (!group.empId) {
        return true;
      } else {
        const months = group.months;
        const monthKeys = Object.keys(months);
        const currentMonthInfo = months[monthKeys[0]];
        let monthInWorkload: boolean;

        switch (workloadProjectTypeFilter) {
          case WorkloadProjectFilterValues.all:
            monthInWorkload = monthIsInWorkloadLimit(workloadLimitFilter, currentMonthInfo.percentageAll);
            break;
          case WorkloadProjectFilterValues.billable:
            monthInWorkload = monthIsInWorkloadLimit(workloadLimitFilter, currentMonthInfo.percentageBillable);
            break;
          case WorkloadProjectFilterValues.notBillable:
            monthInWorkload = monthIsInWorkloadLimit(workloadLimitFilter, currentMonthInfo.percentageNotBillable);
            break;
        }

        // wenn der aktuelle Monat die Auslastungsbedingung erfüllt
        return monthInWorkload;
      }
    });
  }

  // filter from employee search field
  if (employeeSearchValue !== '') {
    groupList = groupList.filter((group) => {
      if (!group.empId) {
        return true;
      }

      const nameIsFound = `${group.employee.forename} ${group.employee.surname}`
        .toLowerCase()
        .includes(employeeSearchValue.toLowerCase());

      let isDisplayed = false;

      if (nameIsFound) {
        isDisplayed = true;
      } else {
        group.items.forEach((item: TimelineItem<CustomItemParams>) => {
          if (
            item.name.toLowerCase().includes(employeeSearchValue.toLowerCase()) ||
            item.customer.toLowerCase().includes(employeeSearchValue.toLowerCase())
          ) {
            isDisplayed = true;
          }
        });
      }
      return isDisplayed;
    });
  }

  // filter empty groups (group headings)
  groupList = filterEmptyTeams(groupList);

  return groupList;
};
