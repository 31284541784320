import { getData } from '../../utils/axios-calls';

const PATH_TEAM_DATA = '/team';
const PATH_EMPLOYEE_DATA_FOLDER = '/employee';
const PATH_EMPLOYEE_DATA = PATH_EMPLOYEE_DATA_FOLDER + '/byTeam/';
const PATH_PROJECT_DATA = '/project';
const PATH_TEAM_DATA_NAMES = '/team/names';

const ERR_NO_DATA = (err: Error, path: string, data: any): string =>
  `No data found at ${path} in ${data}. check error: ${err}`;

export const getTeamData = (): Promise<any> => {
  const response = getData(PATH_TEAM_DATA_NAMES);
  return response
    .then((res: any) => {
      return res.data;
    })
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, PATH_TEAM_DATA, response));
      return undefined;
    });
};

export const getEmployeeData = (teamName: string): Promise<any> => {
  const response = getData(PATH_EMPLOYEE_DATA + teamName);
  return response
    .then((res: any) => {
      return res.data;
    })
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, teamName, response));
      return undefined;
    });
};

export const getProjectData = (): Promise<any> => {
  const response = getData(PATH_PROJECT_DATA);
  return response
    .then((res: any) => {
      return res.data;
    })
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, PATH_PROJECT_DATA, response));
      return undefined;
    });
};
