import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Link, Step, Stepper, Theme, Typography, createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { DateValidationError } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  Project,
  ProjectMember,
  addNewProject,
  deleteProjectConfirmedAction,
  sortProjectMemberByForenames,
  updateProject,
} from '../../../redux/actions/ProjectActions';
import { addToast } from '../../../redux/actions/ToastActions';
import { ApplicationStore } from '../../../redux/reducers';
import { Employee } from '../../../redux/reducers/employee/employee.type';
import { Toast } from '../../../redux/reducers/toastReducer';
import colors from '../../../utils/colors';
import { getProjectByName } from '../../../utils/projectUtils';
import { useDebounce } from '../../../utils/useDebounce';
import MUIEXXDatePicker from '../../BaseComponentsExxetaStyle/MUIEXXDatePicker';
import MUIEXXField from '../../BaseComponentsExxetaStyle/MUIEXXField';
import MUIEXXRadioGroup from '../../BaseComponentsExxetaStyle/MUIEXXRadioGroup';
import ProjectMemberTable from '../ProjectMemberTable';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const styles = (theme: Theme): StyleRules =>
  createStyles({
    dialogGridLayout: {
      display: 'grid',
      gridTemplateColumns: '45fr 55fr',
      gridTemplateRows: 'auto',
      gap: '24px',
      marginBottom: '48px',
    },

    errorText: {
      marginRight: 'auto',
      color: colors.red500,
    },

    root: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'hidden',
      minHeight: '456px',
    },

    titleRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    wizzard: {
      padding: '24px 24px 24px 0px',
    },
    stepLabel: {
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },

    successText: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1, 2, 1, 0),
    },
    formRow: {
      display: 'inline-flex',
      flexDirection: 'row',
      gap: '16px',
      justifyContent: 'space-between',
    },

    formColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    dialogContentRoot: {
      overflow: 'hidden',
      height: '403px',
    },

    stepCircle: {
      width: '24px',
      height: '24px',
      border: `1px solid ${colors.mainColor}`,
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    activeStepper: {
      backgroundColor: colors.turqoise500,
    },

    inactiveFirstStepper: {
      backgroundColor: colors.turqoise200,
    },

    noColor: {
      backgroundColor: 'transparent',
    },

    dialogHint: {
      marginBottom: '24px',
    },

    deleteBtn: {
      justifyContent: 'space-between',
    },

    faSolidCirclePlus: { fontFamily: 'fa-solid', fontSize: '16px', content: '\f055' },

    radioGroup: {
      '& .MuiFormLabel-root': {
        marginBottom: '10px',
      },
      '& .MuiFormGroup-root': {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
      },
    },
  });

export enum DialogNewProjectMode {
  EDIT = 'edit',
  CREATE_NEW = 'create_new',
  FAST_NEW = 'fast_new',
}

export interface DialogMode {
  mode: DialogNewProjectMode.FAST_NEW | DialogNewProjectMode.CREATE_NEW | DialogNewProjectMode.EDIT;
  projectName?: string;
  employee?: Employee;
  startOnSecondStep?: boolean;
}

export interface DialogNewProjectProps extends WithStyles<typeof styles> {
  handleClose: Function;
  employees: Employee[];
  showDialog: boolean;
  dialogNewProjectMode: DialogMode;
  projects: Project[];
}

const DialogNewProject = (props: DialogNewProjectProps): JSX.Element => {
  const { classes, projects, employees, dialogNewProjectMode, showDialog, handleClose } = props;
  const { startOnSecondStep = false } = dialogNewProjectMode;

  const employee = dialogNewProjectMode?.employee;

  const dispatch = useDispatch();
  const initStartDate = dayjs().format('DD.MM.YYYY');
  const initEndDate = dayjs().add(1, 'month').endOf('month').format('DD.MM.YYYY');
  const initProjectState: Project = {
    customer: '',
    description: '',
    projectMembers: [],
    name: '',
    startDate: initStartDate,
    endDate: initEndDate,
    billable: true,
    archived: false,
  };

  const [projectState, setProjectState] = useState<Project>(initProjectState);
  const [projectMemberList, setProjectMemberList] = useState([]);
  const [isFirstScreen, setIsFirstScreen] = useState(!startOnSecondStep);

  const [stateDialogNewProject, setStateDialogNewProject] = useState({
    error: false,
    loading: false,
    success: false,
  });

  const [tableRowsCompleted, setTableRowsCompleted] = useState<boolean[]>([]);

  const [showRowError, setShowRowError] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [showToast, setShowToast] = useState<Toast>();

  const handleNewProjectResponse = (res: number): void => {
    let errorText = '';
    let success = false;
    let error = false;
    switch (res) {
      case 200: {
        success = true;
        break;
      }
      case 201: {
        success = true;
        break;
      }
      case 401: {
        errorText = 'Ups, du bist nicht mehr angemeldet.';
        error = true;
        break;
      }
      case 404: {
        errorText = 'Es gab ein Verbindungsproblem, versuche es bitte noch einmal.';
        error = true;
        break;
      }
      default: {
        break;
      }
    }

    if (success) {
      setShowToast({
        message: (
          <div>
            Das Projekt wurde erfolgreich {DialogNewProjectMode.EDIT ? 'bearbeitet' : 'erstellt'}. Du kannst es auf der
            Auslastungsseite einsehen.
            <NavLink to={'/auslastung'}>
              <Typography variant={'h5'} style={{ backgroundColor: 'transparent' }}>
                Zur Auslastungsseite
              </Typography>
            </NavLink>
          </div>
        ),
        severity: 'success',
      });
      onClose();
    } else if (error) {
      setShowToast({
        message: errorText,
        severity: 'error',
      });
    }

    setStateDialogNewProject({
      error: error,
      loading: false,
      success: success,
    });
  };

  const submitUpdatedProject = (changesNewProject: Project): Promise<void> =>
    new Promise((resolve) => {
      if (
        dialogNewProjectMode.mode === DialogNewProjectMode.CREATE_NEW ||
        dialogNewProjectMode.mode === DialogNewProjectMode.FAST_NEW
      ) {
        dispatch(addNewProject(changesNewProject, handleNewProjectResponse));
      } else if (dialogNewProjectMode.mode === DialogNewProjectMode.EDIT) {
        dispatch(updateProject(dialogNewProjectMode.projectName, changesNewProject, handleNewProjectResponse));
      }
      resolve();
    });

  const onSave = () => {
    let projectMemberListAdjusted = projectMemberList.map((projectMember) => {
      projectMember.projectName = projectState.name.trim();
      return projectMember;
    });

    if (projectMemberList.length === 1 && tableRowsCompleted.length === 0) {
      projectMemberListAdjusted = [];
    }

    // check if row is completed
    if (!tableRowsCompleted.every((value) => value)) {
      setShowRowError(true);
      return; // Vorgang abbrechen
    }

    setProjectState((prev) => {
      const updatedPayload = {
        ...prev,
        name: prev.name.trim(),
        description: prev.description.trim(),
        customer: prev.customer.trim(),
        projectMembers: projectMemberListAdjusted,
      };
      submitUpdatedProject(updatedPayload);

      return updatedPayload;
    });
  };

  const onDeleteProject = () => {
    dispatch(deleteProjectConfirmedAction(dialogNewProjectMode.projectName));
    onClose();
  };

  useEffect(() => {
    if (showToast !== undefined) {
      dispatch(addToast(showToast.message, showToast.severity));
    }
  }, [dispatch, showToast]);

  useEffect(() => {
    setProjectState((state) => ({
      ...state,
      projectMembers: projectMemberList,
    }));
  }, [projectMemberList]);

  // initialState bei EDIT MODE und FAST NEW
  useEffect(() => {
    let project: Project;
    let projectMemberList: ProjectMember[] = [];

    switch (dialogNewProjectMode.mode) {
      case DialogNewProjectMode.EDIT: {
        project = getProjectByName(dialogNewProjectMode.projectName, projects);

        projectMemberList = project.projectMembers
          .map((projectMember) => {
            if (projectMember.startDate === null) {
              projectMember.startDate = project.startDate === null ? initStartDate : project.startDate;
            }
            if (projectMember.endDate === null) {
              projectMember.endDate = project.endDate === null ? initEndDate : project.endDate;
            }
            return projectMember;
          })
          .sort((a, b) => sortProjectMemberByForenames(a, b, employees));

        employee &&
          projectMemberList.push({
            projectName: dialogNewProjectMode.projectName,
            customer: project.customer,
            employeeEmail: employee.email,
            startDate: initStartDate,
            endDate: initEndDate,
            percentageTime: 0,
            percentageProbability: 0,
          });
        project.projectMembers = projectMemberList;
        break;
      }
      case DialogNewProjectMode.FAST_NEW: {
        project = { ...initProjectState, name: dialogNewProjectMode.projectName };
        projectMemberList = [
          {
            employeeEmail: employee ? employee.email : '',
            startDate: initStartDate,
            endDate: initEndDate,
            percentageTime: 0,
            percentageProbability: 0,
            customer: projectState.customer,
            projectName: dialogNewProjectMode.projectName,
          },
        ];
        project.projectMembers = projectMemberList;
        break;
      }
      case DialogNewProjectMode.CREATE_NEW: {
        project = initProjectState;
      }
    }

    setProjectMemberList(projectMemberList);
    setProjectState(project);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedProjectName = useDebounce(projectState.name, 300);
  const debouncedProjectCustomer = useDebounce(projectState.customer, 300);
  const debouncedProjectDescription = useDebounce(projectState.description, 300);

  const checkDebouncedProjectNameOrCustomer = (debouncedValue: string): boolean => {
    return !(debouncedValue.length <= 250 && debouncedValue.replace(/\s/g, '').length !== 0);
  };

  const checkDebouncedProjectDescription = (debouncedValue: string): boolean => {
    return !(debouncedValue.length <= 255);
  };

  const showInputsNotFilledMessage = () => {
    return !isFirstScreen && (projectState.name === '' || projectState.customer === '');
  };

  const deleteBtnIsShowed = () => {
    return !showDeleteConfirmation && isFirstScreen && dialogNewProjectMode.mode === DialogNewProjectMode.EDIT;
  };

  const checkProjectNameExists = (projectname: string) => {
    return (
      ((dialogNewProjectMode.mode === DialogNewProjectMode.FAST_NEW ||
        dialogNewProjectMode.mode === DialogNewProjectMode.CREATE_NEW) &&
        projects.map((project) => project.name).includes(projectname)) ||
      (dialogNewProjectMode.mode === DialogNewProjectMode.EDIT &&
        projects
          .map((project) => project.name)
          .filter((name) => name !== dialogNewProjectMode.projectName)
          .includes(projectname))
    );
  };

  const onClose = (): void => {
    setProjectState({
      ...projectState,
      projectMembers: [],
    });
    setTableRowsCompleted([]);
    setShowRowError(false);
    setShowDeleteConfirmation(false);
    setShowToast(undefined);
    handleClose();
  };

  /*
  handle Change of Inputs, save Inputs in state without validation. Validation in error
   */
  const handleChange = (e: ChangeEvent): void => {
    e.preventDefault();
    const { id, value } = e.target as HTMLInputElement;
    setProjectState({ ...projectState, [id]: value });
  };

  const handleDateChange = (date: Dayjs, fieldName: string) => {
    if (date !== null) {
      const formattedDate = date.format('DD.MM.YYYY');
      // If the previous date was the same as the date of the projectMember, then update it too
      projectMemberList.map((member) => {
        if (
          fieldName === 'projectStart' &&
          projectState.startDate === member.startDate &&
          member.employeeEmail === ''
        ) {
          member.startDate = formattedDate;
        }
        if (fieldName === 'projectEnd' && projectState.endDate === member.endDate && member.employeeEmail === '') {
          member.endDate = formattedDate;
        }
        return member;
      });
      // ansonsten wenn der vorherige state dem datum entsprochen hat --> funktion?
      setProjectState({ ...projectState, [fieldName]: formattedDate });
    }
  };

  const handleDateError = (error: DateValidationError, value: Dayjs) => {
    switch (error) {
      case 'minDate': {
        setProjectState((previous) => ({
          ...previous,
          endDate: previous.startDate,
        }));
        break;
      }
      case 'maxDate': {
        setProjectState((previous) => ({
          ...previous,
          startDate: previous.endDate,
        }));
        break;
      }
    }
  };

  return (
    <Dialog
      className={classes.root}
      open={showDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.titleRow}>
        <DialogTitle id="form-dialog-title" disableTypography>
          {dialogNewProjectMode.mode === DialogNewProjectMode.CREATE_NEW ||
          dialogNewProjectMode.mode === DialogNewProjectMode.FAST_NEW
            ? 'Neues Projekt'
            : dialogNewProjectMode.mode === DialogNewProjectMode.EDIT && `${dialogNewProjectMode.projectName}`}
        </DialogTitle>
        <IconButton onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </div>

      <div className={classes.wizzard}>
        <Stepper nonLinear activeStep={isFirstScreen ? 0 : 1}>
          <Step onClick={() => setIsFirstScreen(true)}>
            <div
              className={`${classes.stepCircle} ${
                isFirstScreen ? classes.activeStepper : classes.inactiveFirstStepper
              }`}
            >
              <Typography variant="h5" className={classes.noColor}>
                1
              </Typography>
            </div>
            <Typography className={classes.stepLabel} onClick={() => setIsFirstScreen(true)}>
              Allgemeine Projektdaten
            </Typography>
          </Step>
          <Step onClick={() => setIsFirstScreen(false)}>
            <div className={`${classes.stepCircle} ${!isFirstScreen && classes.activeStepper}`}>
              <Typography variant="h5" className={classes.noColor}>
                2
              </Typography>
            </div>
            <Typography className={classes.stepLabel} onClick={() => setIsFirstScreen(false)}>
              Projekt-Teilnehmende
            </Typography>
          </Step>
        </Stepper>
      </div>

      <DialogContent className={classes.dialogContentRoot}>
        {isFirstScreen && (
          <>
            {dialogNewProjectMode.mode !== DialogNewProjectMode.EDIT && (
              <div className={classes.dialogHint}>
                <Typography variant="body1">
                  Bitte fülle hier alle Pflichtfelder bezüglich des Projekts aus. Im nächsten Schritt kannst du dem
                  Projekt Mitarbeitenden zuweisen.
                </Typography>
              </div>
            )}
            <div className={classes.dialogGridLayout}>
              <div className={classes.formColumn}>
                <MUIEXXField
                  text={projectState.name}
                  onChange={handleChange}
                  errorInfo={{
                    isError:
                      checkDebouncedProjectNameOrCustomer(debouncedProjectName) ||
                      checkProjectNameExists(projectState.name),
                    errorMessage: checkDebouncedProjectNameOrCustomer(debouncedProjectName)
                      ? 'Projektbezeichnung darf nicht leer sein.'
                      : 'Der Projektname existiert bereits, bitte gib einen anderen Namen an.',
                  }}
                  label="Projektname"
                  placeholder="Name des Projekts..."
                  id="name"
                  required={true}
                />
                <MUIEXXField
                  text={projectState.customer}
                  onChange={handleChange}
                  errorInfo={{
                    isError: checkDebouncedProjectNameOrCustomer(debouncedProjectCustomer),
                    errorMessage: 'Kunde darf nicht leer sein.',
                  }}
                  label="Kundenname"
                  placeholder="Name des Kunden..."
                  id="customer"
                  required={true}
                />
              </div>
              <MUIEXXField
                text={projectState.description}
                onChange={handleChange}
                errorInfo={{
                  isError: checkDebouncedProjectDescription(debouncedProjectDescription),
                  errorMessage: 'Die Projektbeschreibung darf nicht länger als 250 Zeichen sein.',
                }}
                label="Beschreibung/Zusatzinfo"
                placeholder="Beschreibungen/Infos zum Projekt..."
                id="description"
                required={false}
                multilineRows={5}
              />
              <div className={classes.formRow}>
                <MUIEXXDatePicker
                  onChange={(date: Dayjs, fieldName: string) => {
                    handleDateChange(date, fieldName);
                  }}
                  value={dayjs(projectState.startDate, 'DD.MM.YYYY')}
                  formLabel={'Projektbeginn'}
                  fieldName="startDate"
                  handleDateError={handleDateError}
                  textfieldWidth={'100fr'}
                />
                <MUIEXXDatePicker
                  onChange={(date: Dayjs, fieldName: string) => {
                    handleDateChange(date, fieldName);
                  }}
                  value={dayjs(projectState.endDate, 'DD.MM.YYYY')}
                  formLabel={'Projektende'}
                  fieldName="endDate"
                  minDate={dayjs(projectState.startDate, 'DD.MM.YYYY')}
                  handleDateError={handleDateError}
                  textfieldWidth={'100fr'}
                />
              </div>
              <div className={classes.radioGroup}>
                <MUIEXXRadioGroup
                  heading="Ist das Projekt fakturierbar?"
                  selectedValue={projectState.billable}
                  radios={[
                    { label: 'ja, es gibt ein Budget', value: true },
                    { label: 'nein, es gibt kein Budget', value: false },
                  ]}
                  handleChange={(value) =>
                    setProjectState({ ...projectState, billable: value.target.value === 'true' })
                  }
                  width={300}
                />
              </div>
            </div>
          </>
        )}

        {!isFirstScreen && (
          <ProjectMemberTable
            employees={employees}
            setProjectMemberList={setProjectMemberList}
            projectMemberList={projectMemberList}
            project={projectState}
            setTableRowsCompleted={setTableRowsCompleted}
          />
        )}
      </DialogContent>

      <DialogActions {...(deleteBtnIsShowed() ? { className: classes.deleteBtn } : {})}>
        {showRowError && (
          <Typography className={classes.errorText} variant="body2">
            Bitte fülle alle Felder in der Tabelle aus bevor du fortfährst. Du kannst die Angaben zunächst auch
            schätzen.
          </Typography>
        )}
        {showDeleteConfirmation && (
          <Typography className={classes.errorText} variant="body2">
            Sicher, dass das Projekt {projectState.name} unwiderruflich gelöscht werden soll?
          </Typography>
        )}
        {showInputsNotFilledMessage() && (
          <Typography className={classes.errorText} variant="body2">
            Pflichtfelder in der ersten Seite wurden nicht ausgefüllt. Bitte kehre zurück!
          </Typography>
        )}

        {isFirstScreen && dialogNewProjectMode.mode === DialogNewProjectMode.EDIT && (
          <Typography variant="h5">
            <Link onClick={() => setShowDeleteConfirmation((prev) => !prev)}>
              {showDeleteConfirmation ? 'Abbrechen' : 'Projekt löschen'}
            </Link>
          </Typography>
        )}
        {isFirstScreen && showDeleteConfirmation && (
          <Button onClick={onDeleteProject} variant="contained" color="primary" disableRipple>
            Löschen
          </Button>
        )}
        {!showDeleteConfirmation && (
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            disabled={
              stateDialogNewProject.loading ||
              checkDebouncedProjectNameOrCustomer(debouncedProjectName) ||
              checkDebouncedProjectNameOrCustomer(debouncedProjectCustomer) ||
              checkDebouncedProjectDescription(debouncedProjectDescription) ||
              checkProjectNameExists(projectState.name) ||
              !projectState.name ||
              !projectState.customer ||
              stateDialogNewProject.success
            }
            autoFocus
            disableRipple
          >
            {stateDialogNewProject.loading && <CircularProgress size={24} color="primary" />}
            {!stateDialogNewProject.loading && (stateDialogNewProject.error ? 'Nochmal versuchen' : 'Speichern')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (store: ApplicationStore): { projects: Project[]; employees: Employee[] } => {
  return {
    employees: store.employee.employeeData,
    projects: store.project.projectData,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DialogNewProject));
