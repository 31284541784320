import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as appService from '../../services/appServices/appInitService';
import { addOrReplaceEmployee } from '../../utils/employeeUtils';
import { ApplicationStore } from '../reducers';
import { Employee } from '../reducers/employee/employee.type';
import * as appSettingActions from './AppSettingAction';
import * as employeeActions from './EmployeeActions';
import * as projectActions from './ProjectActions';
import { Project } from './ProjectActions';

const all = 'alle';

const getAllTeams = async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>): Promise<string[]> => {
  let teamsToDisplay: string[] = [all];
  return await appService
    .getTeamData()
    .then((teamData: string[]) => {
      if (teamData) {
        teamsToDisplay = teamsToDisplay.concat(teamData);
        dispatch(employeeActions.teamDataAvailable(teamsToDisplay.sort()));
        return teamData;
      }
    })
    .catch(() => {
      console.error('No TeamData in AppInitAction.ts');
      return [];
    });
};

const getAllEmployees = async (
  dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>,
  allTeams: string[],
): Promise<Employee[]> => {
  let allEmp: Employee[] = [];
  for (const team of allTeams) {
    const output = await appService
      .getEmployeeData(team)
      .then((data: Employee[]) => {
        dispatch(employeeActions.employeeDataAvailable(data));
        return data;
      })
      .catch((er: Error) => {
        console.error('No EmployeeData in AppInitAction.ts', er);
      });
    if (output) {
      for (const employee of output) {
        allEmp = addOrReplaceEmployee(allEmp, employee);
      }
    }
  }
  return allEmp;
};

const getAllProjectData = async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>): Promise<void> => {
  await appService
    .getProjectData()
    .then((data: Project[]) => {
      dispatch(projectActions.projectDataAvailable(data));
    })
    .catch(() => {
      console.error('No ProjectData in AppInitAction.ts');
    });
};

export const initApp = () => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    const allTeams: string[] = await getAllTeams(dispatch);
    dispatch(employeeActions.teamFilterAvailable(all));
    await getAllEmployees(dispatch, allTeams);
    await getAllProjectData(dispatch);
    dispatch(appSettingActions.AppStartedAction());
  };
};
