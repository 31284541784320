import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormLabel, Popper, PopperPlacementType, TextField, Theme, makeStyles } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  FilterOptionsState,
} from '@material-ui/lab';
import React, { ChangeEvent } from 'react';

import colors from '../../utils/colors';

export interface Option {
  label: string;
  value: string | number;
}

interface MUIEXXAutocompleteInputFieldProps extends StyleProps {
  options: Option[];
  id?: string;
  disabled?: boolean;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
  value?: Option;
  inputValue: string;
  placeholder: string;
  formControlStyle?: string;
  error?: boolean;
  helperText?: string;
  label?: string;
  noOptionsText?: string;
  getOptionSelected?: (option: Option, value: Option) => boolean;
  popperPlacement?: PopperPlacementType;
  popperClassname?: string;
  onChange: (
    event: ChangeEvent<{}>,
    value: NonNullable<string | Option>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<{}>,
  ) => void;
  onInputChange: (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => void;
}

interface StyleProps {
  endAdornment?: JSX.Element;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  popupIcon: ({ endAdornment }) => ({
    width: '16px',
    height: '16px',
    color: endAdornment ? colors.grey600 : colors.mainColor,
  }),
  autocomplete: ({ endAdornment }) => ({
    transform: endAdornment && 'none !important',
    '& .MuiAutocomplete-popupIndicator': {
      transform: endAdornment && 'none',
    },
  }),
}));
const MUIEXXAutocompleteInputField = (props: MUIEXXAutocompleteInputFieldProps): JSX.Element => {
  const {
    id,
    options,
    disabled,
    onChange,
    noOptionsText,
    onInputChange,
    filterOptions,
    value = null,
    inputValue,
    placeholder,
    error,
    helperText,
    label,
    formControlStyle,
    popperPlacement,
    popperClassname,
    endAdornment,
    getOptionSelected,
  } = props;
  const classes = useStyles({ endAdornment: endAdornment });

  return (
    <FormControl className={formControlStyle}>
      {label && <FormLabel>{label}</FormLabel>}
      <Autocomplete
        id={id}
        options={options}
        className={classes.autocomplete}
        selectOnFocus={true}
        autoHighlight={true}
        disabled={disabled}
        disableClearable={true}
        getOptionSelected={getOptionSelected}
        noOptionsText={noOptionsText}
        openText={null}
        filterOptions={filterOptions}
        value={value}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField placeholder={placeholder} {...params} error={error} helperText={helperText} />
        )}
        onChange={onChange}
        onInputChange={onInputChange}
        PopperComponent={(props) => (
          <Popper
            {...props}
            placement={popperPlacement || 'bottom-start'}
            // eslint-disable-next-line react/prop-types
            className={`${props.className} ${popperClassname}`}
          />
        )}
        getOptionLabel={(option) => (option ? option.label : null)}
        popupIcon={endAdornment ? endAdornment : <FontAwesomeIcon icon={faChevronDown} className={classes.popupIcon} />}
      />
    </FormControl>
  );
};

export default MUIEXXAutocompleteInputField;
