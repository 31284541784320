import { faGauge, faGaugeHigh, faGaugeLow, faTreePalm } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { GetItemsProps, Id, ItemContext, TimelineItem } from 'react-calendar-timeline';

import { senRegular } from '../../fonts/fonts';
import colors from '../../utils/colors';
import { URLAUB, getItemColor, getTextWidth } from '../../utils/timelineUtils';
import MUIEXXTooltipBase from '../BaseComponentsExxetaStyle/MUIEXXTooltipBase';
import { CustomItemParams } from './getTimelineItems';

interface StyleProps {
  projectName: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  tooltipText: {
    backgroundColor: 'transparent',
  },
  itemShape: {
    margin: '8px',
    display: 'flex',
    borderRadius: '36px',
    overflowX: 'clip',
  },
  itemContent: {
    position: 'sticky',
    left: '0',
    display: 'flex',
    alignItems: 'center',
  },
  itemPercentageBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '7px 8px 5px 0px',
  },
  itemPercentageIcon: {
    width: '22px',
    height: '24px',
  },
  itemPercentageTime: {
    position: 'relative',
    fontFamily: senRegular,
    fontSize: '14px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
  },
  projectNameAndCustomer: (props) => ({
    display: 'inline-flex',
    flexDirection: props.projectName === URLAUB ? 'column-reverse' : 'column',
    alignItems: 'flex-start',
    fontFamily: senRegular,
    fontSize: '14px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '4px',
  }),
  projectName: {
    whiteSpace: 'nowrap',
    maskImage: 'linear-gradient(to right, white 70%, transparent 100%)',
    WebkitMaskImage: 'linear-gradient(to right, white 70%, transparent 100%)',
  },
  palmIcon: {
    fontSize: 24,
  },
}));

interface CustomTimelineItemProps {
  item: TimelineItem<CustomItemParams>;
  itemContext: ItemContext;
  getItemProps: (props: GetItemsProps) => {
    key: Id;
    ref: React.Ref<any>;
    className: string;
    onMouseDown: React.MouseEventHandler;
    onMouseUp: React.MouseEventHandler;
    onTouchStart: React.TouchEventHandler;
    onTouchEnd: React.TouchEventHandler;
    onDoubleClick: React.MouseEventHandler;
    onContextMenu: React.ReactEventHandler;
    style: React.CSSProperties;
  };
  minTime: number;
  maxTime: number;
}

const CustomTimelineItem = (props: CustomTimelineItemProps): JSX.Element => {
  const { item, itemContext, getItemProps, minTime, maxTime } = props;
  const classes = useStyles({ projectName: item.name });
  const styleOptions = getItemColor(item.billable, item.percentageProbability, item.name);
  const nameTextWidth = getTextWidth(item.name, '16px "Sen Regular"');
  const customerTextWidth = getTextWidth(item.customer, '14px "Sen Regular"');

  const startBeforeVisible = item.start_time <= minTime;
  const endAfterVisible = item.end_time >= maxTime;

  if (item.start_time <= minTime && item.end_time <= minTime) {
    return <React.Fragment></React.Fragment>;
  }

  const bigItem = itemContext.dimensions.width >= 116;

  // 45px = Icon, 20px = margin itemContent, 8px = margin right Icon
  const widthOfProjectNameAndCustomer = bigItem
    ? `${itemContext.dimensions.width - (45 + 20 + 8)}px`
    : `${itemContext.dimensions.width - (20 + 8)}px`;

  const date = `${dayjs(item.start_time).format('DD.MM')} - ${dayjs(item.end_time).format('DD.MM')}`;

  return (
    <div
      {...getItemProps({
        style: {
          alignItems: 'center',
          background: colors.transparent,
          border: '0px',
          height: itemContext.dimensions.height - 20,
        },
      })}
    >
      <MUIEXXTooltipBase
        showTooltip={
          nameTextWidth > parseInt(widthOfProjectNameAndCustomer) ||
          customerTextWidth > parseInt(widthOfProjectNameAndCustomer) ||
          !bigItem
        }
        title={
          <>
            <Typography variant="h5" className={classes.tooltipText}>
              {item.name === URLAUB ? date : `${item.percentageTime} % Auslastung`}
            </Typography>
            {item.name !== URLAUB && (
              <Typography variant="body1" className={classes.tooltipText}>
                {item?.customer}
              </Typography>
            )}
            <Typography variant="body1" className={classes.tooltipText}>
              {item.name}
            </Typography>
          </>
        }
      >
        <Box
          className={classes.itemShape}
          sx={{
            marginLeft: '0px',
            marginRight: '0px',
            width: endAfterVisible
              ? startBeforeVisible
                ? itemContext.dimensions.width + 1
                : itemContext.dimensions.width - 0
              : itemContext.dimensions.width - 0,
            left: itemContext.dimensions.left + 0,
            color: styleOptions.fontColorText,
            height: itemContext.dimensions.height - 16,
            display: 'flex',
            justifyContent: !bigItem && !startBeforeVisible ? 'center' : 'none',
            background: styleOptions.itemBackgroundColor,
            border: styleOptions.border,
            borderLeft: startBeforeVisible ? `1px solid ${colors.greyLines}` : styleOptions.border,
            borderRight: endAfterVisible ? `2px solid ${colors.greyLines}` : styleOptions.border,
            borderTopLeftRadius: startBeforeVisible ? '0px' : '',
            borderBottomLeftRadius: startBeforeVisible ? '0px' : '',
            borderTopRightRadius: endAfterVisible ? '0px' : '',
            borderBottomRightRadius: endAfterVisible ? '0px' : '',
            '&:hover': {
              backgroundColor: styleOptions.hoveredItemBackgroundColor,
              color: styleOptions.hoveredTextColor + ' !important',
              border:
                styleOptions.border === colors.withBorder
                  ? `2px solid ${styleOptions.hoveredTextColor}`
                  : colors.noBorder,
              borderLeft: startBeforeVisible
                ? `1px solid ${colors.greyLines}`
                : styleOptions.border === colors.withBorder
                ? `2px solid ${styleOptions.hoveredTextColor}`
                : colors.noBorder,
              borderRight: endAfterVisible
                ? `2px solid ${colors.greyLines}`
                : styleOptions.border === colors.withBorder
                ? `2px solid ${styleOptions.hoveredTextColor}`
                : colors.noBorder,
            },
          }}
        >
          {itemContext.dimensions.width >= 76 && (
            <Box
              className={classes.itemContent}
              sx={{
                marginLeft: bigItem || startBeforeVisible ? '20px' : '0px',
              }}
            >
              {/*  TODO: Try to add backgroundimage */}

              <div className={classes.itemPercentageBox}>
                {item.name === URLAUB && <FontAwesomeIcon icon={faTreePalm} className={classes.palmIcon} />}
                {item.name !== URLAUB && (
                  <>
                    <FontAwesomeIcon
                      icon={item.percentageTime <= 30 ? faGaugeLow : item.percentageTime <= 60 ? faGauge : faGaugeHigh}
                      className={classes.itemPercentageIcon}
                    />
                    <div className={classes.itemPercentageTime}>{item.percentageTime} %</div>{' '}
                  </>
                )}
              </div>

              {/* Text nur anzeigen, wenn Projekt-Item breiter als 116px*/}
              {bigItem && (
                <div className={classes.projectNameAndCustomer}>
                  <span
                    style={{
                      backgroundColor: colors.transparent,
                      width: widthOfProjectNameAndCustomer,
                      whiteSpace: 'nowrap',
                      maskImage: 'linear-gradient(to right, white 70%, transparent 100%)',
                      WebkitMaskImage: 'linear-gradient(to right, white 70%, transparent 100%)',
                    }}
                  >
                    {item.name !== URLAUB ? item.customer : date}
                  </span>

                  <span
                    className={classes.projectName}
                    style={{
                      backgroundColor: colors.transparent,
                      width: widthOfProjectNameAndCustomer,
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              )}
            </Box>
          )}
        </Box>
      </MUIEXXTooltipBase>
    </div>
  );
};

export default CustomTimelineItem;
