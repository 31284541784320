import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useKey } from 'react-use';

import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { IsChanged } from '../../pages/employee/EmployeePage';
import { ApplicationStore } from '../../redux/reducers';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { useRouterNav } from '../../useRouterNav';
import colors from '../../utils/colors';

const useStyles = makeStyles({
  root: {
    paddingTop: '0!important',
  },
  left: {
    display: 'flex',
    flex: '1 1 auto',
  },
  buttonLeft: {
    margin: '400px 0 auto auto',
  },
  center: {
    width: '1032px',
  },
  right: {
    display: 'flex',
    flex: '1 1 auto',
  },
  buttonRight: {
    margin: '400px auto auto 0',
  },
  arrowIcon: {
    width: '16px',
    height: '16px',
    color: colors.mainColor,
  },
  arrowIconDiv: {
    display: 'flex',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  navigation: {
    gap: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  arrowText: {
    backgroundColor: 'transparent',
  },
});

export interface EmployeeNavigationProps {
  employee: Employee;
  onCancelClick: Function;
  onSaveButtonClick: Function;
  isChanged: IsChanged;
  dialogIsShowed: boolean;
  displayedEmployees: Employee[];
  children: React.ReactNode;
  onProfile: boolean;
}

const EmployeeNavigation = (props: EmployeeNavigationProps): JSX.Element => {
  const {
    employee,
    onCancelClick,
    onSaveButtonClick,
    isChanged,
    displayedEmployees,
    children,
    dialogIsShowed,
    onProfile,
  } = props;
  const classes = useStyles();
  const { goToNextEmployee } = useRouterNav();
  const [open, setOpen] = useState(false);
  const [arrowDirection, setArrowDirection] = useState('right');
  const [showArrowText, setShowArrowText] = useState(window.innerWidth >= 1400);
  const { goToMitarbeiterPage } = useRouterNav();

  useEffect(() => {
    const handleResize = () => {
      setShowArrowText(window.innerWidth >= 1400);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlerLeft = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (e.repeat || dialogIsShowed || !onProfile) return;
    if (displayedEmployees.length > 1 || dialogIsShowed) {
      onArrowPress('left');
    }
  };
  const handlerRight = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (e.repeat || dialogIsShowed || !onProfile) return;
    if (displayedEmployees.length > 1) {
      onArrowPress('right');
    }
  };
  const handlerEscape = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (e.repeat || !onProfile) return;
    onCancelClick();
    goToMitarbeiterPage();
  };
  const handlerEnter = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (e.repeat || dialogIsShowed || !onProfile) return;
    if (displayedEmployees.length > 1) {
      onSaveButtonClick(employee, true);
    }
  };
  const handlerSave = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (e.repeat || !onProfile) return;
    onSaveButtonClick(employee, false);
  };

  const keyLeftArrow = (event: KeyboardEvent): boolean => event.key === 'ArrowLeft';
  const keyRightArrow = (event: KeyboardEvent): boolean => event.key === 'ArrowRight';
  const keyEscape = (event: KeyboardEvent): boolean => event.key === 'Escape';
  const keyEnter = (event: KeyboardEvent): boolean => (event.ctrlKey === true ? event.key === 'Enter' : null);
  const keyS = (event: KeyboardEvent): boolean => (event.ctrlKey === true ? event.key === 's' : null);
  const macS = (event: KeyboardEvent): boolean => (event.metaKey === true ? event.key === 's' : null);

  useKey(keyLeftArrow, handlerLeft, { event: 'keydown' });
  useKey(keyRightArrow, handlerRight, { event: 'keydown' });
  useKey(keyEscape, handlerEscape, { event: 'keydown' });
  useKey(keyEnter, handlerEnter, { event: 'keydown' });
  useKey(keyS, handlerSave, { event: 'keydown' });
  useKey(macS, handlerSave, { event: 'keydown' });

  const onArrowPress = (direction: string): void => {
    setArrowDirection(direction);
    if (isChanged.absence || isChanged.vacation || isChanged.workload) {
      setOpen(true);
    } else {
      goToNextEmployee(direction === 'right', displayedEmployees, employee);
    }
  };

  return (
    <div className={`${classes.root}`}>
      {open && (
        <ConfirmationDialog
          open={open}
          confirmText="Speichern & Weiter"
          secondText="Weiter ohne Speichern"
          dialogDescription={
            'Deine Änderungen wurden noch nicht gespeichert Möchtest du speichern bevor du fortfährst?'
          }
          dialogTitle={'Ohne speichern fortfahren?'}
          handleClose={() => {
            setOpen(false);
          }}
          handleConfirmButton={() => {
            onSaveButtonClick(employee, true, arrowDirection === 'right');
          }}
          handleSecondaryButton={() => {
            onCancelClick();
            goToNextEmployee(arrowDirection === 'right', displayedEmployees, employee);
          }}
        />
      )}
      <Collapse in={displayedEmployees.length > 1}>
        <div className={classes.navigation}>
          <div className={classes.left}>
            {!open && onProfile && (
              <div className={classes.buttonLeft}>
                <IconButton onClick={() => onArrowPress('left')}>
                  <div className={classes.arrowIconDiv}>
                    <FontAwesomeIcon icon={faArrowLeft} className={classes.arrowIcon} />
                    {showArrowText && (
                      <Typography variant="body1" className={classes.arrowText}>
                        Person zurück
                      </Typography>
                    )}
                  </div>
                </IconButton>
              </div>
            )}
          </div>

          <div className={classes.center}>{children}</div>

          <div className={classes.right}>
            {!open && onProfile && (
              <div className={classes.buttonRight}>
                <IconButton onClick={() => onArrowPress('right')}>
                  <div className={classes.arrowIconDiv}>
                    {showArrowText && (
                      <Typography variant="body1" className={classes.arrowText}>
                        Person vor
                      </Typography>
                    )}
                    <FontAwesomeIcon icon={faArrowRight} className={classes.arrowIcon} />
                  </div>
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    dialogIsShowed: store.project.showDialog,
  };
};

export default connect(mapStateToProps)(EmployeeNavigation);
