import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import React from 'react';

import { Employee } from '../../redux/reducers/employee/employee.type';
import EmployeeCard from './EmployeeCard';

const styles = (): StyleRules =>
  createStyles({
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 200px)',
      gridGap: '24px',
      justifyContent: 'center',
      padding: '0px 24px',
    },
  });

export interface EmployeeListProps extends WithStyles<typeof styles> {
  displayedEmployees: Employee[];
  onEmployeeCardClick: Function;
}

const EmployeeList = (props: EmployeeListProps): JSX.Element => {
  const classes = props.classes;

  const { displayedEmployees, onEmployeeCardClick } = props;

  const createListOfEmployees = (): JSX.Element[] => {
    return (
      displayedEmployees
        .sort((a, b) => a.forename.toUpperCase().localeCompare(b.forename.toUpperCase())) // Removed  (compareForename) Sorting nowq works better, Uwe on last position
        // toUpperCase() for casesensitive added https://stackoverflow.com/questions/8900732/sort-objects-in-an-array-alphabetically-on-one-property-of-the-array
        .map((employee) => {
          return <EmployeeCard key={employee.id} onEmployeeCardClick={onEmployeeCardClick} employee={employee} />;
        })
    );
  };

  return <div className={classes.grid}>{createListOfEmployees()}</div>;
};

export default withStyles(styles)(EmployeeList);
