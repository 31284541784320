import { Button, Link, Typography, makeStyles } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';

import { StatusStore } from '../../pages/employee/EmployeePage';
import { Employee } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';

const useStyles = makeStyles((theme) => ({
  employeeNavigationForward: {
    position: 'absolute',
    right: '-183px',
    top: '8px',
  },
  buttonProgress: {
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },

  fixedBottomNavigation: {
    zIndex: 150,
    display: 'flex',
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    alignItems: 'center',
    height: '80px',
    borderTop: `2px solid ${colors.mainColor}`,
    background: colors.white,
    justifyContent: 'space-between',
    width: '100%',
    padding: '24px',
  },
  saveButtons: {
    display: 'flex',
    gap: '16px',
  },
  errorText: {
    color: colors.red500,
  },
}));

export interface EmployeeBottomProps {
  employee: Employee;
  employeeState: StatusStore;
  onCancelButtonClick: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
  onSaveButtonClick: Function;
  displayedEmployees: Employee[];
}

const EmployeeBottom = (props: EmployeeBottomProps): JSX.Element => {
  const classes = useStyles();
  const { employeeState, onCancelButtonClick, onSaveButtonClick, displayedEmployees, employee } = props;

  const { isChanged, errorShowed, isValid } = employeeState;

  return (
    <>
      {(isChanged.absence || isChanged.vacation || isChanged.workload) && (
        <div className={classes.fixedBottomNavigation}>
          {errorShowed ? (
            <Typography variant="body2" className={classes.errorText}>
              In der Abwesenheitsverwaltung wurden nicht alle Felder ausgefüllt. Bitte gehe nochmal in die
              Einstellungen.
            </Typography>
          ) : (
            <Typography variant="h5">
              <Link onClick={onCancelButtonClick}>Änderungen verwerfen</Link>
            </Typography>
          )}

          <div className={classes.saveButtons}>
            <Button
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                onSaveButtonClick(employee, false);
              }}
              variant="outlined"
              color="primary"
              disableRipple
              disabled={!isValid && errorShowed}
            >
              Speichern
            </Button>

            {displayedEmployees.length > 1 && (
              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  onSaveButtonClick(employee, true);
                }}
                variant="contained"
                color="primary"
                disableRipple
                disabled={!isValid && errorShowed}
              >
                Speichern & Weiter
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeBottom;
