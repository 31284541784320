import { Card, CardContent, CardMedia, Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import EmptyEmployee from '../../models/shared/EmptyEmployee';
import { Absence, Employee, WorkloadStatus } from '../../redux/reducers/employee/employee.type';
import StatusChip, { StatusChipsVariants } from '../status/StatusChip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '420px',
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '188px',
  },

  media: { width: '150px', height: '150px', position: 'relative' },

  profileImage: {
    height: '150px',
    width: '150px',
    borderRadius: '12px',
  },

  profileImageContainer: {
    position: 'absolute',
    zIndex: 0,
  },

  zoomedProfileImage: {
    objectPosition: 'center 17%',
    objectFit: 'none',
  },
  statusChipsContainer: {
    height: '150px',
    width: '150px',
    padding: '4px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '8px',
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    gap: '16px',
  },

  linkToIntranet: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },

  nameAndTeam: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

interface EmployeeInfoCardContentProps {
  employee: Employee;
}

const EmployeeInfoCardContent = (props: EmployeeInfoCardContentProps): JSX.Element => {
  const classes = useStyles();
  const { employee } = props;
  const { absenceStatus, workloadStatus } = employee.status || EmptyEmployee.status;

  const fullName = employee.forename + ' ' + employee.surname;
  // teams.length - 1 extracts sub-team
  const team = employee.teams[employee.teams.length - 1];
  const intranetLinkPrefix = 'https://intranet.exxeta.network/#/profile/';

  const linkToIntranet = intranetLinkPrefix + employee.email;
  const statusChips = [
    absenceStatus,
    ...Object.keys(workloadStatus).filter((key) => workloadStatus[key as keyof WorkloadStatus]),
  ].filter((workloadTag) => workloadTag !== Absence.I_AM_HERE) as StatusChipsVariants[];

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        {/*important div (for status setup) */}
        <div>
          <div className={classes.media}>
            <div className={classes.profileImageContainer}>
              <CardMedia
                component="img"
                className={`${employee.profileImageIsZoomed && classes.zoomedProfileImage} ${classes.profileImage}`}
                image={employee.profileImage}
              />
            </div>
            <div className={classes.statusChipsContainer}>
              {statusChips.map((statusVariant) => (
                <StatusChip variant={statusVariant} key={statusVariant} />
              ))}
            </div>
          </div>
        </div>
        <div className={classes.details}>
          <div className={classes.nameAndTeam}>
            <Typography variant="h5">{fullName}</Typography>
            <Typography variant="body1">{employee.position}</Typography>
            <Typography variant="body1">{team}</Typography>
          </div>

          <Typography variant="h5" className={classes.linkToIntranet}>
            <Link href={linkToIntranet} target="_blank">
              Zum Exxeta-Profil
            </Link>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default EmployeeInfoCardContent;
