import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import MUIEXXField from '../BaseComponentsExxetaStyle/MUIEXXField';

interface PageSearchFieldProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  searchValue: string;
  placeholder?: string;
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '571px',
    margin: '8px auto 24px auto',
  },
}));

const PageSearchField = (props: PageSearchFieldProps): JSX.Element => {
  const { searchValue, onChange, placeholder } = props;
  const classes = useStyles();

  return (
    <MUIEXXField
      className={classes.inputField}
      text={searchValue}
      onChange={onChange}
      placeholder={placeholder}
      endAdornment={<FontAwesomeIcon icon={faMagnifyingGlass} />}
    />
  );
};

export default PageSearchField;
