import { CircularProgress, Theme } from '@material-ui/core';
import { StyleRules, createStyles } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { AutocompleteInputChangeReason } from '@material-ui/lab';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Option } from '../../components/BaseComponentsExxetaStyle/MUIEXXAutocompleteInputField';
import { DialogNewProjectMode } from '../../components/Dialogs/DialogNewProject/DialogNewProject';
import EmployeeInfoCardContent from '../../components/profil/EmployeeInfoCardContent';
import EmployeeStatusCardContent from '../../components/profil/EmployeeStatusCardContent';
import EmployeeWorkloadCardContent from '../../components/profil/EmployeeWorkloadCardContent';
import { InputSelectOption } from '../../models/shared/InputSelectOption';
import { ProjectShort } from '../../models/shared/ProjectShort';
import { Project, changeDialogMode, setShowDialog } from '../../redux/actions/ProjectActions';
import { ApplicationStore } from '../../redux/reducers';
import { Employee } from '../../redux/reducers/employee/employee.type';
import {
  createSuggestionListOfProjectNames,
  getProjectsFromEmployee,
  mapProjectsToShortProjects,
} from '../../utils/projectUtils';
import { StatusStore } from './EmployeePage';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    flexRow: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    spinnerWrapper: {
      paddingTop: theme.spacing(5),
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'auto',
    },
  });

interface EmployeeBodyProps extends WithStyles<typeof styles> {
  employee: Employee;
  projects: Project[];
  employeeState: StatusStore;
  handleWorkloadClick: Function;
}

const EmployeeBody = (props: EmployeeBodyProps): JSX.Element => {
  const { classes, employee, projects, employeeState, handleWorkloadClick } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useDispatch();

  const allProjectShorts: ProjectShort[] = mapProjectsToShortProjects(projects);
  const [employeeProjectList, setEmployeeProjectList] = useState<Project[]>(
    getProjectsFromEmployee(projects, employee.email),
  );
  const projectsFromEmployee = employeeProjectList.map((project) => project.name);

  useEffect(() => {
    setEmployeeProjectList(getProjectsFromEmployee(projects, employee.email));
  }, [employee, projects]);

  const handleClickOnAddProject = (addedProject: string) => {
    if (!projectsFromEmployee.includes(addedProject)) {
      dispatch(
        changeDialogMode({
          mode: DialogNewProjectMode.EDIT,
          projectName: addedProject,
          employee: employee,
          startOnSecondStep: true,
        }),
      );
      dispatch(setShowDialog(true));
    } else {
      setInputValue('');
      alert('Projekt ist bereits ausgewählt.');
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      if (!event.target.value) {
        setInputValue('');
      } else if (event.type === 'change') {
        setInputValue(event.currentTarget.value || null);
      } else if (event.type !== 'click' && event.target.value) {
        const option: InputSelectOption = JSON.parse(JSON.stringify(event.target.value));

        if (option.customer === 'customerKeyForPopOver') {
          dispatch(
            changeDialogMode({
              mode: DialogNewProjectMode.FAST_NEW,
              projectName: inputValue,
              employee: employee,
            }),
          );
          dispatch(setShowDialog(true));
        } else if (event.type !== 'blur') {
          handleClickOnAddProject(option.value);
        }
      }
    }
  };

  const handleOnChange = (event: ChangeEvent<{}>, value: NonNullable<string | Option>) => {
    const tmpEvent = {};
    Object.defineProperty(tmpEvent, 'target', {
      writable: false,
      value: { value: value ? value : null },
    });

    Object.defineProperty(tmpEvent, 'currentTarget', {
      writable: false,
      value: {},
    });
    handleInput(tmpEvent as ChangeEvent<HTMLInputElement>);
  };

  const handleOnInputChange = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    handleInput(event as React.ChangeEvent<HTMLInputElement>);
  };

  return !employee ? (
    <div className={classes.spinnerWrapper}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.flexColumn}>
      <div className={classes.flexRow}>
        <EmployeeInfoCardContent employee={employee} />
        <EmployeeStatusCardContent
          employee={employee}
          handleWorkloadClick={handleWorkloadClick}
          employeeState={employeeState}
        />
      </div>
      <EmployeeWorkloadCardContent
        suggestionList={createSuggestionListOfProjectNames(allProjectShorts, projectsFromEmployee, inputValue)}
        handleOnChange={handleOnChange}
        handleOnInputChange={handleOnInputChange}
        inputValue={inputValue}
        employee={employee}
      />
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    projects: store.project.projectData,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(EmployeeBody));
