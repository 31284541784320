import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton, Typography, createStyles } from '@material-ui/core';
import { StyleRules, Theme, WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import BillableFilter, { BillableFilterValues } from '../components/Filter/BillableFilter';
import EmployeeWorkloadFilter, { EmployeeWorkloadFilterValues } from '../components/Filter/EmployeeWorkloadFilter';
import TeamFilter from '../components/Filter/TeamFilter';
import WorkloadProjectFilter, { WorkloadProjectFilterValues } from '../components/Filter/WorkloadProjectFilter';
import CustomTimeline from '../components/Timeline/CustomTimeline';
import { getTimelineGroups } from '../components/Timeline/getTimelineGroups';
import { getTimelineItems } from '../components/Timeline/getTimelineItems';
import TimelineCaption from '../components/TimelineCaption';
import SubHeader from '../components/ToolbarTop/SubHeader';
import { Project } from '../redux/actions/ProjectActions';
import { changeEmployeeSearchValue, displayRedBoxes } from '../redux/actions/WorkloadActions';
import { ApplicationStore } from '../redux/reducers';
import { Employee } from '../redux/reducers/employee/employee.type';
import colors from '../utils/colors';
import useResizeStatus from '../utils/resizeHook';
import { isBillable } from '../utils/timelineUtils';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0px 40px 40px 40px',
      '& .rct-header-root': {
        border: `2px solid ${colors.grey200}`,
      },
    },
    wrapper: {
      display: 'block',
      width: `100%`,
      marginBottom: '48px',
      '& .rct-header-root': {
        position: 'sticky',
        top: '187px', // Calculations: appbar(84) + filter(52) + searchfield(40 + margin(16 + 32)) + border(1)
      },
    },
    filter: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      zIndex: 110,
    },
    filterRow: {
      display: 'flex',
      flexDirection: 'row',
      height: '52px',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    timelineCaption: {
      position: 'fixed',
      bottom: '0px',
      zIndex: 90,
      display: 'flex',
      backgroundColor: colors.white,
      left: '0px',
      right: '0px',
      padding: '0px 8px 0px 24px',
      borderTop: `1px solid ${colors.mainColor}`,
    },
    captionShowed: {
      height: '80px',
      transition: 'height 0.15s ease-in',
    },
    captionNotShowed: {
      height: '40px',
      transition: 'height 0.15s ease-out',
    },
    captionToggle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '13px',
    },
    captionToggleWrapper: {
      position: 'absolute',
      top: '8px',
      right: '0px',
    },
    chevron: {
      fontSize: '16px',
    },
  });

export interface AuslastungProps extends WithStyles<typeof styles> {
  displayedEmployees: Employee[];
  employees: Employee[];
  teamData: string[];
  projects: Project[];
  isAllEmployeesLoaded: boolean;
  employeeSearchValue: string;
  filters: {
    currentEmployeeFilter: string;
    billableFilter: BillableFilterValues;
    workloadLimit: EmployeeWorkloadFilterValues;
    workloadProjects: WorkloadProjectFilterValues;
  };
  searchValue: string;
}

const Auslastung = (props: AuslastungProps): JSX.Element => {
  const {
    classes,
    employees,
    teamData,
    filters,
    displayedEmployees,
    projects,
    isAllEmployeesLoaded,
    employeeSearchValue,
    searchValue,
  } = props;
  const { currentEmployeeFilter, billableFilter, workloadLimit, workloadProjects } = filters;
  const dispatch = useDispatch();

  const { isResizing } = useResizeStatus();
  const [showCaption, setShowCaption] = useState<boolean>(true);

  const today = dayjs();
  const visibleMonthCount = 6;

  const items = getTimelineItems(
    currentEmployeeFilter,
    displayedEmployees,
    employees,
    projects.filter((project) => isBillable(billableFilter, project)),
  );

  const groups = getTimelineGroups({
    currentEmployeeFilter,
    employees,
    displayedEmployees,
    teamData,
    employeeSearchValue,
    onlyEmployee: false,
    items: items,
    visibleMonthCount: visibleMonthCount,
    startDay: today,
    workloadLimitFilter: workloadLimit,
    workloadProjectTypeFilter: workloadProjects,
  });

  useEffect(() => {
    if (isAllEmployeesLoaded) {
      dispatch(displayRedBoxes(!isResizing));
    }
  }, [isResizing, isAllEmployeesLoaded, dispatch]);

  useEffect(() => {
    if (isAllEmployeesLoaded) {
      dispatch(displayRedBoxes(false));
    }
    const frameId = requestAnimationFrame(() => {
      if (isAllEmployeesLoaded) {
        dispatch(displayRedBoxes(true));
      }
    });

    return () => cancelAnimationFrame(frameId);
  }, [currentEmployeeFilter, dispatch, isAllEmployeesLoaded, workloadLimit, workloadProjects]);

  return (
    <div className={classes.root}>
      <SubHeader
        handleInputChange={(event: ChangeEvent) => {
          dispatch(changeEmployeeSearchValue((event.target as HTMLInputElement).value));
        }}
        searchValue={searchValue}
        placeholder={'Projekt, Kunde oder Mitarbeitenden suchen'}
      >
        <TeamFilter />
        <BillableFilter />
        <EmployeeWorkloadFilter />
        {workloadLimit !== EmployeeWorkloadFilterValues.all && <WorkloadProjectFilter />}
      </SubHeader>

      {isAllEmployeesLoaded ? (
        <div className={classes.wrapper}>
          <CustomTimeline
            items={items}
            employees={employees}
            hideSidebar={false}
            groups={groups}
            startDay={today}
            visibleMonthCount={visibleMonthCount}
          />
          <div
            className={`${classes.timelineCaption} ${showCaption ? classes.captionShowed : classes.captionNotShowed}`}
          >
            {showCaption && <TimelineCaption showDetailedCaption />}

            <IconButton onClick={() => setShowCaption((prev) => !prev)} className={classes.captionToggleWrapper}>
              <div className={classes.captionToggle}>
                <Typography variant="body2">Legende {showCaption ? 'ausblenden' : 'einblenden'}</Typography>
                <FontAwesomeIcon icon={showCaption ? faChevronDown : faChevronUp} className={classes.chevron} />
              </div>
            </IconButton>
          </div>
        </div>
      ) : (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    employeeSearchValue: store.workload.employeeSearchValue,
    employees: store.employee.employeeData,
    teamData: store.employee.teamData,
    displayedEmployees: store.employee.displayedEmployees,
    projects: store.project.projectData,
    isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
    filters: {
      currentEmployeeFilter: store.employee.shownSpecificTeam,
      billableFilter: store.workload.billableFilter,
      workloadLimit: store.workload.workloadLimit,
      workloadProjects: store.workload.workloadProjectType,
    },
    searchValue: store.workload.employeeSearchValue,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Auslastung));
