import { CircularProgress, Theme, createStyles, withStyles } from '@material-ui/core';
//Components
import { StyleRules } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import EmployeeList from '../components/Employee/EmployeeList';
import TeamFilter from '../components/Filter/TeamFilter';
import SubHeader from '../components/ToolbarTop/SubHeader';
import { ApplicationStore } from '../redux/reducers';
import { Employee } from '../redux/reducers/employee/employee.type';
import { useRouterNav } from '../useRouterNav';
import colors from '../utils/colors';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '40px',
    },

    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    stuckHeader: {
      borderBottom: `1px solid ${colors.grey200}`,
    },
    teamFilterGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 200px)',
      gridGap: '24px',
      justifyContent: 'center',
      width: '100%',
    },
  });

export interface MitarbeiterProps extends WithStyles<typeof styles> {
  employees: Employee[];
  displayedEmployees: Employee[];
  isAllEmployeesLoaded: boolean;
}

const Mitarbeiter = (props: MitarbeiterProps): JSX.Element => {
  const { classes, displayedEmployees, isAllEmployeesLoaded } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedEmployees, setSearchedEmployees] = useState<Employee[]>(displayedEmployees);
  const { goToEmployeeProfile } = useRouterNav();

  // open employee profil card of clicked employee
  const handleEmployeeCardClick = (employee: Employee, event: Event): void => {
    event.preventDefault();
    goToEmployeeProfile(employee);
  };

  useEffect(() => {
    const newFilteredEmployees = displayedEmployees.filter((employee) => {
      return `${employee.forename} ${employee.surname}`.toLowerCase().includes(searchValue.toLowerCase());
    });
    setSearchedEmployees(newFilteredEmployees);
  }, [searchValue, displayedEmployees]);

  const handleInputSearchChange = (event: ChangeEvent) => {
    setSearchValue((event.target as HTMLInputElement).value);
  };

  return (
    <div className={classes.root}>
      <SubHeader
        handleInputChange={handleInputSearchChange}
        searchValue={searchValue}
        placeholder={'Mitarbeitende suchen'}
        stuckClass={classes.stuckHeader}
      >
        <div className={classes.teamFilterGrid}>
          <TeamFilter />
        </div>
      </SubHeader>
      {isAllEmployeesLoaded ? (
        <EmployeeList displayedEmployees={searchedEmployees} onEmployeeCardClick={handleEmployeeCardClick} />
      ) : (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    employees: store.employee.employeeData,
    projects: store.project.projectData,
    displayedEmployees: store.employee.displayedEmployees,
    isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Mitarbeiter));
