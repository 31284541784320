import { Card, makeStyles } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React, { BaseSyntheticEvent } from 'react';

import EmptyEmployee from '../../models/shared/EmptyEmployee';
import { Absence, Employee, WorkloadStatus } from '../../redux/reducers/employee/employee.type';
import StatusChip, { StatusChipsVariants } from '../status/StatusChip';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '200px',
    margin: '0px',
  },

  cardAreaWrapper: {
    '&:hover button': {
      display: 'block',
    },
  },
  cardArea: {
    position: 'relative',
    width: '200px',
    height: '200px',
  },

  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },

  zoomedProfileImage: {
    objectPosition: 'center 17%',
    objectFit: 'none',
  },

  profileImage: {
    position: 'absolute',
    height: 200,
    width: 200,
    zIndex: 0,
  },

  statusChipsContainer: {
    height: '200px',
    width: '200px',
    padding: '4px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '8px',
  },

  nameEmployee: {
    fontSize: '1rem',
    fontWeight: 900,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },

  projectsEmployee: {
    flex: 1,
  },

  employeeNameBox: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface EmployeeCardProps {
  key: string;
  employee: Employee;
  onEmployeeCardClick: Function;
}

const EmployeeCard = (props: EmployeeCardProps): JSX.Element => {
  const { employee, onEmployeeCardClick } = props;
  const classes = useStyles();
  const { absenceStatus, workloadStatus } = employee.status || EmptyEmployee.status;

  const statusChips = [
    absenceStatus,
    ...Object.keys(workloadStatus).filter((key) => workloadStatus[key as keyof WorkloadStatus]),
  ].filter((workloadTag) => workloadTag !== Absence.I_AM_HERE) as StatusChipsVariants[];

  const onImageClick = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    onEmployeeCardClick(employee, event);
  };

  return (
    <Card className={classes.card} elevation={0} square={true}>
      <CardActionArea className={classes.cardAreaWrapper} onClick={(event) => onImageClick(event)}>
        <div className={classes.cardArea}>
          <CardMedia
            component="img"
            className={`${employee.profileImageIsZoomed && classes.zoomedProfileImage} ${classes.profileImage}`}
            image={employee.profileImage}
            title={employee.forename + ' ' + employee.surname}
          />

          <div className={classes.statusChipsContainer}>
            {statusChips.map((statusVariant) => (
              <StatusChip variant={statusVariant} key={statusVariant} />
            ))}
          </div>
        </div>
      </CardActionArea>
      <CardContent className={classes.cardContent}>
        <div className={classes.employeeNameBox}>
          <Typography variant="body1">{employee.forename}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default EmployeeCard;
