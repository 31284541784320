import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import { Absence, Status } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import { getNextMonday } from '../../utils/dateUtils';
import MUIEXXRadioGroup from '../BaseComponentsExxetaStyle/MUIEXXRadioGroup';

interface AbwesenheitsstatusProps {
  employeeStatus: Status;
  handleAbsenceValue: Function;
}

const useStyles = makeStyles((theme) => ({
  smallContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  notice: {
    color: colors.grey600,
  },
  noticeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  radioGroup: {
    gap: '32px',
  },
}));

const Abwesenheitsstatus = (props: AbwesenheitsstatusProps) => {
  const { employeeStatus, handleAbsenceValue } = props;
  const { absenceStatus } = employeeStatus;
  const classes = useStyles();

  return (
    <div className={classes.smallContent}>
      <div className={classes.noticeBox}>
        <Typography variant="h5">Kannst du im Weekly dabei sein?</Typography>
        <Typography variant="body2" className={classes.notice}>
          Hinweis: Hier kannst du angeben ob du bei dem Weekly Stand-up Termin dabei bist oder nicht.
        </Typography>
      </div>
      <div className={classes.radioGroup}>
        <MUIEXXRadioGroup
          radios={[
            { value: Absence.I_AM_HERE, label: 'Ich bin diesmal dabei' },
            {
              value: Absence.I_AM_ABSENT,
              label: `Ich bin beim nächsten Termin, den ${getNextMonday().format('DD.MM.YYYY')} nicht da`,
            },
            { value: Absence.I_AM_PERMANENTLY_ABSENT, label: 'Ich bin dauerhaft nicht da' },
          ]}
          selectedValue={absenceStatus}
          handleChange={(event) => handleAbsenceValue(event)}
          isRow={false}
        />
      </div>
    </div>
  );
};

export default Abwesenheitsstatus;
