import { AnyAction } from 'redux';

import * as actionTypes from '../../actions/ActionTypes';
import { Employee, EmployeeStore } from './employee.type';

export const employeeInitialData: EmployeeStore = {
  displayedEmployees: [],
  employeeData: [],
  openEmployeeCards: [],
  shownSpecificTeam: '',
  teamData: [],
  statusTag: [],
  profileImage: '',
};

function EmployeeReducer(state = employeeInitialData, action: AnyAction): EmployeeStore {
  let newEmployeeData: Employee[];
  let currentDisplayedEmployees: Employee[];
  let currentShownSpecificTeam: any;

  switch (action.type) {
    case actionTypes.EMPLOYEES_DATA_AVAILABLE: {
      // Action is called team-wise!
      const employeeData: Employee[] = [...state.employeeData];
      let filteredEmployees: Employee[];
      const listOfAllIds: string[] = employeeData.map((emp) => {
        return emp.id;
      });
      // Add new employees to general employeeData
      newEmployeeData = action.payload.employeeData;
      newEmployeeData.forEach((emp) => {
        // If the new employee is not already in allEmployeeData, then add him
        if (!listOfAllIds.includes(emp.id)) {
          employeeData.push(emp);
          listOfAllIds.push(emp.id);
        }
        // If found with the same id then overwrite
        //TODO: Check if it would be "faster" to check if there were any changes and then replace the employee
        else {
          const empInd = employeeData.findIndex((oldEmp) => {
            return oldEmp.id === emp.id;
          });
          employeeData[empInd] = emp;
        }
      });
      const filter = state.shownSpecificTeam;
      // Filter all employees (new and old) for the current shownSpecificTeamFilter and update this list
      if (!filter || filter === 'alle') {
        // If there is no filter set or "all", then return all employees
        filteredEmployees = [...employeeData];
      } else {
        // Filter all employees which are in the specific team
        filteredEmployees = employeeData.filter((employee: Employee) => {
          return employee.teams.includes(filter);
        });
      }
      return {
        ...state,
        displayedEmployees: filteredEmployees.sort((a, b) =>
          a.forename.toUpperCase().localeCompare(b.forename.toUpperCase()),
        ),
        employeeData: employeeData.sort((a, b) => a.forename.toUpperCase().localeCompare(b.forename.toUpperCase())),
      };
    }

    case actionTypes.TEAM_DATA_AVAILABLE: {
      return {
        ...state,
        teamData: action.payload,
      };
    }

    case actionTypes.TEAM_FILTER_DATA_AVAILABLE: {
      return {
        ...state,
        displayedEmployees: state.employeeData,
        shownSpecificTeam: action.payload,
      };
    }

    case actionTypes.SHOW_SPECIFIC_TEAM: {
      currentDisplayedEmployees = [...state.displayedEmployees];
      newEmployeeData = [...state.employeeData];
      currentDisplayedEmployees.length = 0;
      currentShownSpecificTeam = action.payload;

      let filteredEmployees: Employee[];
      // Filter all employees which are in the "currentShownSpecificTeam"
      if (action.payload === 'alle') {
        filteredEmployees = [...newEmployeeData];
      } else {
        filteredEmployees = newEmployeeData.filter((employee: Employee) => {
          return employee.teams.includes(currentShownSpecificTeam);
        });
      }
      // Add everyone in the team to the list of the displayed employees
      filteredEmployees.forEach((employee) => {
        currentDisplayedEmployees.push(employee);
      });

      return {
        ...state,
        displayedEmployees: currentDisplayedEmployees.sort((a, b) =>
          a.forename.toUpperCase().localeCompare(b.forename.toUpperCase()),
        ),
        shownSpecificTeam: currentShownSpecificTeam,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
export default EmployeeReducer;
