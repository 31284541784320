import { useHistory, useLocation } from 'react-router';

import { Employee } from './redux/reducers/employee/employee.type';
import { createUniqueEmployeeNameFromEmail, getEmployeeIndex } from './utils/employeeUtils';

export const useRouterNav = () => {
  const history = useHistory();
  const location = useLocation();

  const goToMitarbeiterPage = () => {
    history.push('/mitarbeiter');
  };

  const goToEmployeeProfile = (employee: Employee) => {
    history.push('/mitarbeiter/profil/' + createUniqueEmployeeNameFromEmail(employee.email));
  };

  const goToNextEmployee = (moveRight: boolean, displayedEmployees: Employee[], employee: Employee) => {
    const currentPosition = getEmployeeIndex(displayedEmployees, employee);
    let nextEmployee: Employee;

    moveRight
      ? // next to the right
        (nextEmployee = displayedEmployees[currentPosition + 1]
          ? displayedEmployees[currentPosition + 1]
          : displayedEmployees[0])
      : // next employee to the left
        (nextEmployee = displayedEmployees[currentPosition - 1]
          ? displayedEmployees[currentPosition - 1]
          : displayedEmployees[displayedEmployees.length - 1]);

    if (displayedEmployees.length > 1) {
      goToEmployeeProfile(nextEmployee);
    }
  };

  const goToManageEmployeeAbsences = (employee: Employee) => {
    const basePath = 'mitarbeiter/profil/';
    const uniqueName = createUniqueEmployeeNameFromEmail(employee.email);
    const statusPath = '/abwesenheit-verwalten';
    const newUrl = basePath + uniqueName + statusPath;

    if (location.pathname.includes(basePath)) {
      history.push(uniqueName + statusPath);
    } else {
      history.push(newUrl);
    }
  };

  return { goToManageEmployeeAbsences, goToMitarbeiterPage, goToEmployeeProfile, goToNextEmployee };
};
