import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControl, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { ChangeEventHandler, MouseEventHandler } from 'react';

import colors from '../../utils/colors';

interface MUIEXXCheckBoxProps extends StyleProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick: MouseEventHandler<HTMLDivElement>;
  checked: boolean;
  label?: string;
  disabled?: boolean;
}

interface StyleProps {
  margin?: string | number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    padding: 0,
    '& .MuiSvgIcon-root': {
      visibility: 'hidden',
      display: 'none',
    },
  },
  checkbox: ({ margin }) => ({
    margin: margin,
  }),
  checkIcon: {
    position: 'absolute',
    width: '10px',
  },
  checkBoxLabel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  disabledCheckIcon: {
    color: colors.grey600,
  },
  label: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

const MUIEXXCheckBox = (props: MUIEXXCheckBoxProps): JSX.Element => {
  const { onChange, checked, label, disabled, margin, onClick } = props;
  const classes = useStyles({ margin: margin });

  return (
    <FormControl className={classes.root} onChange={onChange}>
      <Checkbox
        checked={checked}
        id={label}
        disabled={disabled}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        className={classes.checkbox}
        checkedIcon={
          <FontAwesomeIcon icon={faCheck} className={`${classes.checkIcon} ${disabled && classes.disabledCheckIcon}`} />
        }
      />
      <div className={classes.label} onClick={onClick}>
        {label && <Typography variant="body1">{label}</Typography>}
      </div>
    </FormControl>
  );
};

export default MUIEXXCheckBox;
