import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { DialogMode } from '../../components/Dialogs/DialogNewProject/DialogNewProject';
import * as appServiceGet from '../../services/appServices/appInitService';
import * as appServicePost from '../../services/appServices/appRefreshService';
import { getEmployeeFromEmail } from '../../utils/employeeUtils';
import { ApplicationStore } from '../reducers';
import { Employee } from '../reducers/employee/employee.type';
import * as actionTypes from './ActionTypes';
import { getAllEmployees } from './EmployeeActions';

export interface ProjectMember {
  projectName: string;
  customer: string;
  employeeEmail: string;
  percentageTime: number;
  percentageProbability: number;
  startDate: string;
  endDate: string;
}

export interface Project {
  name: string;
  customer: string;
  description: string;
  projectMembers: ProjectMember[];
  archived: boolean;
  billable: boolean;
  startDate: string;
  endDate: string;
}

export interface UpdatedProject extends Project {
  newProjectName: string;
}

export interface ProjectDataAvailable {
  payload: Project[];
  type: typeof actionTypes.PROJECT_DATA_AVAILABLE;
}

export interface ShowDialog {
  payload: boolean;
  type: typeof actionTypes.SET_SHOW_DIALOG;
}

export interface ChangeDialogMode {
  payload: DialogMode;
  type: typeof actionTypes.CHANGE_DIALOG_MODE;
}

export interface ArchiveProject {
  payload: Project[];
  type: typeof actionTypes.ARCHIVE_PROJECT;
}

export interface DeleteProjectConfirmed {
  payload: Project[];
  type: typeof actionTypes.DELETE_PROJECT_CONFIRMED;
}

export const projectDataAvailable = (projectData: Project[]): ProjectDataAvailable => {
  return {
    payload: projectData,
    type: actionTypes.PROJECT_DATA_AVAILABLE,
  };
};

export const addNewProject = (changesNewProject: Project, callback: Function) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    console.log('new Project: ', changesNewProject);
    await appServicePost
      .postNewProject([changesNewProject], callback)
      .then((res: any) => {
        //TODO refresh only affected employees and Projects
        dispatch(updateProjectsAndEmployees());
        return res.data;
      })
      .catch((err: any) => {
        return err.data;
      });
  };
};

export const sortProjectMemberByForenames = (a: ProjectMember, b: ProjectMember, employees: Employee[]) => {
  const forenameA = getEmployeeFromEmail(a.employeeEmail, employees).forename.toUpperCase();
  const forenameB = getEmployeeFromEmail(b.employeeEmail, employees).forename.toUpperCase();
  if (forenameA < forenameB) {
    return -1;
  }
  if (forenameA > forenameB) {
    return 1;
  }
  return 0;
};

export const updateProject = (oldProjectName: string, newProject: Project, callback: Function) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    console.log('newProject', newProject);
    console.log('newProjectMember', newProject.projectMembers);
    const updatedProject: UpdatedProject = {
      ...newProject,
      newProjectName: newProject.name,
      name: oldProjectName,
    };
    await appServicePost
      .putUpdatedProject(updatedProject, callback)
      .then((res: any) => {
        //TODO refresh only affected employees and Projects
        dispatch(updateProjectsAndEmployees());
        return res.data;
      })
      .catch((err: any) => {
        return err.data;
      });
  };
};

export const archiveProject = (name: string, value: boolean) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    await appServicePost
      .archiveProject(name.toString(), value)
      .then(() => {
        //TODO refresh only affected employees and Projects
        dispatch(updateProjectsAndEmployees());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteProjectConfirmedAction = (name: string) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    await appServicePost
      .deleteProject(name)
      .then((res) => {
        //TODO refresh only affected employees and Projects
        dispatch(updateProjectsAndEmployees());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setShowDialog = (showDialog: boolean): ShowDialog => {
  return {
    payload: showDialog,
    type: actionTypes.SET_SHOW_DIALOG,
  };
};

export const changeDialogMode = (dialogMode: DialogMode): ChangeDialogMode => {
  return { payload: dialogMode, type: actionTypes.CHANGE_DIALOG_MODE };
};

// TODO: Discuss: Update changes after going to next Employee? Otherwise changes will only be visible on one computer
// until a second person will update or save something
export const updateProjectsAndEmployees = () => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    // Get updated project data from database
    await appServiceGet
      .getProjectData()
      .then((data: Project[]) => {
        dispatch(projectDataAvailable(data));
      })
      .catch((err: { data: any }) => {
        return err.data;
      });
    // Get updated employee data from database
    dispatch(getAllEmployees());
  };
};

export type DispatchedProjectAction = ProjectDataAvailable | ShowDialog | ChangeDialogMode;
