import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import { Status, Workload } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import MUIEXXCheckBox from '../BaseComponentsExxetaStyle/MUIEXXCheckBox';

interface AuslastungsstatusProps {
  employeeStatus: Status;
  handleCheckboxClick: Function;
}

const useStyles = makeStyles((theme) => ({
  smallContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  notice: {
    color: colors.grey600,
  },
  checkBoxes: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
  },
  noticeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const Auslastungsstatus = (props: AuslastungsstatusProps) => {
  const { employeeStatus, handleCheckboxClick } = props;
  const { workloadStatus } = employeeStatus;
  const classes = useStyles();

  return (
    <div className={classes.smallContent}>
      <div className={classes.noticeBox}>
        <Typography variant="h5">Wie bist Du diese Woche ausgelastet?</Typography>
        <Typography variant="body2" className={classes.notice}>
          Hinweis: Wenn du diese Woche Zeit übrig hast oder Hilfe brauchst kannst du es hier angeben. Sonst geht man
          davon aus, dass du ausgelastet bist.
        </Typography>
      </div>
      <div className={classes.checkBoxes}>
        <MUIEXXCheckBox
          onChange={() => handleCheckboxClick(Workload.I_HAVE_TIME)}
          onClick={() => handleCheckboxClick(Workload.I_HAVE_TIME)}
          checked={workloadStatus.iHaveTime}
          label={'Ich habe Zeit übrig'}
          margin={'0px 8px 0px 0px'}
        />
        <MUIEXXCheckBox
          onChange={() => handleCheckboxClick(Workload.I_NEED_HELP)}
          onClick={() => handleCheckboxClick(Workload.I_NEED_HELP)}
          checked={workloadStatus.iNeedHelp}
          label={'Ich brauche Hilfe'}
          margin={'0px 8px 0px 0px'}
        />
      </div>
    </div>
  );
};

export default Auslastungsstatus;
