import { faGauge, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import colors from '../utils/colors';
import MUIEXXTooltipBase from './BaseComponentsExxetaStyle/MUIEXXTooltipBase';

const useStyles = makeStyles((theme: Theme) => ({
  legende: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  legendColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    '& .MuiTypography-body2': {
      whiteSpace: 'nowrap',
    },
  },
  legendItemCircle: {
    width: '16px',
    height: '16px',
    borderRadius: '30px',
  },
  legendItemBox: {
    width: '16px',
    height: '16px',
  },
  internProject: {
    border: `1px solid ${colors.mainColor}`,
    background: colors.white,
  },
  percentage2550Project: {
    background: colors.grey200,
  },
  percentage75Project: {
    background: colors.grey600,
  },
  percentageProbability100Project: {
    background: colors.mainColor,
  },
  redBoxes: {
    background: colors.redTimelineColor,
  },
  itemPercentageIcon: {
    width: '22px',
    height: '24px',
  },
  vacation: {
    backgroundColor: colors.purple200,
    border: `1px solid ${colors.mainColor}`,
  },
  tooltipText: {
    backgroundColor: 'transparent',
  },
  today: {
    height: '20px',
    width: '2px',
    background: 'repeating-linear-gradient(to bottom, black 0px, black 4px, transparent 4px, transparent 12px)',
    margin: '0px 5px',
  },
}));

interface TimelineCaptionProps {
  showDetailedCaption?: boolean;
}

const TimelineCaption = (props: TimelineCaptionProps): JSX.Element => {
  const classes = useStyles();
  const { showDetailedCaption } = props;

  return (
    <div className={classes.legende}>
      <div className={classes.legendColumn}>
        <FontAwesomeIcon icon={faGauge} className={classes.itemPercentageIcon} />
        <Typography variant="body2">Arbeitszeit {showDetailedCaption && '(in %)'}</Typography>
      </div>
      <div className={classes.legendColumn}>
        <div className={`${classes.internProject} ${classes.legendItemCircle} `} />
        <Typography variant="body2">budgetlos</Typography>
      </div>
      <div className={classes.legendColumn}>
        <div className={`${classes.percentage2550Project} ${classes.legendItemCircle} `} />
        <Typography variant="body2">unsicher</Typography>
      </div>
      <div className={classes.legendColumn}>
        <div className={`${classes.percentage75Project} ${classes.legendItemCircle} `} />
        <Typography variant="body2">mündliche Zusage</Typography>
      </div>
      <div className={classes.legendColumn}>
        <div className={`${classes.percentageProbability100Project} ${classes.legendItemCircle} `} />
        <Typography variant="body2">beauftragt</Typography>
      </div>

      <div className={classes.legendColumn}>
        <div className={classes.today} />
        <Typography variant="body2">heute</Typography>
      </div>

      <div className={classes.legendColumn}>
        <div className={`${classes.vacation} ${classes.legendItemCircle} `} />
        <Typography variant="body2">Urlaub</Typography>
      </div>

      <div className={classes.legendColumn}>
        <div className={`${classes.redBoxes} ${classes.legendItemBox} `} />
        <Typography variant="body2">
          Auslastung {showDetailedCaption && 'kleiner 50 % (Projekte mit Budget)'}
        </Typography>
        {!showDetailedCaption && (
          <MUIEXXTooltipBase
            title={
              <Typography variant="body1" className={classes.tooltipText}>
                {'Auslastung kleiner als 50 % bezogen auf Projekte mit Budget'}
              </Typography>
            }
            showTooltip
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </MUIEXXTooltipBase>
        )}
      </div>
    </div>
  );
};

export default TimelineCaption;
