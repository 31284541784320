import { Avatar, Theme, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { TimelineGroup } from 'react-calendar-timeline';

import { Employee } from '../../redux/reducers/employee/employee.type';
import colors from '../../utils/colors';
import { CustomGroupParams } from './getTimelineGroups';

const useStyles = makeStyles<Theme>((theme) => ({
  groupEmployeeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    padding: '0px 20px',
    lineHeight: 'initial',
    height: '100%',
    width: '100%',
    gap: '8px',
    borderBottom: `2px solid ${colors.grey200}`,
  },
  avatarSize: {
    width: 56,
    height: 56,
    borderRadius: '8px',
  },
  zoomedAvatarSize: { transform: 'scale(1.7) translate(0px, 7px)' },
  groupNameStyle: {
    whiteSpace: 'pre-line',
  },
  groupTeamBox: {
    padding: '8px 0px 8px 20px',
    height: '100%',
    width: '100vw',
    color: colors.mainColor,
    background: colors.white,
    borderBottom: `3px solid ${colors.mainColor}`,
    position: 'absolute',
    left: -4,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  groupTitle: {
    backgroundColor: 'transparent',
    position: 'relative',
    top: '4px',
  },
}));

interface CustomTimelineGroupProps {
  group: TimelineGroup<CustomGroupParams>;
  hideSidebar: boolean;
  employees: Employee[];
  handleGroupRef: (ref: HTMLElement) => void;
}

const CustomTimelineGroup = (props: CustomTimelineGroupProps): JSX.Element => {
  const { group, hideSidebar, employees, handleGroupRef } = props;
  const classes = useStyles();
  if (hideSidebar) {
    return <React.Fragment></React.Fragment>;
  }
  if (group.empId) {
    const employee = employees.find((emp) => emp.id === group.empId);
    return (
      <div className={classes.groupEmployeeBox} id={`${employee.id}`} ref={handleGroupRef}>
        <Avatar
          alt="profil-bild"
          src={employee.profileImage}
          className={classes.avatarSize}
          imgProps={{
            className: `${employee.profileImageIsZoomed && classes.zoomedAvatarSize} ${classes.avatarSize}`,
          }}
        />

        <Typography variant="body1" className={classes.groupNameStyle}>
          {group.title}
        </Typography>
      </div>
    );
  } else
    return (
      <div className={classes.groupTeamBox}>
        <Typography variant="h5" className={classes.groupTitle}>
          {group.title}
        </Typography>
      </div>
    );
};

export default CustomTimelineGroup;
