import dayjs from 'dayjs';

const getDaysInMonth = (date: dayjs.Dayjs): number => {
  return date.daysInMonth();
};

const isValidDate = (dateString: string): boolean => {
  return dateString && dayjs(dateString, 'DD.MM.YYYY').isValid();
};

const getNextMonday = () => {
  let nextMonday = dayjs();
  if (nextMonday.day() === 1) {
    if (nextMonday.hour() > 11) {
      nextMonday = nextMonday.add(1, 'day');
    }
  }
  // Solange der Wochentag nicht Montag ist (1), füge einen Tag hinzu
  while (nextMonday.day() !== 1) {
    nextMonday = nextMonday.add(1, 'day');
  }

  return nextMonday;
};

export { getDaysInMonth, isValidDate, getNextMonday };
