import { Card, CardContent, Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import { StatusStore } from '../../pages/employee/EmployeePage';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { useRouterNav } from '../../useRouterNav';
import Auslastungsstatus from '../status/Auslastungsstatus';

interface EmployeeStatusCardContentProps {
  employee: Employee;
  employeeState: StatusStore;
  handleWorkloadClick: Function;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '580px',
    '& .MuiFormControlLabel-label': {
      whiteSpace: 'nowrap',
    },
    '& .MuiCheckbox-root': {
      margin: '0px',
      marginRight: '12px',
    },
  },
  statusCardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  linkToStatusSite: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
}));

const EmployeeStatusCardContent = (props: EmployeeStatusCardContentProps) => {
  const { employee, employeeState, handleWorkloadClick } = props;

  const classes = useStyles();

  const { goToManageEmployeeAbsences } = useRouterNav();

  const handleStatusSiteClick = () => {
    goToManageEmployeeAbsences(employee);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.statusCardContent}>
        <Auslastungsstatus employeeStatus={employeeState.status} handleCheckboxClick={handleWorkloadClick} />
        <Typography variant="h5" className={classes.linkToStatusSite}>
          <Link onClick={handleStatusSiteClick}>Abwesenheiten verwalten</Link>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EmployeeStatusCardContent;
