import { AxiosError, AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as appServiceGet from '../../services/appServices/appInitService';
import * as appServicePostOrPut from '../../services/appServices/appRefreshService';
import { addOrReplaceEmployee, convertImage } from '../../utils/employeeUtils';
import { ApplicationStore } from '../reducers';
import { Employee, Status } from '../reducers/employee/employee.type';
import * as actionTypes from './ActionTypes';
import { updateProjectsAndEmployees } from './ProjectActions';
import { addToast } from './ToastActions';

export const teamDataAvailable = (teamData: string[]) => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    dispatch({
      payload: teamData,
      type: actionTypes.TEAM_DATA_AVAILABLE,
    });
  };
};

export const employeeDataAvailable = (employeeData: Employee[]) => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    let currentEmployees: Employee[] = JSON.parse(JSON.stringify(getState().employee.employeeData));
    if (currentEmployees && employeeData) {
      JSON.parse(JSON.stringify(employeeData)).forEach((employee: Employee) => {
        currentEmployees = addOrReplaceEmployee(currentEmployees, employee);
        currentEmployees = currentEmployees.map((employee) => {
          convertImage(employee);
          return employee;
        });
      });
    }
    dispatch({
      payload: {
        employeeData: currentEmployees,
      },
      type: actionTypes.EMPLOYEES_DATA_AVAILABLE,
    });
  };
};

export const showSpecificTeam = (teamName: string): { payload: string; type: string } => ({
  payload: teamName,
  type: actionTypes.SHOW_SPECIFIC_TEAM,
});

export const saveDisplayedEmployees = (displayedEmployees: Employee[]) => ({
  payload: displayedEmployees,
  type: actionTypes.DISPLAYED_EMPLOYEES,
});

export const teamFilterAvailable = (shownSpecificTeam: string) => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    dispatch({
      payload: shownSpecificTeam,
      type: actionTypes.TEAM_FILTER_DATA_AVAILABLE,
    });
  };
};

export const getAllEmployees = () => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    const teams = getState().employee.teamData;
    for (const team of teams) {
      appServiceGet
        .getEmployeeData(team)
        .then((data: Employee[]) => {
          dispatch(employeeDataAvailable(data));
        })
        .catch((err: Error) => {
          console.error('No EmployeeData or; ' + err);
        });
    }
  };
};

export const editEmployee = (changesStatus: Status, emailFromEmployee: string, urlaubChanged: boolean) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    // Send Overlay Changes to database
    await appServicePostOrPut
      .putEditedEmployeeStatus(changesStatus, emailFromEmployee)
      .then((res: AxiosResponse<string>) => {
        if (res.status !== 200) {
          urlaubChanged && dispatch(addToast('Es gab Probleme beim hinzufügen des Urlaubs.', 'error'));
        } else {
          urlaubChanged && dispatch(addToast('Urlaub wurde erfolgreich hinzugefügt', 'success'));
        }

        return res.data;
      })
      .catch((err: AxiosError) => {
        urlaubChanged && dispatch(addToast('Es gab Probleme beim hinzufügen des Urlaubs.', 'error'));
        console.log('Problem in EmployeeActions.js, check error: ' + JSON.stringify(err));
        return err;
      });
    // Update project and employee data in frontend
    await dispatch(updateProjectsAndEmployees());
  };
};
