import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, makeStyles } from '@material-ui/core';
import { AutocompleteInputChangeReason } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';

import { senBold } from '../../fonts/fonts';
import { InputSelectOption } from '../../models/shared/InputSelectOption';
import { ProjectShort } from '../../models/shared/ProjectShort';
import { ApplicationStore } from '../../redux/reducers';
import colors from '../../utils/colors';
import { createSuggestionListOfProjectNames, mapProjectsToShortProjects } from '../../utils/projectUtils';
import MUIEXXAutocompleteInputField, { Option } from '../BaseComponentsExxetaStyle/MUIEXXAutocompleteInputField';
import MUIEXXTooltipBase from '../BaseComponentsExxetaStyle/MUIEXXTooltipBase';
import { DialogMode, DialogNewProjectMode } from '../Dialogs/DialogNewProject/DialogNewProject';

interface ProjectSearchEditFieldProps {
  onProjectSearchClick: (dialogMode: DialogMode) => void;
  shortProjects: ProjectShort[];
}

const useStyles = makeStyles((theme) => ({
  editField: {
    width: '340px',
  },

  addNewProjectCircle: {
    position: 'relative',
    left: '14px',
    width: '31px',
    height: '31px',
    backgroundColor: colors.mainColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    color: colors.white,
  },

  addPlus: {
    fontSize: '16px',
  },

  tooltipText: {
    backgroundColor: 'transparent',
  },

  popperWithSticky: {
    width: 'fit-content !important',
    minWidth: '350px',

    '& .MuiAutocomplete-option:last-child': {
      position: 'absolute',
      padding: '12px 24px 12px 24px',
      margin: '0px',
      height: '48px',
      bottom: '-43px',
      left: '0px',
      borderTop: '1px solid black',
      fontFamily: senBold,
      border: `1px solid ${colors.grey100}`,
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },

    '& .MuiAutocomplete-paper': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },

    '& .MuiAutocomplete-listbox': {
      paddingBottom: '0px',
    },
  },
}));

const ProjectSearchEditField = (props: ProjectSearchEditFieldProps): JSX.Element => {
  const { onProjectSearchClick, shortProjects } = props;
  const classes = useStyles();

  const allProjects: ProjectShort[] = shortProjects;
  const [inputValue, setInputValue] = useState<string>('');

  const handleOnChange = (event: ChangeEvent<{}>, value: NonNullable<string | Option>) => {
    const tmpEvent = {};
    Object.defineProperty(tmpEvent, 'target', {
      writable: false,
      value: { value: value ? value : null },
    });

    Object.defineProperty(tmpEvent, 'currentTarget', {
      writable: false,
      value: {},
    });
    handleInput(tmpEvent as ChangeEvent<HTMLInputElement>);
  };

  const handleOnInputChange = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    handleInput(event as React.ChangeEvent<HTMLInputElement>);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      if (!event.target.value) {
        setInputValue('');
      } else if (event.type === 'change') {
        setInputValue(event.currentTarget.value || null);
      } else if (event.type !== 'click' && event.target.value) {
        const option: InputSelectOption = JSON.parse(JSON.stringify(event.target.value));

        if (option.customer === 'customerKeyForPopOver') {
          onProjectSearchClick({ mode: DialogNewProjectMode.FAST_NEW, projectName: inputValue });
        } else if (event.type !== 'blur') {
          onProjectSearchClick({ mode: DialogNewProjectMode.EDIT, projectName: option.value });
        }
      }
    }
  };

  return (
    <MUIEXXAutocompleteInputField
      formControlStyle={classes.editField}
      options={createSuggestionListOfProjectNames(allProjects, [], inputValue)}
      inputValue={inputValue}
      placeholder={'Projekt bearbeiten oder anlegen'}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      endAdornment={
        <MUIEXXTooltipBase
          title={
            <Typography variant="body1" className={classes.tooltipText}>
              Projekt neu anlegen
            </Typography>
          }
          showTooltip
        >
          <div
            className={classes.addNewProjectCircle}
            onClick={() => {
              onProjectSearchClick({ mode: DialogNewProjectMode.CREATE_NEW });
            }}
          >
            <FontAwesomeIcon icon={faPlus} className={classes.addPlus} />
          </div>
        </MUIEXXTooltipBase>
      }
      popperPlacement="bottom-end"
      popperClassname={inputValue !== '' && classes.popperWithSticky}
    />
  );
};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    shortProjects: mapProjectsToShortProjects(store.project.projectData),
  };
};

export default connect(mapStateToProps)(ProjectSearchEditField);
