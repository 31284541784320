import dayjs from 'dayjs';
import { TimelineItem } from 'react-calendar-timeline';

import { Project } from '../../redux/actions/ProjectActions';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { isValidDate } from '../../utils/dateUtils';

export interface CustomItemParams {
  id: string;
  group: string;
  name: string;
  customer?: string;
  teamName?: string;
  start_time: number;
  end_time: number;
  percentageTime?: number;
  percentageProbability?: number;
  canMove: boolean;
  canResize: boolean;
  canChangeGroup: boolean;
  billable?: boolean;
  archived?: boolean;
}

export const getTimelineItems = (
  currentEmployeeFilter: string,
  displayedEmployees: Employee[],
  employees: Employee[],
  projects: Project[],
): TimelineItem<CustomItemParams>[] => {
  const items: TimelineItem<CustomItemParams>[] = [];
  const employeeList = currentEmployeeFilter === 'alle' ? employees : displayedEmployees;

  const checkDates = (startDate: string, endDate: string, isStart: boolean): number => {
    const today = dayjs();
    const defaultEndDate = today.add(6, 'month');
    const parsedStartDate = isValidDate(startDate) ? dayjs(startDate, 'DD.MM.YYYY') : today;
    const parsedEndDate = isValidDate(endDate) ? dayjs(endDate, 'DD.MM.YYYY') : defaultEndDate;

    if (isStart) {
      return parsedStartDate.valueOf();
    } else {
      return parsedEndDate.valueOf();
    }
  };

  const getProjectFromEmployee = (mail: string, project: string): Project => {
    // TODO: need some loading indicator as long as projects are not loaded it can't read name
    const proj: Project = projects.find(
      (proj) => proj.name === project && proj.projectMembers.find((member) => member.employeeEmail === mail),
    );
    return proj;
  };

  const addItems = (employee: Employee, teamName: string) => {
    employee.projects.forEach((proj) => {
      const project = getProjectFromEmployee(employee.email, proj);
      const member =
        project?.projectMembers &&
        project.projectMembers.find((projectMember) => projectMember?.employeeEmail === employee.email);
      if (member) {
        items.push({
          id: `${employee.id}_${teamName}_${proj}`, // Später beachten: Veränderungen und Verschiebung des gleichen Projektes
          group: employee.id + teamName,
          name: proj,
          customer: project.customer,
          teamName: teamName,
          start_time: checkDates(member.startDate, member.endDate, true),
          end_time: checkDates(member.startDate, member.endDate, false),
          percentageTime: member.percentageTime ? member.percentageTime : 0,
          percentageProbability: member.percentageProbability ? member.percentageProbability : 0,
          canMove: false,
          canResize: false,
          canChangeGroup: false,
          billable: project.billable,
          archived: project.archived,
        });
      }
    });
  };

  const addUrlaubItems = (employee: Employee, teamName: string) => {
    if (employee?.status?.vacationStatus) {
      employee.status.vacationStatus.forEach((vacation, index) => {
        items.push({
          id: `${employee.id}_${teamName}_Urlaub_${index}`, // Später beachten: Veränderungen und Verschiebung des gleichen Projektes
          group: employee.id + teamName,
          name: 'Urlaub',
          start_time: checkDates(vacation.vacationBeginning, vacation.vacationEnding, true),
          end_time: checkDates(vacation.vacationBeginning, vacation.vacationEnding, false),
          canMove: false,
          canResize: false,
          canChangeGroup: false,
        });
      });
    }
  };

  employeeList.forEach((emp) => {
    if (currentEmployeeFilter === 'alle') {
      emp.teams.forEach((teamName) => {
        addItems(emp, teamName);
        addUrlaubItems(emp, teamName);
      });
    } else {
      addItems(emp, currentEmployeeFilter);
      addUrlaubItems(emp, currentEmployeeFilter);
    }
  });
  return items;
};
